export default function CollapseOne() {
    return (
        <div className="flex flex-col my-2 gap-4">
            <div>
                <strong>LEVETIRACETAM</strong><br/><br/>

                <strong>FORMA FARMACEUTICA</strong><br/>
                <strong>Cápsulas de liberación prolongada</strong><br/><br/>

                <strong>FORMULA</strong><br/>
                Cada cápsula contiene:
                Levetiracetam	250 mg, 500 mg, 750 mg ó 1000 mg
                Excipiente cbp	1 tableta
            </div>
        </div>
    )
        ;
}