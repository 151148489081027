import {useState} from "react";
import {tUser} from "./Session.types";

export default function useSession() {

    const [user, setUser] = useState<tUser | null>(null);

    return {
        user,
        setUser
    }


}