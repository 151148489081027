export default function CollapseOne() {
  return (
    <div className="flex flex-col my-2 gap-4">
      <div>
        <strong>OXCARBAZEPINA TABLETAS
          FORMA FARMACEUTICA Y FORMULACION</strong>
        <p>Cada tableta contiene:</p>
        <p>Oxcarbazepina 300 mg ó 600 mg</p>
        <p>Excipiente cbp 1 tableta.</p>
      </div>
    </div>
  )
    ;
}