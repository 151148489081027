import {FaChevronLeft} from "react-icons/fa";
import {MedicinesEnum} from "../../../Medicines.enum";
import {Collapse} from "react-daisyui";
import CollapseOne from "./components/CollapseOne/CollapseOne.component";
import CollapseTwo from "./components/CollapseTwo/CollapseTwo.component";

type DescriptionProps = {
    setSelectedTab: (tab: MedicinesEnum) => void;
}


export default function Description({setSelectedTab}: DescriptionProps) {
    return (
        <div className="flex flex-col my-8">
            <div className="grid grid-cols-12  px-4">
                <div className="col-span-3">
                    <button
                        onClick={() => setSelectedTab(MedicinesEnum.MENU)}
                        className="flex items-center justify-center gap-2">
                        <FaChevronLeft className="w-8 h-8"/>
                        <p>Regresar</p>
                    </button>
                </div>
                <div className="col-span-9 ml-14 mt-6">
                    <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
                        <img src={"./imgs/icons/icon-1.png"} alt="icon.1" className=""/>
                    </div>
                </div>
            </div>
            <h2 className="font-bold text-xl text-center mt-2">DESCRIPCIÓN Y USO</h2>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    INFORMACION PARA PRESCRIBIR REDUCIDA IPPR
                    ZUNUN® CITICOLlNA
                </Collapse.Title>
                <Collapse.Content>
                    <CollapseOne/>
                </Collapse.Content>
            </Collapse>

            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    INDICACIONES TERAPEUTICAS
                </Collapse.Title>
                <Collapse.Content>
                    <CollapseTwo/>
                </Collapse.Content>
            </Collapse>

        </div>

    );
}