import {MedicinesEnum} from "../../../Medicines.enum";
import {FaChevronLeft} from "react-icons/fa";
import {Collapse} from "react-daisyui";

type EffectsProps = {
    setSelectedTab: (tab: MedicinesEnum) => void;
}
export default function Effects({setSelectedTab}: EffectsProps) {
    return (
        <div className="flex flex-col my-8">
            <div className="grid grid-cols-12  px-4">
                <div className="col-span-3">
                    <button
                        onClick={() => setSelectedTab(MedicinesEnum.MENU)}
                        className="flex items-center justify-center gap-2">
                        <FaChevronLeft className="w-8 h-8"/>
                        <p>Regresar</p>
                    </button>
                </div>
                <div className="col-span-9 ml-14 mt-6">
                    <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
                        <img src={"./imgs/icons/icon-3.png"} alt="icon.1" className=""/>
                    </div>
                </div>
            </div>
            <h2 className="font-bold text-xl text-center mt-2">EFECTOS SECUNDARIOS</h2>

            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    REACCIONES SECUNDARIAS Y ADVERSAS
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <h2 className="text-orange-300">Reacciones adversas reportadas con una frecuencia mayor o igual a 1
                        %</h2>
                    <p>La seguridad de
                        Galantamina se evaluó en 4,457 sujetos con demencia de Alzheimer leve a moderadamente severa,
                        quienes participaron en 7 estudios clínicos doble ciego controlados con placebo. La información
                        presentada en esta sección se extrajo de datos acumulados.
                        Las reacciones adversas (RA) reportadas con una frecuencia mayor o igual a 1% de sujetos
                        tratados con Galantamina se muestran en la tabla 1.</p>
                    <br/>
                    <p>En un estudio clínico aleatorizado, doble ciego controlado con placebo, el perfil de seguridad
                        del tratamiento de una vez al día con Galantamina Cápsulas de liberación prolongada fue similar
                        en frecuencia y en naturaleza a la vista con las tabletas.
                        Las náuseas y el vómito, las reacciones adversas más frecuentes, ocurrieron principalmente
                        durante los periodos de titulación, y duraron menos de una semana en la mayoría de los casos y
                        la mayoría de pacientes tuvo sólo un episodio. La prescripción de antieméticos y asegurar una
                        adecuada ingestión de líquidos podría ser útil en algunas situaciones.
                    </p>
                    <h2 className="text-orange-300">Datos de estudios abiertos</h2>
                    <p>Reacciones adversas reportadas con una frecuencia mayor o igual a 1%.
                        La seguridad de Galantamina se evaluó en 1,454 sujetos con demencia de Alzheimer leve a
                        moderadamente severa, quienes participaron en 5 estudios clínicos de fase abierta. La
                        información presentada en esta sección fue derivada de datos acumulados (pool data).
                        Las reacciones adversas reportadas con una frecuencia igual o mayor a 1% de sujetos en
                        tratamiento con Galantamina (sujetos tratados en estos estudios y no listados en la tabla 1
                        incluyendo abandonos, las cuales ocurrieron con una tasa de 6.5% en los estudios abiertos).</p>
                    <h2 className="text-orange-300">Datos de estudios doble ciego y abiertos</h2>
                    <p>Reacciones adversas reportadas con una frecuencia menor a 1%.
                        Las reacciones adversas que ocurrieron en menos de 1% de sujetos tratados con Galantamina I
                        tanto en estudios doble ciego como en estudios abiertos son listadas en la tabla 2.</p>
                    <p>En la tabla 3 se presentan las reacciones adversas por categoría de frecuencia basadas en la
                        incidencia a partir de estudios clínicos, cuando fueron conocidas.</p>
                    <h2 className="text-orange-300">Reacciones adversas adicionales observadas en estudios clínicos y en
                        la experiencia postcomercialización</h2>
                    <p>Otros eventos adversos de la experiencia de los estudios clínicos y postcomercialización que han
                        sido observados en pacientes tratados con Galantamina incluyen:</p>
                    <h2 className="text-orange-300">Generales</h2>
                    <p>Astenia, deshidratación (incluyendo rara vez, casos severos que conducen a insuficiencia renal y
                        falla renal), fiebre y malestar.</p>
                    <h2 className="text-orange-300">Cardiovascular</h2>
                    <p>Bloqueo AV, arritmias auriculares y palpitaciones, hipertensión, hipotensión, infarto o isquemia
                        miocárdica.</p>
                    <h2 className="text-orange-300">SNC</h2>
                    <p>Trastornos de la conducta, incluyendo agitación/agresión y alucinaciones, depresión (muy rara vez
                        con tendencia suicida), calambres de miembros inferiores, parestesia, convulsiones, tinnitus,
                        ataque isquémico transitorio o accidente vascular cerebral.</p>
                    <h2 className="text-orange-300">Gastrointestinal</h2>
                    <p>Disfagia, sangrado gastrointestinal alto y bajo.</p>
                    <h2 className="text-orange-300">Metabólico y alteraciones nutricionales</h2>
                    <p>Hipocaliemia.
                        Algunos de estos eventos adversos pueden ser atribuidos a las propiedades colino miméticas de
                        Galantamina o en algunos casos quizá representen manifestaciones o exacerbaciones de procesos
                        comunes de enfermedades no diagnosticadas en la población anciana.
                    </p>


                </Collapse.Content>
            </Collapse>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    INTERACCIONES MEDICAMENTOSAS Y DE OTRO GÉNERO
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <h2 className="text-orange-300">Interacciones farmacodinámicas</h2>
                    <p>Debido a su mecanismo de acción, Galantamina no debe ser administrada concomitantemente con otros
                        colinomiméticos.
                        Galantamina antagoniza el efecto de medicamentos anticolinérgicos. Como se espera con los
                        colinomiméticos, una interacción farmacodinámica es posible con los medicamentos que reducen
                        significativamente la frecuencia cardiaca (por ejemplo, digoxina y B-bloqueadores) (véase Dosis
                        y vía de administración).
                        Es probable que la Galantamina, como cualquier otro colinomimético, exagere la relajación
                        muscular tipo succinilcolina durante la anestesia.
                    </p>
                    <h2 className="text-orange-300">Interacciones farmacocinéticas</h2>
                    <p>Múltiples vías metabólicas y excreción renal están involucradas en la eliminación de Galantamina.
                        Basados en estudios in vitro, las principales enzimas involucradas en el metabolismo de
                        Galantamina fueron CYP2D6 o CYP3A4.
                        La inhibición de la acidez gástrica no afecta la absorción de Galantamina.</p>
                    <h2 className="text-orange-300">Otros medicamentos que afectan el metabolismo de Galantamina</h2>
                    <p>Medicamentos que son inhibidores potentes de CYP2D6 ó CYP3A4 pueden incrementar el ABC de
                        Galantamina. Estudios farmacocinéticos de dosis múltiples demostraron que el ABC de Galantamina
                        aumenta 30 y 40% respectivamente, durante la coadministración de ketoconazol y paroxetina,
                        Cuando se coadministra con eritromicina, otro inhibidor CYP3A4, el ABC de Galantamlna solo
                        aumenta aproximadamente 10%. Estudios de farmacocinética en poblaciones de pacientes con
                        enfermedad de Alzheimer mostraron que la depuración de Galantamina es disminuida cerca de 25-33%
                        por la administración concurrente de amitriptilina, fluoxetina, fluvoxamina, paroxetina y
                        quinidina, inhibidores conocidos del CYP2D6. Por lo tanto, durante el inicio de un tratamiento
                        con inhibidores potentes CYP2D6 o CYP3A4, los pacientes quizás experimenten un aumento en la
                        incidencia de efectos secundarios de tipo colinérgico, predominando las náuseas y el vómito.
                        Bajo estas circunstancias, basados en la tolerabilidad, se debe considerar una reducción de la
                        dosis de mantenimiento de Galantamina.</p>
                    <h2 className="text-orange-300">Efectos de Galantamina sobre el metabolismo de otros
                        medicamentos</h2>
                    <p>Dosis terapéuticas de Galantamina (hasta 12 mg dos veces al día) no tienen efecto sobre la
                        cinética de digoxina o warfarina. Galantamina no afecta el incremento en el tiempo de
                        protrombina inducido por warfarina.
                        Los estudios in vitro indican que el potencial de inhibición Galantamina con respecto a las
                        formas mayores del citocromo P-450 humano es muy bajo.
                    </p>

                </Collapse.Content>
            </Collapse>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    PRECAUCIONES Y RELACION CON EFECTOS DE CARCINOGENESIS, MUTAGENESIS, TERATOGENESIS Y SOBRE LA
                    FERTILIDAD
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <p>Los estudios de reproducción realizados en ratas gestantes no mostraron potencial teratogéníco
                        con dosis
                        por arriba de 16 mg/kg (o cerca de 25 veces la dosis terapéutica en humanos) y en conejos
                        gestantes utilizando dosis por arriba de 40 mg/kg (alrededor de 63 veces la dosis terapéutica
                        humana). Se observó un incremento no significativo en la incidencia de anormalidades
                        esqueléticas menores en ratas a dosis de 16 mg/kg.</p>
                </Collapse.Content>
            </Collapse>
        </div>
    );
}