import {MedicinesEnum} from "../../../Medicines.enum";
import {FaChevronLeft} from "react-icons/fa";
import {Collapse} from "react-daisyui";
import CollapseOne from "./components/CollapseOne/CollapseOne.component";


type PrecautionsProps = {
    setSelectedTab: (tab: MedicinesEnum) => void;
}
export default function Precautions({setSelectedTab}: PrecautionsProps) {
    return (
        <div className="flex flex-col my-8">
            <div className="grid grid-cols-12  px-4">
                <div className="col-span-3">
                    <button
                        onClick={() => setSelectedTab(MedicinesEnum.MENU)}
                        className="flex items-center justify-center gap-2">
                        <FaChevronLeft className="w-8 h-8"/>
                        <p>Regresar</p>
                    </button>
                </div>
                <div className="col-span-9 ml-14 mt-6">
                    <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
                        <img src={"./imgs/icons/icon-2.png"} alt="icon.1" className=""/>
                    </div>
                </div>
            </div>
            <h2 className="font-bold text-xl text-center mt-2">PRECAUCIONES ESPECIALES</h2>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    CONTRAINDICACIONES
                </Collapse.Title>
                <Collapse.Content>
                    <CollapseOne/>
                </Collapse.Content>
            </Collapse>

            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    PRECAUCIONES GENERALES
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <h2 className="text-orange-300">Hepatotoxicidad</h2>
                    <p>Se han presentado alteraciones de la función hepática, incluyendo insuficiencia con resultados
                        fatales en algunos pacientes que recibieron el ácido valpróico. Estos incidentes generalmente
                        han ocurrido durante los primeros seis meses de tratamiento. La hepatotoxicidad severa o fatal
                        puede verse precedida de síntomas inespecíficos como malestar general, debilidad, letargia,
                        edema facial, anorexia y vómito. En pacientes con epilepsia puede ocurrir una pérdida del
                        control de las crisis. Se debe monitorear de cerca a los pacientes para detectar la presencia de
                        estos síntomas.
                        <br/><br/>
                        Se deben realizar pruebas de función hepática antes del tratamiento y después en intervalos
                        frecuentes, especialmente durante los primeros seis meses. Sin embargo, los médicos no deben
                        confiar totalmente en la química sanguínea, ya que estas pruebas no son anormales en todos los
                        casos, y también deben considerar los resultados de la historia clínica cuidadosa y la
                        exploración física.
                        <br/><br/>
                        Se debe tener precaución al administrar productos con valproato semisódico a pacientes con
                        antecedentes de enfermedad hepática. Los pacientes que toman múltiples anticonvulsivos, los
                        niños, los pacientes con trastornos metabólicos congénitos, aquellos con trastornos de crisis
                        convulsivas severas acompañados por retraso mental y aquellos con enfermedad cerebral orgánica
                        están en riesgo particular.
                        <br/><br/>
                        1 .-La experiencia indica que los niños menores de dos años tienen un riesgo
                        considerablemente más
                        alto de desarrollar hepatotoxicidad letal, especialmente aquellos con los padecimientos
                        antes
                        mencionados. En este grupo de pacientes, el valproato sódico debe usarse con extrema
                        precaución
                        y como medicamento único. Los beneficios del tratamiento se deben valorar contra los
                        riesgos.
                        Por encima de este grupo de edad, la experiencia en epilepsia indica que la incidencia
                        de
                        hepatotoxicidad letal disminuye considerablemente en los grupos de pacientes
                        progresivamente
                        mayores.
                        <br/> <br/>
                        2.- No se recomienda el uso pediátrico de valproato semisódico de liberación prolongada para
                        la
                        profilaxis de migrañas. El fármaco se debe suspender de inmediato ante la presencia de
                        disfunción hepática significativa, sospechada o aparente. En algunos casos, la
                        disfunción
                        hepática ha progresado a pesar de suspender el fármaco.
                    </p>
                    <h2 className="text-orange-300">Pancreatitis</h2>
                    <p>Se han reportado casos de pancreatitis potencialmente mortal en niños y adultos tratados con
                        valproato. Algunos de los casos se describieron como hemorrágicos con progresión rápida desde
                        los síntomas iniciales a la muerte. Algunos casos se han presentado brevemente después del uso
                        inicial, así como después de varios años de uso. La tasa basada en los casos reportados excede
                        la esperada en la población general, y ha habido casos en los que la pancreatitis reincidió
                        después de reanudar el tratamiento con valproato. En estudios clínicos, hubo dos casos de
                        pancreatitis sin etiología alternativa en 2,416 pacientes, representando una experiencia de
                        1,044 años-paciente.
                        <br/> <br/>
                        Se debe advertir a los pacientes y cuidadores que el dolor abdominal, náuseas, vómito y/o
                        anorexia podrían ser síntomas de pancreatitis que requieren evaluación médica inmediata. Si se
                        diagnostica pancreatitis, el valproato normalmente debe suspenderse. Se debe iniciar un
                        tratamiento alternativo para el padecimiento causante, según esté indicado clínicamente.
                    </p>
                    <h2 className="text-orange-300">Trastornos del Ciclo de la Urea (TCU)</h2>
                    <p>Se ha reportado encefalopatía hiperamonémica, en ocasiones mortal, después del inicio del
                        tratamiento con valproato en pacientes con trastornos del ciclo de la urea, un grupo de
                        alteraciones genéticas poco comunes, en particular deficiencia de ornitina transcarbamilasa. En
                        los siguientes pacientes debe considerarse la evaluación del TCU antes de iniciar el tratamiento
                        con valproato:
                        <br/> <br/> 1) Pacientes con antecedentes de encefalopatía inexplicada o coma, encefalopatía
                        asociada con el
                        contenido proteico, encefalopatía relacionada con el embarazo o posparto, retraso mental
                        inexplicado o antecedentes de elevaciones plasmáticas de amoniaco o glutamina.
                        <br/> <br/>2) Pacientes con vómito cíclico o letargo, irritabilidad episódica extrema, ataxia,
                        BUN bajo,
                        intolerancia a proteínas.
                        <br/> <br/>3) Pacientes con antecedentes heredofamiliares de TCU o de muerte del lactante
                        inexplicada
                        (particularmente varones).
                        <br/> <br/>4) Pacientes con signos o síntomas de TCU.
                        Los pacientes que desarrollan síntomas de encefalopatía hiperamonémica inexplicada mientras
                        reciben tratamiento con valproato deben recibir tratamiento inmediato (incluyendo la suspensión
                        de valproato) y ser evaluados con respecto a trastornos del ciclo de la urea.
                    </p>
                    <h2 className="text-orange-300">Comportamiento e Ideas de Suicidio</h2>
                    <p>Se reportó un mayor riesgo de pensamientos y comportamiento suicidas en pacientes que tomaban
                        fármacos antiepilépticos (FAE) para cualquier indicación. El aumento del riesgo de pensamientos
                        o comportamiento suicidas con fármacos antiepilépticos se observó desde tan sólo una semana
                        después de haber iniciado el tratamiento con FAE y persistió durante todo el tratamiento
                        evaluado. El riesgo relativo de pensamientos o comportamiento de suicidio fue más alto en los
                        estudios clínicos de epilepsia que en los estudios clínicos de padecimientos psiquiátricos o de
                        otro tipo, pero las diferencias de riesgo absoluto fueron similares para las indicaciones de
                        epilepsia y psiquiátricas.
                        <br/> <br/>
                        Se debe monitorear a los pacientes tratados con un FAE para cualquier indicación para detectar
                        la aparición o empeoramiento de depresión, comportamiento o ideas de suicidio y/o cambios
                        inusuales del estado de ánimo o comportamiento. El médico que prescriba valproato semisódico o
                        algún otro FAE debe sopesar el riesgo de comportamiento o pensamientos de suicidio y el riesgo
                        de no tratar la enfermedad. En caso de presentarse pensamientos y comportamientos de suicidio
                        durante el tratamiento, el médico que prescribe debe considerar si la aparición de estos
                        síntomas en cualquier paciente puede estar relacionada con la enfermedad que está tratando.
                        <br/> <br/>
                        Se debe informar a los pacientes, sus cuidadores y familias que los FAE aumentan el riesgo de
                        pensamientos y comportamiento de suicidio, y se les debe advertir de la necesidad de estar
                        alertas de la aparición o empeoramiento de los signos y síntomas de depresión, cualquier cambio
                        inusual del estado de ánimo o comportamiento, o la aparición de pensamientos y comportamientos
                        de suicidio o pensamientos de daño autoprovocado. Los comportamientos de preocupación deben
                        reportarse inmediatamente a los proveedores de salud.
                    </p>
                    <h2 className="text-orange-300">Interacciones con antibióticos carbapenémicos</h2>
                    <p>Los antibióticos carbapenémicos (ertapenem, imipenem, meropenem) pueden reducir las
                        concentraciones séricas de ácido valproico a niveles subterapéuticos, produciendo pérdida del
                        control de crisis convulsivas. Las concentraciones séricas del ácido valproico deben
                        monitorearse frecuentemente después de iniciar el tratamiento con carbapenem. Si las
                        concentraciones séricas de ácido valpróico disminuyen significativamente o si el control de
                        crisis convulsivas se deteriora, debe considerarse un tratamiento antibacteriano o
                        anticonvulsivo alternativo.
                    </p>
                    <h2 className="text-orange-300">Somnolencia en los ancianos</h2>
                    <p>En un estudio doble ciego, multicéntrico de valproato en pacientes de edad avanzada con demencia
                        (la edad promedio era 83 años), las dosis se aumentaron 125 mg/día hasta una dosis de 20
                        mg/kg/día. Una proporción significativamente más alta de pacientes tratados con valproato
                        tuvieron somnolencia en comparación con los tratados con placebo y, aunque no estadísticamente
                        significativa, hubo una proporción más alta de pacientes con deshidratación. También hubo una
                        tasa de suspensión por somnolencia significativamente más alta que con placebo.
                        <br/><br/>
                        Algunos pacientes con somnolencia (aproximadamente la mitad) presentaron disminución en la
                        ingesta de alimentos y pérdida de peso. En los pacientes que experimentaron estos eventos, hubo
                        una tendencia a tener una concentración basal de albúmina más baja, una depuración de valproato
                        más baja y BUN más alto. En pacientes de edad avanzada, la dosis debe aumentarse más lentamente
                        y con un monitoreo periódico de la ingesta de líquidos y alimentos, deshidratación, somnolencia
                        y otros eventos adversos. En pacientes con menor ingesta de alimentos o líquidos y en pacientes
                        con somnolencia excesiva, debe considerarse reducir o suspender la dosis de valproato.
                    </p>
                    <h2 className="text-orange-300">Trombocitopenia</h2>
                    <p>
                        La frecuencia de efectos adversos (en particular elevación de enzimas hepáticas y
                        trombocitopenia) puede estar relacionada con la dosis. En un estudio clínico de valproato
                        semisódico como monoterapia en pacientes con epilepsia, 34/126 pacientes (27%) que recibían
                        aproximadamente 50 mg/kg/día en promedio, tuvieron por lo menos un valor de plaquetas ≤ 75 x
                        10^9/L. A aproximadamente la mitad de estos pacientes se les suspendió el tratamiento y el
                        número de plaquetas regresó al normal. En los pacientes restantes, el número de plaquetas se
                        normalizó con tratamiento continuo. En este estudio, la probabilidad de trombocitopenia al
                        parecer aumenta significativamente a concentr </p>
                    <h2 className="text-orange-300">Hiperamonemia</h2>
                    <p>Se ha reportado hiperamonemia en asociación con el tratamiento con valproato y puede estar
                        presente a pesar de las pruebas de función hepática con resultado normal. En pacientes que
                        desarrollan letargo y vómito o cambios en el estado mental, debe considerarse la encefalopatía
                        hiperamonémica y determinarse el nivel de amoniaco. También debe considerarse la hiperamonemia
                        en pacientes que presentan hipotermia. Si el amoniaco aumenta, se debe suspender el tratamiento
                        con valproato. Se deben iniciar intervenciones adecuadas para el tratamiento de la hiperamonemia
                        y esos pacientes deben ser sometidos a investigación por trastornos subyacentes del ciclo de
                        urea. Las elevaciones asintomáticas de amoniaco son más comunes y, cuando se presentan,
                        requieren un monitoreo estrecho de los niveles plasmáticos de amoniaco. Si la elevación
                        persiste, debe considerarse la suspensión del tratamiento con valproato.</p>
                    <h2 className="text-orange-300">Hiperamonemia y encefalopatía asociadas con el uso concomitante de
                        topiramato</h2>
                    <p>La administración concomitante de topiramato y ácido valpróico se ha asociado con hiperamonemia
                        con o sin encefalopatía en pacientes que han tolerado cualquiera de los fármacos en terapia
                        única. Los síntomas clínicos de encefalopatía hiperamonémica a menudo incluyen alteraciones
                        agudas en el nivel de conciencia y/o función cognitiva con letargo o vómito. La hipotermia
                        también puede ser una manifestación de hiperamonemia. En la mayoría de los casos, los síntomas y
                        signos disminuyeron con la suspensión de cualquiera de los dos fármacos. Este evento adverso no
                        se debe a una interacción farmacocinética. Se desconoce si la monoterapia con topiramato está
                        relacionada con hiperamonemia. Los pacientes con errores innatos en el metabolismo o menor
                        actividad mitocondrial hepática pueden estar en mayor riesgo de hiperamonemia con o sin
                        encefalopatía. Aunque no se ha estudiado, una interacción de topiramato y ácido valpróico puede
                        exacerbar los defectos existentes o desenmascarar deficiencias en personas sensibles.</p>
                    <h2 className="text-orange-300">Hipotermia</h2>
                    <p>Se reportó hipotermia, definida como un descenso involuntario en la temperatura corporal central
                        mayor a 35° C (95°F), relacionada con el tratamiento con valproato tanto con presencia como en
                        ausencia de hiperamonemia. Esta reacción adversa también puede presentarse en pacientes que
                        toman topiramato concomitante con valproato después de iniciar el tratamiento con topiramato o
                        después de aumentar la dosis diaria de topiramato (véase Interacciones medicamentosas y de otro
                        género - Topiramato). Se debe considerar suspender el valproato en pacientes que desarrollan
                        hipotermia, que puede manifestarse mediante una variedad de anomalías clínicas, incluyendo
                        letargo, confusión, coma y alteraciones significativas en otros sistemas de órganos principales,
                        como el aparato cardiovascular y respiratorio. El manejo clínico y evaluación deben incluir el
                        análisis de niveles sanguíneos de amoniaco.
                    </p>
                    <h2 className="text-orange-300">General</h2>
                    <p>Debido a los reportes de trombocitopenia (véase Precauciones generales), la inhibición de la fase
                        secundaria de agregación plaquetaria y parámetros de coagulación anormales (por ejemplo,
                        fibrinógeno bajo), se recomienda realizar pruebas para determinar el número de plaquetas y
                        coagulación antes de iniciar el tratamiento y en intervalos periódicos. Se recomienda monitorear
                        los parámetros de plaquetas y coagulación de los pacientes que reciben valproato semisódico
                        antes de una cirugía planeada. En un estudio clínico de valproato semisódico como monoterapia en
                        pacientes con epilepsia, 34/126 pacientes (27%) que recibían aproximadamente 50 mg/kg/día en
                        promedio, tuvieron por lo menos un valor de plaquetas ≤ 75 x 10⁹/L. Aproximadamente la mitad de
                        estos pacientes tuvieron que suspender el tratamiento y el número de plaquetas regresó a lo
                        normal. En los pacientes restantes, el número de plaquetas se normalizó con tratamiento
                        continuo. En este estudio, la probabilidad de trombocitopenia al parecer aumentó
                        significativamente a concentraciones totales de valproato de 110 µg/ml (mujeres) o 135 µg/ml
                        (varones). La evidencia de hemorragia, contusiones o un trastorno de hemostasia/coagulación es
                        una indicación para reducir la dosis o suspender el tratamiento. Ya que el valproato semisódico
                        puede interactuar con los fármacos administrados concomitantemente que pueden inducir enzimas,
                        se recomienda determinar periódicamente la concentración plasmática de valproato y los fármacos
                        concomitantes durante la primera parte del tratamiento.</p>

                    <h2 className="text-orange-300">Reacción de hipersensibilidad en múltiples órganos</h2>
                    <p>En contadas ocasiones se han reportado reacciones de hipersensibilidad en múltiples órganos en
                        asociación cercana temporal después de iniciar el tratamiento con valproato en pacientes adultos
                        y pediátricos (mediana de tiempo hasta la detección: 21 días; rango: 1 a 40). A pesar de que el
                        número de reportes es limitado, muchos de estos casos resultaron en hospitalización y se ha
                        reportado por lo menos una muerte.</p>
                    <h2 className="text-orange-300">Información para pacientes</h2>
                    <p>Se debe advertir a los pacientes y cuidadores que el dolor abdominal, náuseas, vómito y/o
                        anorexia podrían ser síntomas de pancreatitis y, por lo tanto, requieren evaluación médica
                        inmediata. Los pacientes y cuidadores deben estar informados de los signos y síntomas asociados
                        con encefalopatía hiperamonémica (véase Precauciones generales - Hiperamonemia) y se les debe
                        indicar que si se presenta cualquiera de estos síntomas, lo informen al médico prescriptor. Ya
                        que los productos con valproato semisódico pueden producir depresión del SNC, especialmente
                        cuando se combinan con otro depresor del SNC (por ejemplo, alcohol), se debe recomendar a los
                        pacientes que no participen en actividades peligrosas, como manejar un automóvil u operar
                        maquinaria peligrosa, hasta que se sepa que el fármaco no les causa somnolencia. Debido a que el
                        valproato sódico se ha asociado con ciertos tipos de defectos de nacimiento, se debe informar a
                        las mujeres en edad fértil que estén considerando el uso de valproato semisódico los riesgos
                        asociados con el uso de valproato semisódico durante el embarazo (véase Precauciones
                        generales).</p>


                </Collapse.Content>
            </Collapse>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    RESTRICCIONES DE USO DURANTE EL EMBARAZO
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <h2 className="text-orange-300">Efectos teratogénicos</h2>
                    <p>De acuerdo con los reportes publicados y no publicados, el ácido valpróico puede producir efectos
                        teratogénicos, como defectos en el tubo neural (por ejemplo, espina bífida) en hijos de mujeres
                        que reciben el fármaco durante el embarazo. Hay datos que sugieren un aumento en la incidencia
                        de malformaciones congénitas asociado con el uso de ácido valpróico durante el embarazo en
                        comparación con otros fármacos antiepilépticos. Por consiguiente, el ácido valpróico debe
                        considerarse para mujeres en edad fértil solo después de haber discutido exhaustivamente los
                        riesgos con el paciente y de haberlos valorado contra los beneficios potenciales del
                        tratamiento.</p>
                    <h2 className="text-orange-300">Incidencia de defectos de nacimiento</h2>
                    <p>Los datos que se describen a continuación se obtuvieron casi exclusivamente de mujeres que
                        recibieron valproato para tratar la epilepsia. Hay múltiples reportes en la literatura clínica
                        que indican que el uso de fármacos antiepilépticos durante el embarazo aumenta la incidencia de
                        defectos de nacimiento. La incidencia de defectos del tubo neural en el feto puede aumentar en
                        madres que reciben valproato durante el primer trimestre de embarazo. El Centro para el Control
                        de Enfermedades (CDC) de Estados Unidos estima que las mujeres expuestas a ácido valpróico
                        tienen un riesgo de 1 a 2% de tener hijos con espina bífida. Se han reportado otras anomalías
                        congénitas (por ejemplo, defectos craneofaciales, malformaciones cardiovasculares y anomalías
                        que abarcan diferentes sistemas corporales) compatibles e incompatibles con la vida. No hay
                        datos suficientes para determinar la incidencia de estas anomalías congénitas. La incidencia más
                        alta de anomalías congénitas en mujeres tratadas con fármacos antiepilépticos con trastornos de
                        crisis convulsivas no puede considerarse como una relación de causa y efecto.</p>
                    <h2 className="text-orange-300">Factores de Riesgo</h2>
                    <p>Existen reportes de retraso del desarrollo, autismo y/o trastorno del espectro autista en hijos
                        de
                        mujeres expuestas a ácido valpróico durante el embarazo. Los pacientes que toman valproato
                        pueden
                        desarrollar alteraciones de la coagulación. Una paciente que tuvo fibrinógeno bajo cuando tomaba
                        múltiples anticonvulsivos, incluyendo valproato, dio a luz un bebé con afibrinogenemia que
                        posteriormente murió de hemorragia. Si se usa valproato durante el embarazo, se deben monitorear
                        cuidadosamente los parámetros de coagulación.</p>
                    <h2 className="text-orange-300">Complicaciones Adicionales</h2>
                    <p>Se reportó insuficiencia hepática que causó la muerte de un recién nacido y de un lactante
                        después del uso de valproato durante el embarazo. Las pruebas para detectar defectos del tubo
                        neural y otros defectos usando los procedimientos actuales deben considerarse parte de la rutina
                        prenatal en mujeres en edad fértil que reciben valproato.</p>
                    <h2 className="text-orange-300">Suspensión del Tratamiento</h2>
                    <p>Los fármacos antiepilépticos no deben suspenderse abruptamente en pacientes a quienes se
                        administra el fármaco para prevenir crisis convulsivas mayores, debido a la fuerte posibilidad
                        de precipitar un estado epiléptico con hipoxia asociada y amenaza para la vida. En casos
                        individuales en los que la severidad y frecuencia del trastorno de crisis convulsivas son tales
                        que suspender la dosis del medicamento no representa una amenaza seria para el paciente, se
                        puede considerar suspender el fármaco antes del embarazo y durante el mismo. Sin embargo, no es
                        posible afirmar con seguridad que incluso las crisis menores no representan cierto peligro para
                        el embrión o feto en desarrollo.</p>
                    <h2 className="text-orange-300">Estudios en Animales</h2>
                    <p>Los estudios en animales han demostrado teratogenia inducida por valproato. En ratones, ratas,
                        conejos y monos se ha observado un aumento en la frecuencia de malformaciones, retraso del
                        crecimiento intrauterino y muerte después de la exposición prenatal a valproato. Las
                        malformaciones
                        del sistema esquelético son las anomalías estructurales más comunes producidas en animales de
                        experimentación, pero se han observado defectos de cierre del tubo neural en ratones expuestos a
                        concentraciones plasmáticas maternas de valproato que exceden 230 µg/ml (2.3 veces el límite
                        superior normal del rango terapéutico en humanos para epilepsia) durante los periodos sensibles
                        del
                        desarrollo embrionario. La administración de una dosis oral de 200 mg/kg/día o mayor (50% de la
                        dosis diaria máxima en humanos o mayor en mg/m²) a ratas preñadas durante la organogénesis
                        produjo
                        malformaciones (esqueléticas, cardiacas y urogenitales) y retraso del crecimiento en las crías.
                        Estas dosis produjeron niveles plasmáticos máximos maternos de valproato de aproximadamente 340
                        µg/ml o mayores (3.4 veces o más el límite superior del rango terapéutico en humanos para
                        epilepsia). Se han reportado deficiencias conductuales en crías de ratas a las que se administró
                        una
                        dosis de 200 mg/kg/día durante la mayor parte del embarazo.</p>
                    <h2 className="text-orange-300">Efectos en conejos y Monos</h2>
                    <p>Una dosis oral de 350 mg/kg/día (aproximadamente dos veces la dosis diaria máxima en humanos en
                        mg/m²) produjo malformaciones esqueléticas y viscerales en conejos expuestos durante la
                        organogénesis. Se observaron malformaciones esqueléticas, retraso del crecimiento y muerte en
                        monos rhesus después de la administración de una dosis oral de 200 mg/kg/día (igual a la dosis
                        diaria máxima en humanos en mg/m²) durante la organogénesis. Esta dosis produjo niveles
                        plasmáticos máximos maternos de valproato de aproximadamente 280 µg/ml (2.8 veces el límite
                        superior del rango terapéutico en humanos para epilepsia).</p>


                </Collapse.Content>
            </Collapse>

            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    LACTANCIA
                </Collapse.Title>
                <Collapse.Content>
                    <p>El valproato se excreta en la leche materna. Se ha reportado que las concentraciones en la leche
                        materna son del 1 al 10% de las concentraciones séricas. Se desconoce el efecto que esto tendría
                        en un lactante alimentado con leche materna. Se debe considerar suspender la lactancia materna
                        cuando se administra valproato semisódico a mujeres en etapa de lactancia.</p>
                </Collapse.Content>
            </Collapse>


        </div>
    )
        ;
}