export default function CollapseTwo() {
    return (
        <div className="flex flex-col my-2 gap-4">
            <p>Neuro protector antagonista de los receptores NMDA para el tratamiento de
                alteraciones funcionales cerebrales del síndrome demencial, caracterizado por trastornos de la
                concentración y
                memoria, pérdida de interés, iniciativa, cansancio precoz, autosuficiencia limitada, trastornos de la
                motricidad en las
                actividades diarias y estado de ánimo depresivo en padecimiento tales como: - Demencia degenerativa tipo
                Alzheimer.
                - Demencia vascular/multiinfarto. - Formas mixtas de demencia degenerativa y vascular. - Demencias
                secundarias
                (Trastornos cognoscitivos como resultado de alteraciones endocrinas, enfermedades metabólicas y
                avitaminosis)
                Esta indicado en el tratamiento de enfermedades que requieren un aumento de la atención y la vigilancia
                (p.ej.
                Secundaria a lesiones cerebrales infantiles, traumatismos craneoencefálicos, esclerosis múltiple,
                parálisis transversa,
                isquemia cerebral y condiciones parkinsonoides).</p>
        </div>
    );
}