import {MedicinesEnum} from "../../../Medicines.enum";
import {FaChevronLeft} from "react-icons/fa";
import {Collapse} from "react-daisyui";

type DosisProps = {
    setSelectedTab: (tab: MedicinesEnum) => void;
}

export default function Dosis({setSelectedTab}: DosisProps) {
    return (
        <div className="flex flex-col my-8">
            <div className="grid grid-cols-12  px-4">
                <div className="col-span-3">
                    <button
                        onClick={() => setSelectedTab(MedicinesEnum.MENU)}
                        className="flex items-center justify-center gap-2">
                        <FaChevronLeft className="w-8 h-8"/>
                        <p>Regresar</p>
                    </button>
                </div>
                <div className="col-span-9 ml-14 mt-6">
                    <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
                        <img src={"./imgs/icons/icon-4.png"} alt="icon.1" className=""/>
                    </div>
                </div>
            </div>
            <h2 className="font-bold text-xl text-center mt-2">DOSIFICACIÓN, SOBREDOSIS Y CONSIDERACIONES</h2>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    SOBREDOSIFICACION O INGESTA ACCIDENTAL; MANIFESTACIONES Y
                    MANEJO (ANTIDOTOS):
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <p>En caso de producirse una intoxicación por sobredosificación
                        accidental se recomienda administrar atropina, biperideno o
                        butilhioscina para el tratamiento de la estimulación colinérgica
                        excesiva o noradrenalina o clorhidrato de norfenefrina para combatir la
                        bradicardia o hipotensión. En general, el manejo debe hacerse
                    </p>
                </Collapse.Content>
            </Collapse>

        </div>
    );
}