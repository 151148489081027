import {useState} from "react";
import {MedicinesEnum} from "../Medicines.enum";


import Menu from "../components/Menu/Menu.component";
import Description from "./components/Description/Description.component";
import Precautions from "./components/Precautions/Precautions.component";
import Effects from "./components/Effects/Effects.component";
import Dosis from "./components/Dosis/Dosis.component";
import Presentations from "./components/Presentations/Presentations.component";


export default function Zunun(){
  const [selectedTab, setSelectedTab] = useState<MedicinesEnum>(MedicinesEnum.MENU);

  const RenderContent = () => {
    switch (selectedTab) {
      case MedicinesEnum.DESCRIPTION:
        return <Description setSelectedTab={setSelectedTab}/>;
      case MedicinesEnum.PRECAUTIONS:
        return <Precautions setSelectedTab={setSelectedTab}/>;
      case MedicinesEnum.EFFECTS:
        return <Effects setSelectedTab={setSelectedTab}/>;
      case MedicinesEnum.DOSIS:
        return <Dosis setSelectedTab={setSelectedTab}/>;
      case MedicinesEnum.MENU:
        return <Menu medicine="Zunun" selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
      case MedicinesEnum.PRESENTATION:
        return <Presentations setSelectedTab={setSelectedTab}/>
      default:
        return <div>DEFAULT</div>;
    }
  }

  return(
    <div className="flex flex-col ">
      <div className="flex flex-row">
        <img src={"./imgs/backgrounds/zunun-banner.png"} alt="sundonnez-banner" className="w-full md:px-[30rem]"/>
      </div>
      <RenderContent/>
    </div>
  );
}