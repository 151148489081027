import {MedicinesEnum} from "../../../Medicines.enum";
import {FaChevronLeft} from "react-icons/fa";
import {Collapse} from "react-daisyui";

type OxetolEffectsProps = {
  setSelectedTab: (tab: MedicinesEnum) => void;
}
export default function OxetolEffects({setSelectedTab}: OxetolEffectsProps) {
  return (
    <div className="flex flex-col my-8">
      <div className="grid grid-cols-12  px-4">
        <div className="col-span-3">
          <button
            onClick={() => setSelectedTab(MedicinesEnum.MENU)}
            className="flex items-center justify-center gap-2">
            <FaChevronLeft className="w-8 h-8"/>
            <p>Regresar</p>
          </button>
        </div>
        <div className="col-span-9 ml-14 mt-6">
          <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
            <img src={"./imgs/icons/icon-3.png"} alt="icon.1" className=""/>
          </div>
        </div>
      </div>
      <h2 className="font-bold text-xl text-center mt-2">EFECTOS SECUNDARIOS</h2>

      <Collapse
        icon="arrow"
        className="border-b border-gray-500"

      >
        <Collapse.Title className="text-xl font-medium text-orange-500">
          REACCIONES SECUNDARIAS Y ADVERSAS
        </Collapse.Title>
        <Collapse.Content className="flex flex-col gap-4">
          <p>En ensayos clínicos, los eventos adversos fueron generalmente de leves a moderados en severidad, y de
            naturaleza transitoria que ocurrieron primordialmente al inicio del tratamiento.</p>

          <p>El análisis del perfil de los efectos indeseados por sistema corporal se basa en los eventos adversos de
            los
            ensayos clínicos evaluados de acuerdo con Oxetol®. Además se tomaron en cuenta reportes significativos sobre
            las
            experiencias adversas reportándose lo siguiente:</p>
          <div className="grid grid-cols-12 my-8 gap-2 justify-center">
            <div className="flex flex-col col-span-4 p-2 gap-2">
              <p className="font-bold text-center">Mareo</p>
              <img src={"./imgs/icons/bad-1.png"} alt="bad-1.png" className="w-20 mx-auto"/>
              <p className="font-bold text-center">Nausea</p>
            </div>
            <div className="flex flex-col col-span-4 p-2 gap-2">
              <p className="font-bold text-center">Cefalea</p>
              <img src={"./imgs/icons/bad-2.png"} alt="bad-1.png" className="w-20 mx-auto"/>
              <p className="font-bold text-center">Diplopía</p>
            </div>
            <div className="flex flex-col col-span-4 p-2 gap-2">
              <p className="font-bold text-center">Fatiga</p>
              <img src={"./imgs/icons/bad-3.png"} alt="bad-1.png" className="w-20 mx-auto"/>
              <p className="font-bold text-center">Somnolencia</p>
            </div>
          </div>
        </Collapse.Content>
      </Collapse>
      <Collapse
        icon="arrow"
        className="border-b border-gray-500"

      >
        <Collapse.Title className="text-xl font-medium text-orange-500">
          INTERACCIONES MEDICAMENTOSAS Y DE OTRO GÉNERO
        </Collapse.Title>
        <Collapse.Content className="flex flex-col gap-4">
          <p> La oxcarbazepina fue estudiada en los microsomas hepáticos humanos para medir su capacidad de inhibir las
            enzimas principales del citocromo P-450, responsable del metabolismo de otros fármacos.</p>

          <p> Los resultados mostraron que la oxcarbazepina y su metabolito farmacológicamente activo (el derivado
            monohidroxi, MHD) inhiben de CYP2C19. Por lo tanto, pueden ocurrir interacciones cuando se coadministra
            dosis de
            Oxetol® y fármacos metabolizados por el CYP2C19 ( por ejemplo, fenobarbital, fenitoina,) en algunos
            pacientes
            tratados con Oxetol® y fármacos metabolizados vía el CYP2C19, puede ser que sea necesario una disminución de
            los
            fármacos coadministrados.</p>

          <p> En los microsomas hepáticos humanos Ia oxcarbazepina y el MHD tiene poca o ninguna capacidad de funcionar
            como
            inhibidores de las siguientes enzimas: CYP1A2, CYP2A6, CYP2CP, CYP2D6, CYP2E1, CYP4A9 y CYP4A11. Fármacos
            antiepilépticos se valoraron interacciones potenciales entre Oxetol® y otros fármacos antiepilépticos en
            varios
            estudios clínicos. No se ha observado una autoinducción con Oxetol®. Se mostró que Oxetol® tiene influencia
            en
            el etinilestradiol (EE) y el levonorgestrel (LNG) componentes de un anticonceptivo oral.</p>

          <p> No se han realizado estudios con otros anticonceptivos o con anticonceptivos de implante. Por lo tanto el
            uso
            concomi- tante de Oxetol® con anticonceptivos hormonales puede hacer que estos pierdan eficacia.
          </p>
        </Collapse.Content>
      </Collapse>
      <Collapse
        icon="arrow"
        className="border-b border-gray-500"

      >
        <Collapse.Title className="text-xl font-medium text-orange-500">
          Antagonistas del Calcio
        </Collapse.Title>
        <Collapse.Content className="flex flex-col gap-4">
          <div className="flex justify-center items-center">
            <img src={"./imgs/plasma.png"} alt="plasma.png" className="w-2/3"/>
          </div>
          <p>Después de la coadministración de Oxetol® los valores del ABC de Ia felodipina disminuyeron 28 %. Sin
            embargo,
            los niveles plasmáticos permanecieron dentro de los límites terapéuticos recomendados. Por otro lado el
            Verapamilo produjo una disminución de 20 % en los niveles plasmáticos del MHD. Esta disminución en los
            niveles
            plasmáticos de MHD no es considerado como de importancia y relevancia clínica. </p>

        </Collapse.Content>
      </Collapse>
      <Collapse
        icon="arrow"
        className="border-b border-gray-500"

      >
        <Collapse.Title className="text-xl font-medium text-orange-500">
          Otras interacciones de fármacos</Collapse.Title>
        <Collapse.Content className="flex flex-col gap-4">
          <p>La cimetidina, eritromicina y dextropropoxifeno no tiene efecto en la farmacocinética del MHD, mientras que
            la
            viloxacina produce cambios menores en los niveles plasmáticos del MHD (alrededor de 10 % mayor después de Ia
            coadministración repetida). Los resultados con Warfarina no mostraron evidencia de interacción alguna con
            dosis
            únicas o repetidas de Oxetol®.
          </p>
          <img src={"./imgs/oxetol.png"} alt="oxetol.png" className="w-full mt-4"/>
        </Collapse.Content>
      </Collapse>
      <Collapse
        icon="arrow"
        className="border-b border-gray-500"

      >
        <Collapse.Title className="text-xl font-medium text-orange-500">
          PRECAUCIONES Y RELACION CON EFECTOS DE CARCINOGENESIS, MUTAGENESIS,
          TERATOGENESIS Y SOBRE LA FERTILIDAD
        </Collapse.Title>
        <Collapse.Content className="flex flex-col gap-4">
          <p>Los datos preclínicos indicaron la seguridad para humanos basados en cuanto a toxicidad por dosis
            repetidas,
            farmacología de seguridad y estudios de genotoxicidad con la oxcarbazepina y su metabolito
            farmacológicamente
            activo, el derivado monohidroxi (MHD).</p>

          <p>Se notó nefrotoxicidad en estudios de toxicidad con dosis repetidas en ratas, pero no en perros ni ratones.
            Debido a que no hay reportes que muestran cambios en pacientes, la relevancia clínica de este hallazgo en
            ratas
            permanece desconocido.</p>

          <img src={"./imgs/bunny.png"} alt="bunny.png" className="w-full mt-4"/>
          <div className="flex flex-col gap-4 bg-gray-300 p-1 rounded">
            <p>Las pruebas inmunoestimulantes en ratones demostraron que el MHD (y en menor grado la oxcarbazepina)
              puede
              inducir una hipersensibilidad demorada. Los estudios en animales demostraron efectos que incluyen aumento
              en
              la
              incidencia de mortalidad embrionaria y retraso en el crecimiento prenatal y/o posnatal con niveles
              maternos
              tóxicos.
              Hubo un aumento en la incidencia de malformaciones fetales de la rata en uno de los ocho estudios de
              toxicidad
              en embriones, que fueron realizados con oxcarbazepina o su metabolito farmacológicamente activo (MHD), a
              una
              dosis que fue mostrado ser tóxica para la madre.</p>

            <p>En estudios de carcinogenicidad se indujeron tumores de células granulares en hígado (ratas y ratones),
              los
              testículos y del tracto genital femenino (ratas) en animales tratados. La recurrencia de tumores hepáticos
              fue
              probablemente debido a una consecuencia de la inducción de las enzimas microsomales hepáticas; un efecto
              inductor que, aunque no puede ser excluido, es débil o se encuentra ausente en pacientes tratados con
              Oxetol®.</p>

            <div className="grid grid-cols-12">
              <p className="col-span-8">Los tumores testiculares pueden haber sido inducidos por concentraciones
                elevadas de
                hormonas luteinizantes. Debido a la ausencia de dicho aumento en humanos, estos tumores no son de
                relevancia
                clínica.
                Se observó un ligero aumento en un número de tumores de células granulares del tracto genital femenino
                en un
                estudio realizado en ratas.</p>
            </div>
          </div>
        </Collapse.Content>
      </Collapse>


    </div>
  );
}