import {MedicinesEnum} from "../../../Medicines.enum";
import {FaChevronLeft} from "react-icons/fa";
import {Collapse} from "react-daisyui";
import CollapseOne from "./components/CollapseOne/CollapseOne.component";


type OxetolPrecautionsProps = {
  setSelectedTab: (tab: MedicinesEnum) => void;
}
export default function OxetolPrecautions({setSelectedTab}: OxetolPrecautionsProps) {
  return (
    <div className="flex flex-col my-8">
      <div className="grid grid-cols-12  px-4">
        <div className="col-span-3">
          <button
            onClick={() => setSelectedTab(MedicinesEnum.MENU)}
            className="flex items-center justify-center gap-2">
            <FaChevronLeft className="w-8 h-8"/>
            <p>Regresar</p>
          </button>
        </div>
        <div className="col-span-9 ml-14 mt-6">
          <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
            <img src={"./imgs/icons/icon-2.png"} alt="icon.1" className=""/>
          </div>
        </div>
      </div>
      <h2 className="font-bold text-xl text-center mt-2">PRECAUCIONES ESPECIALES</h2>
      <Collapse
        icon="arrow"
        className="border-b border-gray-500"
      >
        <Collapse.Title className="text-xl font-medium text-orange-500">
          CONTRAINDICACIONES
        </Collapse.Title>
        <Collapse.Content>
          <CollapseOne/>
        </Collapse.Content>
      </Collapse>

      <Collapse
        icon="arrow"
        className="border-b border-gray-500"

      >
        <Collapse.Title className="text-xl font-medium text-orange-500">
          PRECAUCIONES GENERALES
        </Collapse.Title>
        <Collapse.Content className="flex flex-col gap-4">
          <p>Aquellos pacientes que han tenido reacciones de hipersensibilidad a la carbamazepina deben ser informados
            que
            25-30 % de estas pacientes pueden tener reacciones de hipersensibilidad con Oxetol®. Las reacciones de
            hipersensibilidad pueden ocurrir también en pacientes sin antecedentes de hipersensibilidad a la
            carbamazepina.</p>
          <div className="grid grid-cols-12 gap-4">
            <p className="col-span-7">En general, si llegan a ocurrir signos y síntomas sugestivos de reacciones de
              hipersensibilidad, Oxetol® debe
              ser suspendido de inmediato. Se han reportado casos muy raros de hepatitis, que en la mayoría de los casos
              se
              resuelven favorablemente.</p>
            <img src={"./imgs/warning.png"} alt="warning.png" className="w-full col-span-5"/>
          </div>
          <p>En casos de sospecha de hepatitis se debe descontinuar el uso de Oxetol®. Para aquellas mujeres en edad
            reproductiva, deben ser avisadas que el uso concomitante de Oxetol® con anticonceptivos hormonales puede
            quitar
            la inefectividad de uso de este último. Se recomienda el uso de métodos no hormonales de anticoncepción
            cuando
            se esté utilizando Oxetol®.</p>
        </Collapse.Content>
      </Collapse>

      <Collapse
        icon="arrow"
        className="border-b border-gray-500"

      >
        <Collapse.Title className="text-xl font-medium text-orange-500">
          RESTRICCIONES DE USO DURANTE EL EMBARAZO Y LA LACTANCIA
        </Collapse.Title>
        <Collapse.Content className="flex flex-col gap-4">
          <div className="bg-orange-300 p-1 rounded">
            <p>El resultado sobre un número limitado de embarazos indican que la oxcarbazepina puede causar defectos de
              nacimiento muy serios (por ejemplo el paladar hendido) cuando es administrado durante el embarazo. Si se
              embarazan las mujeres que se encuentren tomando Oxetol®, o si se requiere iniciar Oxetol® durante el
              embarazo,
              se tendrán que valorar los beneficios potenciales del fármaco contra sus riesgos potenciales en causar
              malforma- ciones fetales. Esto es particularmente importante durante los primeros tres meses del
              embarazo.</p>
            <div className="grid grid-cols-12">
              <p className="col-span-8">La oxcarbazepina y su metabolito activo (MHD) cruzan la barrera placentaria. Las
                concentraciones plasmáticas neonatales y maternas de MHD fueron similares en un caso. La oxcarbazepina y
                su
                metabolito activo (MHD) son excretados en la leche humana. Se registro a conciencia la concentración de
                la
                leche en plasma de 0.5 para ambos. Los efectos en los lactantes expuestos a Oxetol® por esta vía se
                desconocen. Por lo tanto Oxetol® no debe utilizarse durante la lactancia.
              </p>
            </div>
          </div>

        </Collapse.Content>
      </Collapse>

    </div>
  );
}