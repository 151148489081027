import {MedicinesEnum} from "../../../Medicines.enum";
import {FaChevronLeft} from "react-icons/fa";
import {Collapse} from "react-daisyui";

type DosisProps = {
    setSelectedTab: (tab: MedicinesEnum) => void;
}

export default function Dosis({setSelectedTab}: DosisProps) {
    return (
        <div className="flex flex-col my-8">
            <div className="grid grid-cols-12  px-4">
                <div className="col-span-3">
                    <button
                        onClick={() => setSelectedTab(MedicinesEnum.MENU)}
                        className="flex items-center justify-center gap-2">
                        <FaChevronLeft className="w-8 h-8"/>
                        <p>Regresar</p>
                    </button>
                </div>
                <div className="col-span-9 ml-14 mt-6">
                    <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
                        <img src={"./imgs/icons/icon-4.png"} alt="icon.1" className=""/>
                    </div>
                </div>
            </div>
            <h2 className="font-bold text-xl text-center mt-2">DOSIFICACIÓN, SOBREDOSIS Y CONSIDERACIONES</h2>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    DOSIS Y VIA DE ADMINISTRACION
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <p>Las tabletas deben deglutirse con agua y pueden tomarse con o sin los alimentos.</p>
                    <img src={"./imgs/sunam-dosis.png"} alt="dosis.png" className="w-full"/>
                </Collapse.Content>
            </Collapse>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    Tratamiento de la enfermedad de Parkinson
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <p>Las dosis total diaria debe ser repartida en tres tabletas al día.</p>
                    <h2 className="text-orange-300">Tratamiento inicial</h2>
                    <p>La dosis debe aumentarse gradualmente, comenzando con 0.375 mg/día, fraccionadas en tres tomas,
                        cada 8 horas, incrementándose en lo sucesivo cada 5-7 días en caso de que los pacientes no
                        sufran de reacciones secundarias intolerables. La dosis deberá ajustarse hasta lograr un efecto
                        terapéutico máximo.</p>
                    <p>En caso de que sea necesario incrementar la dosis últimamente citada, ésta deberá aumentarse
                        administrando 0.750 mg adicionales en intervalos semanales, hasta llegar a una dosis máxima de
                        4.5 mg al día.</p>
                    <h2 className="text-orange-300">Tratamiento de mantenimiento</h2>
                    <p>La dosis individual deberá encontrarse en el rango de 0.375 mg hasta un máximo de 4.5 mg al día.
                        El escalamiento de la dosis en tres estudios pivote realizados en pacientes con Parkinson en
                        estadio inicial o avanzado, se logró la efectividad terapéutica con dosis diarias de 1.5 mg.
                        <br></br><br></br>
                        Lo anterior no excluye la posibilidad de que en casos especiales se requiere de dosis mayores a
                        1.5 mg para lograr un beneficio terapéutico adicional. Esto ocurre particularmente en estadios
                        avanzados cuando se pretende disminuir la dosis de levodopa.</p>
                    <h2 className="text-orange-300">Descontinuación del tratamiento</h2>
                    <p>Pramipexol debe ser paulatinamente retirado en el transcurso de varios días.</p>
                    <h2 className="text-orange-300">Dosis en pacientes bajo tratamiento concomitante con levodopa</h2>
                    <p>Se recomienda que la dosis de levodopa disminuye tanto al irse ajustando la dosis de Pramipexol
                        como durante el tratamiento de mantenimiento a fin de evitar una excesiva estimulación
                        dopaminérgica.</p>
                    <h2 className="text-orange-300">Dosis con insuficiencia renal</h2>
                    <p>La eliminación de Pramipexol es dependiente de la función renal. El siguiente esquema de dosis es
                        sugerido para el inicio del tratamiento: los pacientes con depuración de creatinina mayor a 50
                        ml/min no requieren reducción de la dosis diaria. En pacientes con depuración de creatinina
                        entre 20 y 50 ml/min, la dosis inicial deberá fraccionarse en dos tomas al día, comenzando con
                        una dosis de 0.125 mg 2 veces al día (0.250 mg al día). En pacientes con depuración de
                        creatinina menor de 20 ml/min, deberá administrarse sólo 1 dosis de 0.125 mg al día. Si la
                        función renal disminuye durante el tratamiento de mantenimiento, se deberá disminuir la dosis
                        del medicamento en la misma proporción porcentual de la disminución de la depuración de
                        creatinina, por ejemplo si la depuración disminuye 30%, la dosis del medicamento se disminuirá
                        en la misma proporción (30%). La dosis del medicamento puede fraccionarse en dos si la
                        depuración de creatinina se encuentra entre 20 y 50 ml/min. Puede administrarse una sola dosis
                        si dicha depuración es menor de 20 ml/min.
                    </p>
                    <h2 className="text-orange-300">Dosis con insuficiencia hepática</h2>
                    <p>No es necesario ajustar la dosificación en sujetos con insuficiencia hepática. </p>

                </Collapse.Content>
            </Collapse>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    SÍNDROME DE PIERNAS INQUIETAS
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <h2 className="text-orange-300">Tratamiento Inicial</h2>
                    <p>0.125 mg una vez al día, 2 a 3 horas antes de acostarse. En pacientes que requieren alivio
                        sintomático adicional, la dosis debe ser incrementada cada 4 a 7 días hasta un máximo de 0.75 mg
                        por día.</p>
                    <h2 className="text-orange-300">Dosis en pacientes con insuficiencia renal</h2>
                    <p>La eliminación de Pramipexol es dependiente de la función renal y mantiene una estrecha relación
                        con la depuración de creatinina. La extrapolación a partir de un estudio de farmacocinética
                        realizado en sujetos con insuficiencia renal, indica que los pacientes con una depuración de
                        creatinina mayor a 20 ml/min, no requieren ajuste de la dosis. El uso de Pramipexol en pacientes
                        con síndrome de piernas inquietas y con insuficiencia renal no ha sido estudiado.
                    </p>
                    <h2 className="text-orange-300">Dosis en pacientes con insuficiencia hepática</h2>
                    <p>No es necesario ajustar la dosificación en sujetos con insuficiencia hepática, ya que
                        aproximadamente el 90% del medicamento absorbido es eliminado por los riñones.</p>
                    <h2 className="text-orange-300">Dosis en niños y adolescentes</h2>
                    <p>La seguridad y eficacia de Pramipexol en niños y adolescentes menores de 18 años no ha sido
                        establecida.</p>

                </Collapse.Content>
            </Collapse>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    MANIFESTACIONES Y MANEJO DE LA SOBREDOSIFICACIÓN O INGESTA ACCIDENTAL
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <h2 className="text-orange-300">Síntomas</h2>
                    <p>No existe experiencia clínica relacionada con la sobredosificación, sin embargo, los eventos
                        adversos están relacionados con el perfil farmacodinámico de un agonista dopaminérgico los
                        cuales incluyen náusea, vómito, hipercinesia, alucinaciones, agitación e hipotensión
                        arterial. </p>
                    <h2 className="text-orange-300">Manejo</h2>
                    <p>No existe un antagonista específico de los agonistas dopaminérgicos. Se recomienda lavado
                        gástrico, administración intravenosa de líquidos y monitoreo electrocardiográfico. En caso de
                        manifestarse signos de estimulación del sistema nervioso central, se pueden emplear agentes
                        neurolépticos. No parece ser de utilidad la hemodiálisis.</p>

                </Collapse.Content>
            </Collapse>
        </div>
    );
}