import {MedicinesEnum} from "../../../Medicines.enum";
import {FaChevronLeft} from "react-icons/fa";
import {Collapse} from "react-daisyui";
import CollapseOne from "./components/CollapseOne/CollapseOne.component";


type PrecautionsProps = {
    setSelectedTab: (tab: MedicinesEnum) => void;
}
export default function Precautions({setSelectedTab}: PrecautionsProps) {
    return (
        <div className="flex flex-col my-8">
            <div className="grid grid-cols-12  px-4">
                <div className="col-span-3">
                    <button
                        onClick={() => setSelectedTab(MedicinesEnum.MENU)}
                        className="flex items-center justify-center gap-2">
                        <FaChevronLeft className="w-8 h-8"/>
                        <p>Regresar</p>
                    </button>
                </div>
                <div className="col-span-9 ml-14 mt-6">
                    <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
                        <img src={"./imgs/icons/icon-2.png"} alt="icon.1" className=""/>
                    </div>
                </div>
            </div>
            <h2 className="font-bold text-xl text-center mt-2">PRECAUCIONES ESPECIALES</h2>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    CONTRAINDICACIONES
                </Collapse.Title>
                <Collapse.Content>
                    <CollapseOne/>
                </Collapse.Content>
            </Collapse>

            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    PRECAUCIONES GENERALES
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <p>De acuerdo con las prácticas clínicas actuales, si levetiracetam debe descontinuarse, se
                        recomienda que el retiro se realice gradualmente (por ejemplo, en adultos, disminución de 500 mg
                        dos veces al día cada dos a cuatro semanas; en niños, la disminución de dosis no debe exceder el
                        decremento de 10mg/kg dos veces al día cada dos semanas)
                        La administración de levetiracetam a pacientes con disfunción renal puede requerir que se adapte
                        la dosis. En los pacientes con disfunción hepática severa se recomienda un estudio de la función
                        renal antes de seleccionar la dosis.
                        Debido a las diferencias individuales en la sensibilidad algunos pacientes pueden presentar al
                        inicio del tratamiento o con el aumento progresivo de la dosis, somnolencia u otros síntomas
                        relacionados con el sistema nervioso central. Por lo que se recomienda precaución a los
                        pacientes que realicen actividades que le pongan en riesgo.
                        Se ha reportado suicidio, intento de suicidio e ideas suicidas en pacientes tratados con
                        levetiracetam. Los pacientes deben ser informados de reportar inmediatamente a su médico
                        tratante cualquier síntoma de depresión y/o idea.
                    </p>
                    <h2 className="text-orange-300">Efectos en la habilidad para manejar y el uso de maquinaria</h2>
                    <p>Hasta este momento no se han realizado estudios sobre los efectos de levetiracetam en la
                        habilidad para el manejo y/o uso de máquinas. Debido a las diferencias en la sensibilidad de
                        cada individuo, algunos pacientes experimentan somnolencia u otros síntomas asociados al sistema
                        nervioso central, tanto al inicio del tratamiento como después de un incremento de la dosis. Se
                        debe aconsejar a los pacientes que extremen las medidas de precaución al manejar maquinaria,
                        incluyendo vehículos de motor, mientras están en tratamiento con levetiracetam, ya que ésta
                        puede producir somnolencia.</p>

                </Collapse.Content>
            </Collapse>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    RESTRICCIONES DE USO DURANTE EL EMBARAZO Y LA LACTANCIA:
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <p>No hay datos disponibles del uso de levetiracetam en mujeres embarazadas. Los estudios en
                        animales han mostrado toxicidad reproductiva. Se desconoce el riesgo potencial en humanos,
                        levetiracetam no debe de administrarse en el embarazo a menos que sea claramente necesario. Como
                        con el uso de otros fármacos antiepilépticos, los cambios fisiológicos durante el embarazo
                        pueden afectar la concentración de levetiracetam. Sin embargo, no se han encontrado reportes que
                        demuestren la disminución de la concentración plasmática de levetiracetam durante el embarazo.
                        La discontinuación, disminución del tratamiento con antiepilépticos puede provocar el
                        empeoramiento de la enfermedad y dañar a la madre y al feto. El levetiracetam se excreta en la
                        leche materna. Por lo que no se recomienda amamantar durante su uso.</p>

                </Collapse.Content>
            </Collapse>
        </div>
    )
        ;
}