import React from 'react';
import { useState } from 'react';
import { VscLoading } from 'react-icons/vsc';
import { FcGoogle } from 'react-icons/fc';
import { toast } from 'sonner';
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
    GoogleAuthProvider,
    sendPasswordResetEmail
} from 'firebase/auth';
import { auth, db } from '../../lib/firebase';
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import {GiMedicines} from "react-icons/gi";
import {useNavigate} from "react-router-dom";
import {useSessionContext} from "../../Providers/SessionProvider/Session.context";

export function LoginPage() {
    const [loading, setLoading] = useState(false);
    const [isLogin, setIsLogin] = useState(true);
    const [resetPassword,setResetPassword] = useState(false)

    const {setUser} = useSessionContext();
    const navigate = useNavigate();

    const handleSubmit = async (event: any) => {
        setLoading(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        const { email, password, userName } = Object.fromEntries(formData);
        if (typeof email !== 'string' || typeof password !== 'string')
            return toast.error('Error al iniciar sesion');
        if (isLogin) {

            await signInWithEmailAndPassword(auth, email, password).then(async (userCredential) => {
                setUser({
                    id: userCredential.user.uid,
                    email: email,
                    name: null,
                    token: 'test',
                });
                navigate('/dashboard');
            }).catch((e) => {
                toast.error(`Error al iniciar sesion ${e}`);
            });
        } else {
            try {
                const res = await createUserWithEmailAndPassword(auth, email, password);
                await setDoc(doc(db, 'users', res.user.uid), {
                    userName: userName,
                    email: email,
                    id: res.user.uid,
                });
                setUser({
                    id: res.user.uid,
                    email: email,
                    name: userName.toString(),
                    token: res.user.uid,
                });
                setIsLogin(true);
                toast.success('Usuario creado correctamente');
                navigate('/dashboard');
            } catch (e) {
                toast.error(`Error al iniciar sesion ${e}`);
            }
        }
        setLoading(false);
    };

    const handleGoogleLogin = async () => {
        setLoading(true);

        const userCollectionsRef = collection(db, 'users');

        try {
            signInWithPopup(auth, new GoogleAuthProvider()).then(async (userCredential) => {
                const user = userCredential.user;
                const data = await getDocs(userCollectionsRef);
                const emails = data.docs.map((doc) => ({ ...doc.data() }));
                if (emails.find((email) => email.email === user.email)) {
                    toast.success('Usuario ya registrado');
                    setUser({
                        id: user.uid,
                        email: user.email,
                        name: user.displayName,
                        token: user.uid,
                    });
                    navigate('/dashboard');
                    return;
                } else {
                    await setDoc(doc(db, 'users', user.uid), {
                        userName: user.displayName,
                        email: user.email,
                        id: user.uid,
                    });
                    setUser({
                        id: user.uid,
                        email: user.email,
                        name: user.displayName,
                        token: user.uid,
                    });
                    toast.success('Usuario creado correctamente');
                    navigate('/dashboard');
                }
            });
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    const handleResetPassword = async (event: any) => {
        setLoading(true);
        event.preventDefault();
        try{
            const formData = new FormData(event.target);
            const { email } = Object.fromEntries(formData);
            if (typeof email !== 'string') return toast.error('Error al iniciar sesion');
            await sendPasswordResetEmail(auth, email);
            toast.success('Te enviamos un link al correo para recuperar tu contraseña')
        }catch (err){
            console.error(`Error al mandar correo`)
            toast.error(`Error al mandar el correo ${err}`)
        }
        setLoading(false)
    }

    return (
        <div className="grid grid-cols-12 w-full text-gray-800">
            <div className="col-span-12 md:col-span-4 bg-white h-screen shadow-2xl ">
                <div className="flex flex-col justify-center items-center h-full ">
                    <h1 className="text-4xl font-bold">Medic WebApp</h1>
                    <p className="text-gray-500 mt-4">Inicia sesion con tu cuenta o registrate</p>
                    {resetPassword ?
                        <form onSubmit={handleResetPassword} className="flex flex-col w-72 mt-8">
                            <label className="text-sm font-semibold mt-4">Correo</label>
                            <input
                                required
                                name="email"
                                placeholder={'Example@email.com'}
                                type="email"
                                className="border border-gray-200 rounded-xl p-2 mt-2"
                            />
                            <div className="flex justify-end items-center mt-4">
                                <button
                                    type={'button'}
                                    onClick={() => setResetPassword(false)}
                                    className="text-blue-500 text-sm font-bold">
                                    ya tengo mi contraseña
                                </button>
                            </div>
                            <button
                                disabled={loading}
                                type={'submit'}
                                className="bg-blue-950 text-white rounded-xl p-2 mt-4"
                            >
                                {loading ? (
                                    <VscLoading className="animate-spin w-4 h-5 my-auto mx-auto" />
                                ) : 'Recuperar contraseña'}
                            </button>
                        </form>
                        :

                        <form onSubmit={handleSubmit} className="flex flex-col w-72 mt-8">
                            {!isLogin && (
                                <>
                                    <label className="text-sm font-semibold mt-4">Nombre de usuario</label>
                                    <input
                                        name={'userName'}
                                        required
                                        placeholder={'Johny Doe'}
                                        type="text"
                                        className="border border-gray-200 rounded-xl p-2 mt-2"
                                    />
                                </>
                            )}
                            <label className="text-sm font-semibold mt-4">Correo</label>
                            <input
                                required
                                name="email"
                                placeholder={'Example@email.com'}
                                type="email"
                                className="border border-gray-200 rounded-xl p-2 mt-2"
                            />
                            <label className="text-sm font-semibold mt-4">Contraseña</label>
                            <input
                                required
                                name={'password'}
                                placeholder={'********'}
                                type="password"
                                className="border border-gray-200 rounded-xl p-2 mt-2"
                            />
                            {!isLogin && (
                                <>
                                    <label className="text-sm font-semibold mt-4">Confirmar contraseña</label>
                                    <input
                                        name={'confirmPassword'}
                                        required
                                        placeholder={'********'}
                                        type="password"
                                        className="border border-gray-200 rounded-xl p-2 mt-2"
                                    />
                                </>
                            )}

                            {isLogin && (
                                <div className="flex justify-end items-center mt-4">
                                    <button
                                        type={'button'}
                                        onClick={() => setResetPassword(true)}
                                        className="text-blue-500 text-sm font-bold">
                                        Olvidaste la contraseña?
                                    </button>
                                </div>
                            )}

                            <button
                                disabled={loading}
                                type={'submit'}
                                className="bg-blue-950 text-white rounded-xl p-2 mt-4"
                            >
                                {loading ? (
                                    <VscLoading className="animate-spin w-4 h-5 my-auto mx-auto" />
                                ) : isLogin ? (
                                    'Iniciar Sesion'
                                ) : (
                                    'Registrarse'
                                )}
                            </button>
                        </form>
                    }

                    <div className="flex flex-col mt-8 w-72 gap-4">
                        <div className="flex flex-row justify-center">
                            <hr className="border border-gray-200 w-72 my-auto " />
                            <p className="my-auto mx-2">Or</p>
                            <hr className="border border-gray-200 w-72 my-auto" />
                        </div>
                        <button
                            disabled={loading}
                            onClick={handleGoogleLogin}
                            type={'button'}
                            className="hover:bg-blue-100 transition ease-in-out rounded-xl p-2 flex flex-row gap-2 justify-center border border-gray-400"
                        >
                            {loading ? (
                                <VscLoading className="animate-spin w-4 h-5 my-auto mx-auto" />
                            ) : (
                                <>
                                    <FcGoogle className="my-auto w-5 h-5" />
                                    Inicia sesion con Google
                                </>
                            )}
                        </button>
                        {!resetPassword &&
                            <button
                                type={'button'}
                                onClick={() => setIsLogin(!isLogin)}
                                className="hover:bg-blue-100 transition ease-in-out rounded-xl p-2 flex flex-row gap-2 justify-center border border-gray-400"
                            >
                                {isLogin ? 'Crear una cuenta' : 'Ya tengo una cuenta'}
                            </button>
                        }

                    </div>
                </div>
            </div>
            <div className="col-span-12 md:col-span-8  h-screen  hidden md:block">
                <div className="flex flex-col justify-center items-center h-full -mt-16">
                    <GiMedicines className="w-96 h-96 text-blue-300"/>
                    <p className="text-xl font-bold text-white">Habla con confianza, comunica con clase</p>
                </div>
            </div>
        </div>
    );
};
