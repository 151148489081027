import {MedicinesEnum} from "../../../Medicines.enum";
import {FaChevronLeft} from "react-icons/fa";
import {Collapse} from "react-daisyui";
import CollapseOne from "./components/CollapseOne/CollapseOne.component";


type PrecautionsProps = {
    setSelectedTab: (tab: MedicinesEnum) => void;
}
export default function Precautions({setSelectedTab}: PrecautionsProps) {
    return (
        <div className="flex flex-col my-8">
            <div className="grid grid-cols-12  px-4">
                <div className="col-span-3">
                    <button
                        onClick={() => setSelectedTab(MedicinesEnum.MENU)}
                        className="flex items-center justify-center gap-2">
                        <FaChevronLeft className="w-8 h-8"/>
                        <p>Regresar</p>
                    </button>
                </div>
                <div className="col-span-9 ml-14 mt-6">
                    <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
                        <img src={"./imgs/icons/icon-2.png"} alt="icon.1" className=""/>
                    </div>
                </div>
            </div>
            <h2 className="font-bold text-xl text-center mt-2">PRECAUCIONES ESPECIALES</h2>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    CONTRAINDICACIONES
                </Collapse.Title>
                <Collapse.Content>
                    <CollapseOne/>
                </Collapse.Content>
            </Collapse>

            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    PRECAUCIONES GENERALES
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <p>Los pacientes con enfermedad de Alzheimer pierden peso. El tratamiento con inhibidores de la
                        colinesterasa, incluyendo a la Galantamina, se ha asociado con la pérdida de peso en esos
                        pacientes.<br/>
                        Durante el tratamiento el peso de los pacientes debe ser monitoreado.
                        Como con otros colinomiméticos, Galantamina debe administrarse con precaución en las siguientes
                        situaciones:</p>
                    <h2 className="text-orange-300">Afecciones cardiovasculares</h2>
                    <p>Debido a su acción farmacológica, los colinomiméticos pueden tener efectos vagotónicos sobre la
                        frecuencia cardiaca (por ejemplo: bradicardia). El potencial de estas acciones puede ser
                        particularmente importante en pacientes con "síndrome del seno enfermo" o con otros trastornos
                        de la conducción cardiaca supraventricular o en quienes utilizan concomitantemente medicamentos
                        que reducen significativamente la frecuencia cardiaca como la digoxina y los B-bloqueadores. En
                        estudios clínicos, el uso de Galantamina se ha asociado con síncope y raras veces con
                        bradicardia severa</p>
                    <h2 className="text-orange-300">Afecciones gastrointestinales</h2>
                    <p>Pacientes en un riesgo mayor de úlcera péptica, por ejemplo con historia de enfermedad ulcerosa o
                        aquéllos con predisposición a esas alteraciones, incluyendo a aquellos que reciben
                        antiinflamatorios no esteroideos (AINEs), deben ser monitoreados en la aparición de síntomas.
                        Sin embargo, los estudios clínicos con Galantamina no muestran incrementos relacionados a
                        placebo, en la incidencia tanto de úlcera péptica gastrointestinal.<br/>
                        El uso de Galantamina no es recomendado en pacientes con obstrucción gastrointestinal o en
                        recuperación de cirugía gastrointestinal.</p>
                    <h2 className="text-orange-300">Alteraciones Neurológicas </h2>
                    <p>Aunque se cree que los colinomiméticos pueden tener algún potencial para causar crisis
                        convulsivas, la actividad epiléptica también puede ser una manifestación de la enfermedad de
                        Alzheimer.</p>
                    <h2 className="text-orange-300">Alteraciones Neurológicas </h2>
                    <p>Aunque se cree que los colinomiméticos pueden tener algún potencial para causar crisis
                        convulsivas, la actividad epiléptica también puede ser una manifestación de la enfermedad de
                        Alzheimer.</p>
                    <h2 className="text-orange-300">Afecciones Pulmonares </h2>
                    <p>Debido a sus acciones colino mimética, los colinomiméticos deben ser prescritos con precaución en
                        pacientes con historia de asma severa o enfermedad pulmonar obstructiva.</p>
                    <h2 className="text-orange-300">Genitourinarios </h2>
                    <p>El uso de Galantamina no es recomendado en pacientes con obstrucción del flujo urinario o en
                        recuperación de cirugía de la vejiga.</p>
                    <h2 className="text-orange-300">Niños </h2>
                    <p>El uso de Galantamina no es recomendado en niños. No hay datos disponibles del uso de Galantamina
                        en pacientes pediátricos.
                    </p>
                    <h2 className="text-orange-300">Insuficiencia hepática o renal </h2>
                    <p>Los niveles de Galantamina en plasma quizá se incrementen en pacientes con moderado a severo
                        deterioro hepático o deterioro renal (véase Dosis y vía de administración).</p>
                    <h2 className="text-orange-300">Efectos sobre la habilidad para manejar o utilizar maquinaria</h2>
                    <p>La enfermedad de Alzheimer puede
                        causar un deterioro gradual de las capacidades para manejar, o comprometer la habilidad para
                        utilizar maquinaria. Además, como otros colinomiméticos, Galantamina puede causar vértigo o
                        somnolencia, lo cual podría afectar la habilidad para manejar o utilizar maquinaria,
                        especialmente durante las primeras semanas después de iniciar el tratamiento.</p>


                </Collapse.Content>
            </Collapse>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    RESTRICCIONES DE USO DURANTE EL EMBARAZO
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <h2 className="text-orange-300">Embarazo</h2>
                    <p>No se dispone de estudios sobre el uso de Galantamina en mujeres embarazadas. Galantamina sólo
                        debe usarse en el embarazo si los beneficios potenciales justifican el riesgo potencial al
                        feto.</p>
                    <h2 className="text-orange-300">Lactancia</h2>
                    <p>Se desconoce si Galantamina se excreta en la leche materna humana y no se dispone de estudios en
                        mujeres en periodo de lactancia
                        No se use en el embarazo y la lactancia.</p>


                </Collapse.Content>
            </Collapse>

        </div>
    )
        ;
}