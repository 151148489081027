export default function CollapseTwo() {
    return (
        <div className="flex flex-col my-2 gap-4">
            <p>Antiparkinsoniano. Pramipexol está indicado para el tratamiento de los signos y síntomas de la enfermedad
                de Parkinson idiopática en estadio temprano o avanzado. Puede ser empleado como tratamiento único o
                asociado a levodopa. Para el tratamiento sintomático de síndrome de piernas inquietas idiopático
                (RLS). </p>
            <img src={"./imgs/sunam-box.png"} alt="sunam-box.png" className="col-span-6"/>

        </div>
    );
}