import {MedicinesEnum} from "../../../Medicines.enum";
import {FaChevronLeft} from "react-icons/fa";
import {Collapse} from "react-daisyui";
import CollapseOne from "./components/CollapseOne/CollapseOne.component";


type PrecautionsProps = {
    setSelectedTab: (tab: MedicinesEnum) => void;
}
export default function Precautions({setSelectedTab}: PrecautionsProps) {
    return (
        <div className="flex flex-col my-8">
            <div className="grid grid-cols-12  px-4">
                <div className="col-span-3">
                    <button
                        onClick={() => setSelectedTab(MedicinesEnum.MENU)}
                        className="flex items-center justify-center gap-2">
                        <FaChevronLeft className="w-8 h-8"/>
                        <p>Regresar</p>
                    </button>
                </div>
                <div className="col-span-9 ml-14 mt-6">
                    <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
                        <img src={"./imgs/icons/icon-2.png"} alt="icon.1" className=""/>
                    </div>
                </div>
            </div>
            <h2 className="font-bold text-xl text-center mt-2">PRECAUCIONES ESPECIALES</h2>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    CONTRAINDICACIONES
                </Collapse.Title>
                <Collapse.Content>
                    <CollapseOne/>
                </Collapse.Content>
            </Collapse>

            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    PRECAUCIONES GENERALES
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <p>Administrar citicolina en combinación con hipotensores puede
                        aumentar su efecto. </p>

                </Collapse.Content>
            </Collapse>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    RESTRICCIONES DE USO DURANTE EL EMBARAZO
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <p>No se recomienda su uso en el embarazo, lactancia, ni en niños
                        menores de 3 años. </p>
                </Collapse.Content>
            </Collapse>

            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    INTERACCIONES MEDICAMENTOSAS Y DE OTRO GÉNERO
                </Collapse.Title>
                <Collapse.Content>
                    <p>No se administre con meclofenoxato pues puede producir irritabilidad
                        e hiperexcitabilidad del SNC.
                        No es recomendable administrarla junto con hipotensores, ya que
                        puede potenciar su efecto. </p>
                </Collapse.Content>
            </Collapse>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    PRECAUCIONES Y RELACION CON EFECTOS DE CARCINOGENESIS,
                    MUTAGENESIS, TERATOGENESIS, Y SOBRE LA FERTILIDAD                </Collapse.Title>
                <Collapse.Content>
                    <p>En casos de hemorragia intracraneal persistente se recomienda no
                        sobre pasar la dosis de 1,000 mg de Citicolina al día. No produce
                        efectos teratogénicos, ni es un producto potencialmente
                        cancerogénico. </p>
                </Collapse.Content>
            </Collapse>


        </div>
    )
        ;
}