import {MedicinesEnum} from "../../../Medicines.enum";
import {FaChevronLeft} from "react-icons/fa";
import {Collapse} from "react-daisyui";

type OxetolDosisProps = {
  setSelectedTab: (tab: MedicinesEnum) => void;
}

export default function OxetolDosis({setSelectedTab}: OxetolDosisProps) {
  return (
    <div className="flex flex-col my-8">
      <div className="grid grid-cols-12  px-4">
        <div className="col-span-3">
          <button
            onClick={() => setSelectedTab(MedicinesEnum.MENU)}
            className="flex items-center justify-center gap-2">
            <FaChevronLeft className="w-8 h-8"/>
            <p>Regresar</p>
          </button>
        </div>
        <div className="col-span-9 ml-14 mt-6">
          <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
            <img src={"./imgs/icons/icon-4.png"} alt="icon.1" className=""/>
          </div>
        </div>
      </div>
      <h2 className="font-bold text-xl text-center mt-2">DOSIFICACIÓN</h2>
      <Collapse
        icon="arrow"
        className="border-b border-gray-500"
      >
        <Collapse.Title className="text-xl font-medium text-orange-500">
          DOSIS Y VIA DE ADMINISTRACION
        </Collapse.Title>
        <Collapse.Content className="flex flex-col gap-4">
          <p>ORAL</p>
          <p>Oxetol® puede ser utilizado como monoterapia o en combinación con otros fármacos antiepilépticos.</p>

          <p>En la terapia mono y concomitante, el tratamiento con Oxetol® se inicia con una dosis clínicamente efectiva
            dividida en dos tomas. La dosis se puede incrementar dependiendo de la respuesta clínica del paciente.</p>

          <img src={"./imgs/pills.png"} alt="pills.png" className="w-full"/>
          <p>Cuando otros fármacos antiepilépticos son reemplazados por Oxetol®, las dosis de los fármacos
            antiepilépticos
            concomitantes deben disminuirse gradualmente una vez que se inicie la terapia con Oxetol®.</p>

          <p>En la terapia concomitante, ya que aumenta la carga total de fármacos antiepilépticos, la dosis de los
            fármacos
            antiepilépticos quizá necesiten ser disminuidos y/o la dosis de Oxetol® aumentada a una velocidad más
            lenta.</p>

          <p>Oxetol® puede ser administrado con o sin los alimentos. Las siguientes recomendaciones de dosificación se
            aplican a todos los pacientes en ausencia de una disfunción renal. No es necesario el monitoreo de los
            niveles
            plasmáticos del fármaco para la optimización de la terapia con Oxetol®.</p>
        </Collapse.Content>
      </Collapse>
      <Collapse
        icon="arrow"
        className="border-b border-gray-500"
      >
        <Collapse.Title className="text-xl font-medium text-orange-500">
          Pacientes con disfunción hepática
        </Collapse.Title>
        <Collapse.Content className="flex flex-col gap-4">
          <div className="grid grid-cols-12 gap-2">
            <p className="col-span-6">No se requiere ajustar la dosis en pacientes con falla hepática leve a moderada.
              Oxetol® no ha sido estudiado en pacientes con insuficiencia hepática severa.
            </p>
            <img src={"./imgs/higa.png"} alt="higa.png" className="col-span-6"/>
          </div>
        </Collapse.Content>
      </Collapse>
      <Collapse
        icon="arrow"
        className="border-b border-gray-500"
      >
        <Collapse.Title className="text-xl font-medium text-orange-500">
          Pacientes con disfunción renal
        </Collapse.Title>
        <Collapse.Content className="flex flex-col gap-4">
          <img src={"./imgs/rino.png"} alt="rino.png" className="col-span-6"/>
          <p>En pacientes con disfunción renal (niveles de depuración de creatinina menores a 30 ml/min), se debe
            iniciar el
            tratamiento con Oxetol® a la mitad de la dosis usual de inicio (300 mg/día) y aumentar la dosis lentamente
            hasta
            lograr Ia respuesta clínica deseada.
          </p>
        </Collapse.Content>
      </Collapse>
      <Collapse
        icon="arrow"
        className="border-b border-gray-500"
      >
        <Collapse.Title className="text-xl font-medium text-orange-500">
          Adultos y Ancianos: Monoterapia
        </Collapse.Title>
        <Collapse.Content className="flex flex-col gap-4">
          <img src={"./imgs/old.png"} alt="old.png" className="col-span-6"/>
          <p>Oxetol® debe iniciarse con una dosis de 600 mg /día (8-10 mg/kg/día) administrado en dos dosis divididas.
            Se
            observan buenos efectos terapéuticos con dosis que oscilan entre 600 mg/día y 2400 mg/día.</p>

          <p>Si existe la indicación la dosis puede incrementarse a un máximo de 600 mg/día con intervalos semanales
            desde
            la
            dosis inicial para lograr la respuesta clínica deseada. Las dosis diarias por arriba de 2400 mg/día no han
            sido
            estudiadas sistemáticamente en ensayos clínicos.</p>

          <p><strong>Niños:</strong> En la monoterapia y la terapia concomitante, Oxetol® debe iniciarse con una dosis
            de
            8-10mg/kg/día
            dividida en dos tomas.</p>

          <p>En la terapia concomitante, se han observado buenos efectosterapéuticos con una dosis de mantenimiento
            promedio
            de aproximadamente 30 mg/kg/día. Si está clínicamente indicado, la dosis puede aumentar por un máximo de 10
            mg/kg/día en intervalos semanales partiendo de la dosis inicial, a una dosis máxima de 46 mg/kg/día, para
            lograr
            la respuesta clínica deseada.</p>

          <p>Oxetol® no ha sido estudiado en niños menores de 6 años.</p>
        </Collapse.Content>
      </Collapse>
      <Collapse
        icon="arrow"
        className="border-b border-gray-500"
      >
        <Collapse.Title className="text-xl font-medium text-orange-500">
          SOBREDOSIFICACION 0 INGESTA ACCIDENTAL, MANIFESTACIONES Y MANEJO
          (ANTIDOTOS)
        </Collapse.Title>
        <Collapse.Content className="flex flex-col gap-4">
          <p>Se han reportado casos aislados de sobredosis. La dosis máxima ingerida fue de aproximadamente 24,000 mg.
            Todos
            los pacientes se recuperan con tratamiento sintomático. Los síntomas de sobredosis incluyen somnolencia,
            mareo,
            nausea, vómito, hipocinesia, ataxia y nistagmus. No hay antídoto especifico. Se debe administrar tratamiento
            sintomático y de soporte. Debe ser considerada la realización de un lavado gástrico y/o in activación con
            administración de carbón activado.
          </p>
        </Collapse.Content>
      </Collapse>

    </div>
  );
}