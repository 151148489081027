export default function CollapseOne() {
    return (
        <div className="flex flex-col my-2 gap-4">
            <p>Galantamina no debe administrarse a pacientes con hipersensibilidad conocida al bromhidrato de
                Galantamina o cualquier otro excipiente usado en la formulación. Insuficiencia hepática y/o renal
                severa, embarazo y lactancia.</p>
            <div className="grid grid-cols-3 gap-5 p-4 justify-center">
                <img src={"./imgs/langi-1.png"} alt="precaution" className="w-3/4"/>
                <img src={"./imgs/langi-2.png"} alt="precaution" className="w-3/4"/>
                <img src={"./imgs/langi-3.png"} alt="precaution" className="w-3/4"/>
            </div>
        </div>
    )
        ;
}