export default function CollapseOne() {
    return (
        <div className="flex flex-col my-2 gap-4">
            <p>El valproato semisódico no debe administrarse a pacientes con enfermedad hepática o disfunción hepática
                significativa. El valproato semisódico está contraindicado en pacientes con hipersensibilidad conocida
                al medicamento. También está contraindicado en pacientes con trastorno en el ciclo de la urea (véase
                Precauciones generales).</p>
        </div>
    )
        ;
}