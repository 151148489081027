export default function CollapseTwo() {
    return (
        <div className="flex flex-col my-2 gap-4">
            <p>Vasodilatador cerebral</p>
            <h2 className="text-orange-300">Disfunción cerebral mínima:</h2>
            <p> Coadyuvante en el tratamiento de la
                insuficiencia vascular cerebral. En la insuficiencia cerebrovascular
                desde sus primeras manifestaciones funcionales como: dificultad de
                concentración, déficit de la memoria. Alteraciones de la marcha o del
                sueño.
            </p>
            <h2 className="text-orange-300">Insuficiencia cerebral senil:</h2>
            <p>
                Manifestaciones neuropsíquicas de
                involución senil como: olvido benigno del anciano, alteración de la
                memoria reciente, alteración del estado emocional (ansiedad,
                agresividad, irritabilidad), falta de interés por el entorno, intranquili
                dad. Disminución de los rendimientos de tipo intelectual o psíquico.
                Déficit motor. </p>
        </div>
    );
}