export default function CollapseTwo() {
    return (
        <div className="flex flex-col my-2 gap-4">
            <h2 className="text-orange-300">Manía</h2>
            <p>Las tabletas de valproato semisódico de liberación prolongada están indicadas para el tratamiento de
                episodios maníacos agudos o mixtos asociados con el trastorno bipolar, con o sin características
                psicóticas. Un episodio maniaco es un periodo claro de elevación anormal y persistente del estado de
                ánimo, agitación o irritabilidad. Los síntomas típicos de manía incluyen expresión verbal excesiva,
                hiperactividad motora, menor necesidad de sueño, pensamientos apresurados, ideas de grandeza, deterioro
                de la capacidad de discernimiento, agresividad y posible hostilidad.
                <br/><br/>
                Un episodio mixto se caracteriza por los criterios de un episodio maniaco junto con aquellos de un
                episodio depresivo (estado de ánimo deprimido, apatía por casi todas las actividades). La eficacia de
                las tabletas de valproato semisódico de liberación prolongada en parte se basa en estudios de tabletas
                de valproato semisódico de liberación retardada en esta indicación y fue confirmada en un estudio de
                tres semanas con pacientes que reunían los criterios de DSM-IV para trastorno bipolar I, tipo maniaco o
                mixto, que fueron hospitalizados por manía aguda.
                <br/><br/>
                La eficacia de las tabletas de valproato de liberación prolongada para uso a largo plazo en manía, es
                decir, más de 3 semanas, no se ha demostrado en estudios clínicos controlados. Por lo tanto, los médicos
                que decidan usar tabletas de valproato semisódico de liberación prolongada durante periodos prolongados
                deben revalorar continuamente el riesgo y el beneficio a largo plazo del fármaco para un paciente
                individual.</p>
            <h2 className="text-orange-300">Epilepsia</h2>
            <div>Las tabletas de valproato semisódico de liberación prolongada están indicadas como monoterapia y
                tratamiento complementario en el tratamiento de adultos y niños de 10 años de edad o mayores con crisis
                convulsivas parciales complejas que se presentan solas o junto con otros tipos de crisis convulsiva. Las
                tabletas de valproato semisódico de liberación prolongada también están indicadas para uso como
                tratamiento único o complementario en el tratamiento de crisis convulsivas simples y complejas en
                adultos y niños de 10 años de edad o mayores, y complementariamente en adultos y niños de 10 años de
                edad o mayores con múltiples tipos de crisis convulsivas que incluyen crisis de ausencia.
                <div className="pl-4">
                    <li>Ausencia simple se define como una obnubilación o pérdida de la conciencia muy breve acompañada
                        por
                        ciertas descargas epilépticas generalizadas sin otros signos clínicos detectables.
                    </li>
                    <li> Ausencia compleja es el término utilizado cuando también hay presencia de otros síntomas.</li>
                </div>
            </div>
            <h2 className="text-orange-300">Migraña</h2>
            <p>Las tabletas de valproato semisódico de liberación prolongada están indicadas para la profilaxis de
                migrañas en adultos. No hay pruebas de que el valproato semisódico de liberación prolongada sea útil en
                el tratamiento agudo de migrañas. Debido a que el ácido valpróico puede ser peligroso para el feto, el
                valproato semisódico de liberación prolongada solo debe considerarse para mujeres en edad fértil después
                de haber hablado a fondo este riesgo con el paciente y de haberlo valorado contra los beneficios
                potenciales del tratamiento (véase Restricciones de uso durante el embarazo y la lactancia, Precauciones
                generales - Información para pacientes). Véase la información con respecto a disfunción hepática mortal
                en la sección Precauciones generales.</p>
            <h2 className="text-orange-300">Neuropatía y Dolor Neurítico</h2>
            <p>Está indicado en el manejo agudo y de mantenimiento del dolor neurítico, alodinia, alteraciones
                sensitivas dolorosas por neuropatía diabética, en la neuralgia del trigémino y en la neuropatía
                sensitiva.</p>
        </div>
    );
}