export default function CollapseTwo() {
  return (
    <div className="flex flex-col my-2 gap-4">
      <p>Anticonvulsivante. Oxetol® está indicado en el <strong>tratamiento de crisis convulsivas parciales</strong>,
        así como las
        convulsiones tónico- clónicas generalizadas en niños y adultos.</p>

      <p>Al igual está indicado en el <strong>tratamiento de primera elección</strong>, ya sea como monoterapéutico o
        como un medicamento
        concomitante.</p>

      <p>Oxetol® puede llegar a reemplazar otros fármacos antiepilépticos cuando el control de las convulsiones es
        insuficiente o llega a ser deficiente. </p>
      <img src={"./imgs/oxetol-box.png"} alt="oxetol-box.png" className="col-span-6"/>

    </div>
  );
}