import {MedicinesEnum} from "../../../Medicines.enum";
import {FaChevronLeft} from "react-icons/fa";
import {Collapse} from "react-daisyui";

type DosisProps = {
    setSelectedTab: (tab: MedicinesEnum) => void;
}

export default function Dosis({setSelectedTab}: DosisProps) {
    return (
        <div className="flex flex-col my-8">
            <div className="grid grid-cols-12  px-4">
                <div className="col-span-3">
                    <button
                        onClick={() => setSelectedTab(MedicinesEnum.MENU)}
                        className="flex items-center justify-center gap-2">
                        <FaChevronLeft className="w-8 h-8"/>
                        <p>Regresar</p>
                    </button>
                </div>
                <div className="col-span-9 ml-14 mt-6">
                    <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
                        <img src={"./imgs/icons/icon-4.png"} alt="icon.1" className=""/>
                    </div>
                </div>
            </div>
            <h2 className="font-bold text-xl text-center mt-2">DOSIFICACIÓN, SOBREDOSIS Y CONSIDERACIONES</h2>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    DOSIS Y VIA DE ADMINISTRACION
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <p>Las dosis de Donepecilo que resultaron efectivas en estudios clínicos controlados fueron de 5 y
                        10 mg administrados una vez al día. La dosis alta de 10 mg, no se acompañó de beneficio clínico
                        de significación estadística mayor que la de 5 mg. Existe una sugerencia en el orden de las
                        calificaciones y en los análisis de los esquemas de dosis de estos estudios clínicos, que la
                        dosis diaria de 10 mg de Donepecilo podría aportar beneficios adicionales en algunos pacientes.
                        En consecuencia, usar o no la dosis de 10 mg es cuestión de preferencias del paciente y el
                        médico que prescribe. La evidencia de estudios clínicos controlados indica que la dosis de 10
                        mg, con titulación a una semana, parece estar asociada a una incidencia más alta de eventos
                        adversos colinérgicos que la dosis de 5 mg. En estudios abiertos usando titulación a unas 6
                        semanas, la frecuencia de los mismos eventos adversos fue similar en los grupos de 5 y 10 mg.
                        Por lo tanto, dado que el estado estable no se logra por 15 días y por la incidencia de efectos
                        indeseados se ve influida por la tasa de escalamiento de la dosis, el tratamiento con una dosis
                        de 10 mg no debe contemplarse hasta que el paciente haya estado en una dosis diaria de 5 mg
                        durante 4 a 6 semanas. Donepecilo debe tomarse por la noche, justo antes de acostarse.
                        Donepecilo se puede tomar con o sin alimentos. El tratamiento debe empezar con 5 mg/día durante
                        un mes y aumentar de acuerdo con la respuesta clínica.
                    </p>
                </Collapse.Content>
            </Collapse>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    MANIFESTACIONES Y MANEJO DE LA SOBREDOSIFICACIÓN O INGESTA ACCIDENTAL
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <p>Al igual que en cualquier otra sobredosis, deben utilizarse medidas de sostén general. Las
                        sobredosis de inhibidores de colinesterasa pueden originar crisis colinérgicas, caracterizadas
                        por náuseas intensas, vómito, salivación, sudoración, bradicardia, hipotensión, depresión
                        respiratoria, colapso y convulsiones. Hay la posibilidad de que ocurra un aumento de la
                        debilidad muscular, y puede resultar si afecta los músculos respiratorios. Los anticolinérgicos
                        terciarios, como la atropina, pueden usarse como antídoto contra la sobredosis de Donepecilo. Se
                        recomienda el sulfato de atropina por vía intravenosa titulado, para lograr el efecto: dosis
                        inicial de 1 a 2 mg I.V. y dosis subsecuentes basadas en la respuesta clínica. Se ha informado
                        de respuestas atípicas de la presión sanguínea, frecuencia cardiaca, con otros colinomiméticos
                        cuando se coadministran con anticolinérgicos cuaternarios, como el glicopirrolato. No se sabe si
                        Donepecilo y/o sus metabolitos son dializables (hemodiálisis, diálisis peritoneal o
                        hemofiltración). Los signos de toxicidad relacionados con la dosis en animales incluyeron una
                        respuesta reducida al movimiento espontáneo, posición prona, normalidad de la marcha,
                        lagrimación, convulsiones clónicas, respiración deprimida, salivación, miosis, temblores,
                        fasciculación y temperatura superficial corporal disminuida, respiración deprimida, salivación,
                        miosis, temblores.
                    </p>

                </Collapse.Content>
            </Collapse>
        </div>
    );
}