import {MedicinesEnum} from "../../../Medicines.enum";

type OxetolMenuProps = {
  selectedTab: MedicinesEnum;
  setSelectedTab: (tab: MedicinesEnum) => void;
}

export default function OxetolMenu({selectedTab,setSelectedTab}: OxetolMenuProps) {
  return (
    <div className="container my-8 text-sm">
      <div className="flex flex-col">
        <button
          onClick={() => setSelectedTab(MedicinesEnum.DESCRIPTION)}
          className="grid grid-cols-12 border-b border-gray-800 px-4 py-4 gap-3 flex items-center justify-center">
          <div className="col-span-3 flex items-center justify-center">
            <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
              <img src={"./imgs/icons/icon-1.png"} alt="icon.1" className=""/>
            </div>
          </div>
          <div className="col-span-9 flex flex-col text-start">
            <h2 className="font-bold text-xl">DESCRIPCIÓN Y USO</h2>
            <p>Características principales de Oxetol, su composición, y los usos terapéuticos para el tratamiento del
              dolor neuropático y la epilepsia.</p>
          </div>
        </button>
        <button
          onClick={() => setSelectedTab(MedicinesEnum.PRECAUTIONS)}
          className="grid grid-cols-12 border-b border-gray-800 px-4 py-4 gap-3 flex items-center justify-center">
          <div className="col-span-3 flex items-center justify-center">
            <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
              <img src={"./imgs/icons/icon-2.png"} alt="icon.1" className="w-full my-auto"/>
            </div>
          </div>
          <div className="col-span-9 flex flex-col text-start">
            <h2 className="font-bold text-xl">PRECAUCIONES ESPECIALES</h2>
            <p>Consideraciones importantes para su uso, incluyendo interacciones, restricciones durante el embarazo y
              riesgos.</p>
          </div>
        </button>
        <button
          onClick={() => setSelectedTab(MedicinesEnum.EFFECTS)}
          className="grid grid-cols-12 border-b border-gray-800 px-4 py-4 gap-3 flex items-center justify-center">
          <div className="col-span-3 flex items-center justify-center">
            <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
              <img src={"./imgs/icons/icon-3.png"} alt="icon.1" className="w-full "/>
            </div>
          </div>
          <div className="col-span-9 flex flex-col text-start">
            <h2 className="font-bold text-xl">EFECTOS SECUNDARIOS</h2>
            <p>Reacciones adversas posibles, como el prurito y otras respuestas, que los pacientes pueden experimentar
              con el medicamento.</p>
          </div>
        </button>
        <button
          onClick={() => setSelectedTab(MedicinesEnum.DOSIS)}
          className="grid grid-cols-12 border-b border-gray-800 px-4 py-4 gap-3 flex items-center justify-center">
          <div className="col-span-3 flex items-center justify-center">
            <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
              <img src={"./imgs/icons/icon-4.png"} alt="icon.1" className="w-full"/>
            </div>
          </div>
          <div className="col-span-9 flex flex-col text-start">
            <h2 className="font-bold text-xl">DOSIFICACIÓN</h2>
            <p>Instrucciones detalladas sobre las dosis recomendadas, ajustadas para pacientes adultos, ancianos y
              personas con disfunciones renales o hepáticas</p>
          </div>
        </button>
        <button
          onClick={() => setSelectedTab(MedicinesEnum.PRESENTATION)}
          className="grid grid-cols-12  px-4 py-4 gap-3 flex items-center justify-center">
          <div className="col-span-3 flex items-center justify-center ">
            <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
              <img src={"./imgs/icons/icon-5.png"} alt="icon.1" className="w-full"/>
            </div>
          </div>
          <div className="col-span-9 flex flex-col text-start">
            <h2 className="font-bold text-xl">PRESENTACIONES</h2>
            <p>Diferentes formas en las que se puede adquirir Oxetol, como comprimidos, y las dosis disponibles para el
              tratamiento adecuado</p>
          </div>
        </button>
      </div>

    </div>
  );
}