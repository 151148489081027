export default function CollapseOne() {
    return (
        <div className="flex flex-col my-2 gap-4">
            <div>
                <strong>PRAMIPEXOL</strong>
                <br></br><br></br>
                <strong>FORMA FARMACEUTICA
                    <br></br>
                    Tabletas</strong>
                <br></br><br></br>

                <strong>FORMULA</strong>
                <p>Cada tableta contiene:</p>
                <p>Diclorhidrato de pramipexol</p>
                <p>monohidratado......................................... 0.25mg 0.5 mg 1.0 mg 1.5 mg</p>
                <p>Excipiente cbp… 1 Tableta.</p>
            </div>
        </div>
    )
        ;
}