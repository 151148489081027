export default function CollapseOne() {
    return (
        <div className="flex flex-col my-2 gap-4">
            <div>
                <strong>MIRAJI ER</strong><br/><br/>

                <strong>FORMA FARMACEUTICA</strong><br/>
                <strong>Cápsulas de liberación prolongada</strong><br/><br/>

                <strong>FORMULA</strong><br/>
                Tabletas de liberación prolongada Cada tableta de liberación prolongada contiene: Valproato semisódico
                equivalente a 500 mg de Ácido valpróico Excipiente cbp 1 tableta
            </div>
        </div>
    )
        ;
}