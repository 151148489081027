import {BrowserRouter} from 'react-router-dom';
import Router from './Router';
import Providers from "./Providers/Providers.component";

export default function App() {
    return (
        <BrowserRouter>
            <Providers>
                <Router/>
            </Providers>
        </BrowserRouter>
    );
}