import {MedicinesEnum} from "../../../Medicines.enum";
import {FaChevronLeft} from "react-icons/fa";
import {Collapse} from "react-daisyui";

type EffectsProps = {
  setSelectedTab: (tab: MedicinesEnum) => void;
}
export default function Effects({setSelectedTab}: EffectsProps) {
  return (
    <div className="flex flex-col my-8">
      <div className="grid grid-cols-12  px-4">
        <div className="col-span-3">
          <button
            onClick={() => setSelectedTab(MedicinesEnum.MENU)}
            className="flex items-center justify-center gap-2">
            <FaChevronLeft className="w-8 h-8"/>
            <p>Regresar</p>
          </button>
        </div>
        <div className="col-span-9 ml-14 mt-6">
          <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
            <img src={"./imgs/icons/icon-3.png"} alt="icon.1" className=""/>
          </div>
        </div>
      </div>
      <h2 className="font-bold text-xl text-center mt-2">EFECTOS SECUNDARIOS</h2>

      <Collapse
        icon="arrow"
        className="border-b border-gray-500"

      >
        <Collapse.Title className="text-xl font-medium text-orange-500">
          MANÍA
        </Collapse.Title>
        <Collapse.Content className="flex flex-col gap-4">
          <p>La incidencia de eventos derivados del tratamiento se determinó con base en datos combinados de dos
            estudios clínicos controlados con placebo de tres semanas de tabletas de valproato semisódico de liberación
            prolongada en el tratamiento de episodios maniacos asociados con trastorno bipolar. La tabla 1 resume esos
            eventos adversos reportados en pacientes de esos estudios donde la tasa de incidencia en el grupo tratado
            con tabletas de valproato semisódico de liberación prolongada fue mayor de 5% y mayor que la incidencia con
            placebo.
            <br/><br/>
            Tabla 1. Reporte de eventos adversos mayor a 5% en estudios de valproato semisódico ER vs. placebo en
            pacientes
            con manía aguda
          </p>
          <img src={"./imgs/mirj-mania.png"} alt=" md:px-[15rem]" className=" md:px-[15rem]"/>
          <p>Los siguientes eventos adversos fueron reportados mayor a 1% pero no mayor a 5% del valproato semisódico en
            liberación
            prolongada:
            <br/>Generales: Dolor dorsal, síndrome gripal, infecciones en general, infección micótica.
            <br/>Cardiovascular: Hipertensión.
            <br/>Digestivo: Constipación, boca seca y flatulencia.
            <br/>Hematológico: Equimosis.
            <br/>Metabolismo: Edema periférico.
            <br/>Musculoesquelético: Eialgia.
            <br/>Nervioso: Marcha anormal, hipertonía, temblor.
            <br/>Respiratorio: Rinitis.
            <br/>Piel: Prurito, rash.
            <br/>Mucosas: Conjuntivitis.
            <br/>Urogenital: Infección del tracto urinario y vaginitis.
            <br/>Epilepsia:<br/><br/>
            Crisis convulsivas parciales complejas (CPC): Con base en un estudio controlado con placebo de tratamiento
            complementario para crisis parciales complejas, el valproato semisódico generalmente fue bien tolerado con
            la mayoría de los eventos adversos clasificados como de severidad leve a moderada. La intolerancia fue el
            motivo principal de suspensión en los pacientes tratados con valproato semisódico (6%), en comparación con
            1% de los pacientes tratados con placebo. La tabla 2 enumera los eventos adversos derivados del tratamiento
            reportados por ³ 5% de los pacientes tratados con valproato semisódico y para los cuales la incidencia fue
            mayor que en el grupo placebo, en el estudio controlado con placebo de tratamiento complementario de crisis
            convulsivas parciales complejas. Debido a que los pacientes también fueron tratados con otros fármacos
            antiepilépticos, en la mayoría de los casos no es posible determinar si los siguientes eventos adversos
            pueden atribuirse al valproato semisódico solo o a la combinación de valproato semisódico y otros fármacos
            antiepilépticos.
            <br/><br/>
            Tabla 2. Eventos adversos reportados por ³ 5% de los pacientes tratados con valproato semisódico durante el
            estudio controlado con placebo de tratamiento complementario para crisisconvulsivas parciales complejas
          </p>
          <img src={"./imgs/mirj-2.png"} alt=" md:px-[15rem]" className=" md:px-[15rem]"/>
          <p>La tabla 3 enumera los eventos adversos derivados del tratamiento reportados por ³ 5% de los pacientes en
            el grupo de dosis alta de valproato semisódico y para los cuales la incidencia fue mayor que en el grupo de
            dosis baja, en un estudio controlado de monoterapia con valproato semisódico para crisis parciales
            complejas. Debido a que durante la primera parte del estudio se estaba ajustando otro fármaco antiepiléptico
            para suspenderlo, en muchos casos no es posible determinar si los siguientes eventos adversos pueden
            atribuirse a valproato semisódico solo, o a la combinación de valproato semisódico y otros fármacos
            antiepilépticos.
            <br/><br/>
            Tabla 3. Eventos adversos reportados por ³ 5% de los pacientes en el grupo de dosis alta en el estudio de
            monoterapia con valproato semisódico para crisis parciales complejas1
          </p>
          <img src={"./imgs/mjr-3.png"} alt=" md:px-[15rem]" className=" md:px-[15rem]"/>
          <p>Los siguientes eventos adversos fueron reportados por más de 1% pero menos de 5% de los 358 pacientes
            tratados con valproato semisódico en los estudios controlados de crisis convulsivas parciales complejas:
            Organismo en general: Dorsalgia, dolor precordial, malestar.<br/>
            Aparato cardiovascular: Taquicardia, hipertensión, palpitación.<br/>
            Aparato digestivo: Aumento del apetito, flatulencia, hematemesis, eructo, pancreatitis, absceso periodontal.
            Sangre y sistema linfático: Petequias.<br/>
            Trastornos metabólicos y alimenticios: ALAT elevada, ASAT elevada.<br/>
            Sistema musculoesquelético: Mialgia, espasmos, artralgia, calambres en piernas, miastenia.<br/>
            Sistema nervioso: Ansiedad, confusión, trastorno del habla, marcha anormal, parestesia, hipertonía,<br/>
            descoordinación, sueños anormales, trastorno de la personalidad.<br/>
            Aparato respiratorio: Sinusitis, aumento de tos, neumonía, epistaxis.<br/>
            Piel y tejidos blandos: Erupción cutánea, prurito, piel seca.<br/>
            Sentidos especiales: Disgeusia, visión anormal, trastorno del oído, sordera, otitis media.<br/>
            Aparato urogenital: Incontinencia urinaria, vaginitis, dismenorrea, amenorrea, frecuencia urinaria.<br/>
            Profilaxis de la migraña:
            <br/><br/>
            Con base en los resultados de un estudio clínico multicéntrico, aleatorizado, doble ciego, controlado con
            placebo, valproato semisódico de liberación prolongada fue bien tolerado en el tratamiento profiláctico de
            la migraña. De los 122 pacientes expuestos a valproato semisódico de liberación prolongada en el estudio
            controlado con placebo, 8% suspendió el tratamiento debido a eventos adversos, en comparación con 9% de los
            115 pacientes tratados con placebo. La tabla 4 incluye esos eventos adversos reportados en pacientes del
            estudio clínico controlado con placebo en el que la tasa de incidencia en el grupo tratado con valproato
            semisódico de liberación prolongada fue mayor de 5% y mayor que la de los pacientes tratados con placebo.
            <br/><br/>
            Tabla 4. Eventos adversos reportados por mayor a 5% de los pacientes tratados con valproato semisódico de
            liberación prolongada durante el estudio de migraña controlado con placebo con una incidencia mayor que los
            pacientes que tomaban placebo1
          </p>
          <img src={"./imgs/mjr-4.png"} className=" md:px-[15rem]"/>
          <p>Los siguientes eventos adversos adicionales fueron reportados por más de 1%, pero no más de 5% de los
            pacientes tratados con valproato semisódico de liberación prolongada y con una mayor incidencia que placebo
            en el estudio controlado con placebo para profilaxis de migraña:
            <br/><br/>
            Organismo en general: lesión accidental, infección viral.
            Aparato digestivo: aumento del apetito, trastorno dental.
            Trastornos metabólicos y alimenticios: edema, aumento de peso.
            Sistema nervioso: marcha anormal, mareo, hipertonía, insomnio, nerviosismo, temblor, vértigo.
            Aparato respiratorio: faringitis, rinitis.
            Piel y tejidos blandos: erupción cutánea.
            Sentidos especiales: acúfenos.
            <br/><br/>
            Otras poblaciones de pacientes: los siguientes eventos adversos, no enumerados anteriormente, fueron
            reportados por más de 1% de los pacientes tratados con valproato semisódico con capa entérica y con una
            mayor incidencia que placebo en estudios controlados con placebo de epilepsia o episodios maníacos asociados
            con el trastorno bipolar:
            <br/><br/>
            Organismo en general: escalofríos y fiebre, aumento del nivel del fármaco, cefalea, malestar, dolor de
            cuello, rigidez cervical.
            Aparato cardiovascular: arritmia, hipertensión, hipotensión, palpitación, hipotensión postural.
            Aparato digestivo: anorexia, disfagia, eructo, incontinencia fecal, gastroenteritis, glositis, hemorragia
            gingival, hematemesis, ulceraciones bucales, absceso periodontal.
            Sangre y sistema linfático: anemia, mayor tiempo de sangrado, leucopenia, petequia.
            Trastornos metabólicos y alimenticios: hipoproteinemia, aumento de ASAT, aumento de ALAT, pérdida de peso.
            Sistema musculoesquelético: artralgia, artrosis, espasmos.
            Sistema nervioso: agitación, amnesia, ataxia, reacción catatónica, depresión, diplopía, disartria,
            inestabilidad emocional, alucinaciones, hipoquinesia, falta de coordinación, nistagmo, psicosis, aumento de
            reflejos, trastorno del sueño, discinesia tardía.
            Aparato respiratorio: bronquitis, hipo, neumonía.
            Piel y tejidos blandos: lupus eritematoso discoide, piel seca, eritema nodoso, furunculosis, erupción
            maculopapulosa, seborrea, sudoración, erupción cutánea.
            Sentidos especiales: ambliopía, conjuntivitis, sordera, sequedad de ojos, trastorno ocular, dolor ocular,
            fotofobia, disgeusia.
            Aparato urogenital: cistitis, dismenorrea, disuria, trastorno menstrual, incontinencia urinaria, vaginitis.
            <br/><br/>
            A continuación, se enumeran por sistema y aparato del organismo los eventos adversos que se han reportado
            con todas las formas farmacéuticas de valproato en estudios de epilepsia, reportes espontáneos y otras
            fuentes. Ya que el valproato semisódico normalmente se usa con otros fármacos antiepilépticos en el
            tratamiento de epilepsia, en la mayoría de los casos no es posible determinar si las siguientes reacciones
            adversas pueden atribuirse al valproato semisódico solo o a la combinación de fármacos.
            <br/><br/>
            Gastrointestinales: los efectos reportados más comúnmente al inicio del tratamiento son náuseas, vómito e
            indigestión. Estos efectos generalmente son transitorios y rara vez es necesario suspender el tratamiento.
            Se han reportado diarrea, cólicos y estreñimiento. También se ha reportado anorexia con cierta pérdida de
            peso y aumento de apetito con aumento de peso. En algunos pacientes, muchos de ellos con trastornos
            gastrointestinales funcionales o anatómicos (incluyendo ileostomía o colostomía) con disminución del tiempo
            de tránsito GI, se ha reportado la presencia de tabletas de valproato semisódico de liberación prolongada en
            heces.
            <br/><br/>
            Efectos en el SNC: se han observado efectos sedantes en pacientes que reciben valproato solo, pero se
            presentan con más frecuencia en pacientes que reciben tratamiento combinado. La sedación por lo regular
            disminuye al reducir el otro medicamento antiepiléptico. Se reportaron temblor (puede relacionarse con la
            dosis), alucinaciones, ataxia, cefalea, nistagmo, diplopía, asterixis, “manchas delante de los ojos”,
            disartria, mareo, confusión, hipestesia, vértigo, falta de coordinación y parkinsonismo con el uso de
            valproato. Se han presentado casos contados de coma en pacientes que reciben valproato solo o en combinación
            con fenobarbital. En casos contados, se ha desarrollado encefalopatía con o sin fiebre poco después de la
            introducción de monoterapia con valproato sin evidencia de disfunción hepática o niveles plasmáticos de
            valproato inadecuadamente altos. Aunque se ha definido una recuperación después de retirar el fármaco, han
            habido muertes entre los pacientes con encefalopatía hiperamonémica, en particular en pacientes con
            trastornos subyacentes del ciclo de la urea (véase "Trastornos del ciclo de la urea y Precauciones
            generales"). Diversos reportes han mencionado demencia reversible y pseudoatrofia reversible en asociación
            con el tratamiento con valproato.
            <br/><br/>
            Dermatológicos: pérdida de cabello transitoria, erupción cutánea, fotosensibilidad, prurito generalizado,
            eritema multiforme y síndrome de Stevens-Johnson. Se han reportado casos de necrólisis epidérmica tóxica,
            incluyendo un caso mortal en un lactante de seis meses de edad que tomaba valproato y otros medicamentos
            concomitantes. Otro caso de necrosis epidérmica tóxica resultó en la muerte de un paciente de 35 años con
            SIDA que tomaba diversos medicamentos concomitantes y tenía antecedentes de múltiples reacciones cutáneas a
            fármacos. Se han reportado reacciones cutáneas serias con la administración concomitante de lamotrigina y
            valproato.
            <br/><br/>
            Psiquiátricos: alteración emocional, depresión, psicosis, agresión, hiperactividad, hostilidad y deterioro
            de la conducta.
            <br/><br/>
            Musculoesqueléticos: debilidad. Se han recibido reportes de disminución de masa ósea, derivando posiblemente
            en osteoporosis y osteopenia durante el tratamiento a largo plazo con medicamentos anticonvulsivos,
            incluyendo valproato. Algunos estudios indican que el calcio y vitamina D complementarios pueden ser
            beneficiosos para los pacientes en tratamiento crónico con valproato.
            <br/><br/>
            Hematológicos: trombocitopenia e inhibición de la fase secundaria de la agregación plaquetaria, que pueden
            reflejarse en alteración del tiempo de sangrado, petequias, contusiones, formación de hematomas, epistaxis y
            hemorragia franca (véase "Precauciones generales - Interacciones medicamentosas y de otro género").
            Linfocitosis relativa, macrocitosis, hipofibrinogenemia, leucopenia, eosinofilia, anemia (incluyendo
            macrocítica con o sin deficiencia de folato), inhibición de médula ósea, pancitopenia, anemia aplásica,
            agranulocitosis y porfiria aguda intermitente.
            <br/><br/>
            Hepáticos: las elevaciones menores de las transaminasas (por ejemplo, ASAT y ALAT) y LDH son poco frecuentes
            y al parecer se relacionan con la dosis. Ocasionalmente, los resultados de pruebas de laboratorio incluyen
            aumentos de bilirrubina y cambios anormales en otras pruebas de función hepática. Estos resultados pueden
            reflejar una hepatotoxicidad potencialmente seria (véase "Precauciones generales").
            <br/><br/>
            Endocrinos: menstruaciones irregulares, amenorrea secundaria, aumento de mamas, galactorrea e hinchazón de
            las glándulas parótidas. Pruebas de función tiroidea anormales (véase "Precauciones generales"). Existen
            reportes espontáneos poco frecuentes de enfermedad ovárica poliquística; no se ha establecido una relación
            de causa y efecto.
            <br/><br/>
            Pancreáticos: pancreatitis aguda, incluyendo muertes (véase "Precauciones generales").
            <br/><br/>
            Metabólicos: hiperamonemia (véase "Precauciones generales"), hiponatremia y secreción inadecuada de la
            hormona antidiurética (ADH). Existen reportes poco comunes de síndrome de Fanconi, principalmente en niños.
            Se han reportado disminuciones en las concentraciones de carnitina, aunque la relevancia clínica no se
            conoce. Se ha presentado hiperglucemia, la cual se relacionó con la muerte de un paciente con hiperglucemia
            no cetósica preexistente.
            <br/><br/>
            Genitourinarios: enuresis e infección de vías urinarias.
            <br/><br/>
            Sentidos especiales: se ha reportado pérdida del oído, ya sea reversible o irreversible; sin embargo, no se
            ha establecido una relación de causa y efecto.</p>

        </Collapse.Content>
      </Collapse>
      <Collapse
        icon="arrow"
        className="border-b border-gray-500"

      >
        <Collapse.Title className="text-xl font-medium text-orange-500">
          INTERACCIONES MEDICAMENTOSAS Y DE OTRO GÉNERO
        </Collapse.Title>
        <Collapse.Content className="flex flex-col gap-4">
          <p>Efectos en la depuración del valproato debidos a fármacos co-administrados: Los fármacos que afectan el
            nivel de expresión de enzimas hepáticas, en particular aquellos que elevan los niveles de
            glucuroniltransferasas, pueden aumentar la depuración de valproato. Por ejemplo, la fenitoína, la
            carbamazepina y el fenobarbital (o primidona) pueden aumentar al doble la depuración de valproato. Por
            consiguiente, los pacientes en monoterapia generalmente tendrán vidas medias más prolongadas y
            concentraciones más altas que los pacientes que reciben politerapia con fármacos antiepilépticos. Por el
            contrario, los fármacos que son inhibidores de las isoenzimas del citocromo P-450, por ejemplo, los
            antidepresivos, pueden tener poco efecto en la depuración de valproato porque la oxidación microsomal
            mediada por el citocromo P-450 es una vía metabólica secundaria relativamente menor en comparación con la
            glucuronidación y beta oxidación. Debido a estos cambios en la depuración de valproato, el monitoreo de las
            concentraciones de valproato y los fármacos concomitantes se debe aumentar siempre que se introduzcan o
            retiren fármacos inductores de enzimas. La siguiente lista proporciona información sobre el potencial de una
            influencia de diversos medicamentos prescritos comúnmente en la farmacocinética de valproato. La lista no es
            exhaustiva ni lo podría ser, ya que continuamente se reportan nuevas interacciones.
            Ácido acetilsalicílico: Un estudio que incluyó la administración concomitante de ácido acetilsalicílico en
            dosis antipiréticas (11 a 16 mg/kg) con valproato a pacientes pediátricos (n = 6) reveló una disminución en
            la unión a proteínas y una inhibición del metabolismo del valproato. La fracción libre de valproato aumentó
            cuatro veces en presencia del ácido acetilsalicílico en comparación con valproato solo. La vía de
            ß-oxidación que consiste en 2-E-ácido valpróico, 3-OH-ácido valpróico y 3-ceto ácido valpróico disminuyó de
            25% de metabolitos totales excretados en valproato solo a 8.3% en presencia de ácido acetilsalicílico. El
            valproato semisódico de liberación prolongada no está indicado para uso pediátrico. Se desconoce si la
            interacción observada en este estudio aplica para adultos, pero se debe tener precaución si se debe
            administrar valproato y ácido acetilsalicílico concomitantemente.
            Antibióticos carbapenémicos: Se reportó una reducción clínicamente significativa en la concentración sérica
            de ácido valpróico en pacientes que recibieron antibióticos carbapenémicos (ertapenem, imipenem, meropenem)
            y puede producir pérdida del control de crisis convulsivas. No se comprende bien el mecanismo de esta
            interacción. Las concentraciones séricas de ácido valpróico deben monitorearse frecuentemente después de
            iniciar el tratamiento con carbapenem. Si las concentraciones séricas de ácido valpróico disminuyen
            significativamente o si el control de crisis convulsivas se deteriora, debe considerarse un tratamiento
            antibacteriano o anticonvulsivo alternativo (véase Precauciones generales).
            Felbamato: Un estudio que incluyó la administración concomitante de 1,200 mg/día de felbamato con valproato
            a pacientes con epilepsia (n = 10) reveló un aumento en las concentraciones máximas plasmáticas promedio en
            35% (de 86 a 115 µg/ml) en comparación con valproato solo. El incremento de la dosis de felbamato a 2,400
            mg/día aumentó las concentraciones máximas plasmáticas promedio a 133 µg/ml (otro aumento de 16%). Al
            iniciar el tratamiento con felbamato puede ser necesario disminuir la dosis de valproato.
            Rifampicina: Un estudio que incluyó la administración de una dosis única de valproato (7 mg/kg) 36 horas
            después de cinco noches de dosificación diaria con rifampicina (600 mg) reveló un aumento de 40% en la
            depuración oral de valproato. Puede ser necesario ajustar la dosis de valproato cuando se administra
            concomitantemente con rifampicina

          </p>
          <p>Fármacos en los que no se han observado interacciones ni probables interacciones clínicamente
            significativas:<br/>
            Antiácidos: Un estudio que incluyó la administración concomitante de valproato 500 mg con antiácidos
            comúnmente administrados (a base de sales de aluminio entre otras) no reveló ningún efecto en el grado de
            absorción de valproato. Clorpromazina: Un estudio que incluyó la administración de 100 a 300 mg/día de
            clorpromazina a pacientes esquizofrénicos que ya recibían valproato (200 mg b.i.d.) reveló un aumento de 15%
            en los niveles mínimos plasmáticos de valproato.
            Haloperidol: Un estudio que incluyó la administración de 6 a 10 mg/día de haloperidol a pacientes
            esquizofrénicos que ya recibían valproato (200 mg b.i.d.) no reveló cambios significativos en los niveles
            mínimos plasmáticos de valproato.
            Cimetidina y ranitidina: Cimetidina y ranitidina no afectan la depuración del valproato.
            Efectos del valproato en otros fármacos: Se encontró que el valproato es un inhibidor débil de algunas
            isoenzimas P-450, epóxido hidrasa y glucuroniltransferasas. La siguiente lista proporciona información sobre
            el potencial de una influencia de la coadministración de valproato en la farmacocinética y farmacodinamia de
            diversos medicamentos comúnmente prescritos. La lista no es exhaustiva, ya que continuamente se reportan
            nuevas interacciones.
            <br/><br/>
            Fármacos en los que se han observado interacciones potencialmente importantes con valproato:
            Amitriptilina/nortriptilina: La administración de una dosis oral única de 50 mg de amitriptilina a 15
            voluntarios normales (diez varones y cinco mujeres) que recibieron valproato (500 mg b.i.d.) produjo una
            disminución de 21% en la depuración plasmática de amitriptilina y una disminución de 34% en la depuración
            neta de nortriptilina. Se han recibido contados reportes posteriores a la comercialización de que el uso
            concurrente de valproato y amitriptilina aumenta el nivel de amitriptilina. El uso concurrente de valproato
            y amitriptilina rara vez se ha asociado con toxicidad. Se debe considerar el monitoreo de los niveles de
            amitriptilina de pacientes que toman valproato concomitantemente con amitriptilina. Se debe considerar
            reducir la dosis de amitriptilina/nortriptilina en presencia de valproato.
            Carbamazepina/carbamazepina-10, 11-Epóxido: Los niveles séricos de carbamazepina (CBZ) disminuyeron 17%
            mientras que el de la carbamazepina-10, 11-epóxido (CBZ-E) aumentó en 45% al coadministrar valproato y CBZ a
            pacientes epilépticos.<br/>
            Clonazepam: El uso concomitante de ácido valpróico y clonazepam puede inducir estado de ausencia en
            pacientes con antecedentes de crisis de ausencia.<br/>
            Diazepam: El valproato desplaza al diazepam de sus sitios de unión con la albúmina plasmática e inhibe su
            metabolismo. La administración concomitante de valproato (1,500 mg al día) aumentó la fracción libre de
            diazepam (10 mg) en 90% en voluntarios sanos (n = 6). La depuración plasmática y volumen de distribución de
            diazepam libre disminuyeron en 25% y 20%, respectivamente, en presencia de valproato. La vida media de
            eliminación de diazepam permaneció sin cambios al agregar valproato.<br/>
            Etosuximida: El valproato inhibe el metabolismo de etosuximida. La administración de una dosis única de
            etosuximida de 500 mg con valproato (800 a 1,600 mg/día) a voluntarios sanos (n = 6) se acompañó por un
            aumento de 25% en la vida media de eliminación de etosuximida y una disminución de 15% en su depuración
            total en comparación con etosuximida sola. Se debe monitorear a los pacientes que reciben valproato y
            etosuximida, especialmente junto con otros anticonvulsivos, para detectar alteraciones en las
            concentraciones séricas de ambos fármacos.<br/>
            Lamotrigina: En un estudio de estado estable que incluyó diez voluntarios sanos, la vida media de
            eliminación de lamotrigina aumentó de 26 a 70 horas con la coadministración de valproato (aumento de 165%).
            La dosis de lamotrigina debe disminuirse cuando se administra con valproato. Se han reportado reacciones
            serias de la piel (como síndrome de Stevens-Johnson y necrólisis epidérmica tóxica) con la administración
            concomitante de lamotrigina y valproato. Véase el instructivo del envase de lamotrigina para consultar los
            detalles de la dosis de lamotrigina con la administración concomitante de valproato.<br/>
            Fenobarbital: Se encontró que el valproato inhibe el metabolismo de fenobarbital. La administración
            concomitante de valproato (250 mg b.i.d. durante 14 días) y fenobarbital a personas sanas (n = 6) produjo un
            aumento de 50% en la vida media y una disminución de 30% en la depuración plasmática de fenobarbital (dosis
            única de 60 mg). La fracción de fenobarbital excretada intacta en orina aumentó en 50% en la presencia de
            valproato. Hay evidencia de depresión del SNC severa, con o sin elevaciones significativas de las
            concentraciones séricas del barbitúrico o del valproato. Se debe hacer un monitoreo estrecho de todos los
            pacientes que reciben tratamiento concomitante con un barbitúrico para detectar toxicidad neurológica. Se
            deben obtener las concentraciones séricas del barbitúrico, de ser posible, y disminuir la dosis del
            barbitúrico, si es adecuado.<br/>
            Primidona: La primidona se metaboliza en un barbitúrico y por lo tanto también puede participar en una
            interacción con valproato similar a la de fenobarbital.<br/>
            Fenitoína: El valproato desplaza a la fenitoína de sus sitios de unión con la albúmina plasmática e inhibe
            su metabolismo hepático. La coadministración de valproato (400 mg t.i.d.) con fenitoína (250 mg) en
            voluntarios normales (n = 7) se asoció con un aumento de 60% en la fracción libre de la fenitoína. La
            depuración plasmática total y el volumen aparente de distribución de fenitoína aumentaron 30% en presencia
            de valproato. En pacientes con epilepsia, existen reportes de crisis convulsivas recurrentes que se
            presentaron con la combinación de valproato y fenitoína. La dosis de fenitoína se debe ajustar según lo
            requiera la situación clínica.<br/>
            Tolbutamida: De experimentos in vitro, la fracción libre de tolbutamida aumentó de 20% a 50% cuando se
            agregó a muestras plasmáticas tomadas de pacientes tratados con valproato. No se conoce la relevancia
            clínica de este desplazamiento.<br/>
            Topiramato: La administración concomitante de ácido valproico y topiramato se ha asociado con hiperamoniemia
            con o sin encefalopatía (véase Contraindicaciones y Precauciones generales
            <br/> - Trastornos del ciclo de la urea y Precauciones generales - Hiperamonemia e hiperamonemia y
            encefalopatía
            asociadas con el uso concomitante de topiramato). La administración concomitante de topiramato con ácido
            valproico también se ha asociado con hipotermia en pacientes que han tolerado cualquiera de los dos fármacos
            solos. Se deben determinar los niveles sanguíneos de amoniaco en pacientes con reporte de inicio de
            hipotermia (véase Precauciones generales - Hipotermia y Precauciones generales - Hiperamonemia).
            Warfarina: En un estudio in vitro, la presencia de valproato aumentó la fracción libre de warfarina hasta en
            32.6%. La relevancia terapéutica de esto se desconoce; sin embargo las pruebas de coagulación se deben
            monitorear si el tratamiento con valproato semisódico se inicia en pacientes que toman anticoagulantes.
            Zidovudina: En seis pacientes seropositivos al VIH, la depuración de zidovudina (100 mg cada ocho horas)
            disminuyó en 38% después de la administración de valproato (250 o 500 mg cada ocho horas); la vida media de
            la zidovudina no fue afectada. Fármacos en los que no se han observado interacciones o probables
            interacciones clínicamente insignificantes:
            Paracetamol: El valproato no tuvo efectos en ninguno de los parámetros farmacocinéticos de paracetamol
            cuando se administró concomitantemente a tres pacientes epilépticos.
            Clozapina: En pacientes psicóticos (n = 11) no se observaron interacciones al administrar valproato
            concomitantemente con clozapina.
            Litio: La administración concomitante de valproato (500 mg b.i.d.) y carbonato de litio (300 mg t.i.d.) a
            voluntarios varones normales (n = 16) no tuvo efectos en la cinética en estado estable del litio.
            Lorazepam: La administración concomitante de valproato (500 mg b.i.d.) y lorazepam (1 mg b.i.d.) en
            voluntarios varones normales (n = 9) se acompañó de una disminución de 17% en la depuración plasmática de
            lorazepam.
            Esteroides anticonceptivos orales: La administración de una dosis única de etinilestradiol (50
            µg)/levonorgestrel (250 µg) a seis mujeres en tratamiento con valproato (200 mg b.i.d.) durante dos meses no
            reveló ninguna interacción farmacocinética.
          </p>
        </Collapse.Content>
      </Collapse>
      <Collapse
        icon="arrow"
        className="border-b border-gray-500"

      >
        <Collapse.Title className="text-xl font-medium text-orange-500">
          PRECAUCIONES EN RELACIÓN CON EFECTOS DE CARCINOGÉNESIS, MUTAGÉNESIS, TERATOGÉNESIS, Y SOBRE LA FERTILIDAD
        </Collapse.Title>
        <Collapse.Content className="flex flex-col gap-4">
          <p>Carcinogénesis: Se administró ácido valpróico por vía oral a ratas Sprague Dawley y ratones ICR (HA/ICR) en
            dosis de 80 y 170 mg/kg/día (aproximadamente 10 a 50% de la dosis diaria máxima en humanos en mg/m2) durante
            dos años. En ambas especies se observó una variedad de neoplasias. Los principales hallazgos fueron un
            aumento estadísticamente significativo en la incidencia de fibrosarcomas subcutáneos en ratones que recibían
            dosis altas de ácido valpróico y una tendencia relacionada con la dosis estadísticamente significativa a
            adenomas pulmonares benignos en ratones que recibían ácido valpróico. No se conoce la importancia clínica de
            estos hallazgos para humanos.<br/>
            Mutagénesis: El valproato no fue mutágeno en un análisis bacteriano in vitro (prueba de Ames), no produjo
            efectos letales dominantes en ratones y no aumentó la frecuencia de aberraciones cromosómicas en un estudio
            citogenético in vivo en ratas. En un estudio de niños epilépticos que tomaban valproato, se reportó aumento
            en la frecuencia de intercambio entre cromátidas hermanas (SCE), pero esta asociación no se observó en otro
            estudio realizado en adultos. Hay cierta evidencia de que el aumento en las frecuencias de SCE se relaciona
            con la epilepsia. Se desconoce la significancia biológica de un aumento en la frecuencia de SCE.
            Alteraciones de la fertilidad: Estudios de toxicidad crónica en ratas y perros jóvenes y adultos demostraron
            una reducción en la espermatogenia y atrofia testicular a dosis orales de 400 mg/kg/día o más altas en ratas
            (aproximadamente equivalente o superior a la dosis diaria máxima humana en mg/m2) y 150 mg/kg/día o superior
            en perros (aproximadamente 1.4 veces la dosis diaria máxima en humanos o superior en mg/m2). Los estudios de
            fertilidad del segmento I realizados en ratas demostraron que las dosis orales de hasta 350 mg/kg/día
            (aproximadamente igual a la dosis diaria máxima en humanos en mg/m2) durante 60 días no tienen efectos en la
            fertilidad. Se desconoce el efecto de valproato en el desarrollo testicular y en la producción de esperma y
            la fertilidad en humanos.

          </p>
        </Collapse.Content>
      </Collapse>
      <Collapse
        icon="arrow"
        className="border-b border-gray-500"

      >
        <Collapse.Title className="text-xl font-medium text-orange-500">
          DOSIS Y VÍA DE ADMINISTRACIÓN </Collapse.Title>
        <Collapse.Content className="flex flex-col gap-4">
          <p>General: Las tabletas de valproato semisódico de liberación prolongada son para administración oral una vez
            al día. Las tabletas de valproato semisódico de liberación prolongada deben tomarse enteras y no deben
            triturarse ni masticarse. Una tableta de valproato semisódico de liberación prolongada es un producto
            formulado y desarrollado para administración una vez al día.<br/>
            Manía: Las tabletas de valproato semisódico de liberación prolongada se administran por vía oral. La dosis
            inicial recomendada es 25 mg/kg/día una vez al día. La dosis se debe aumentar tan rápido como sea posible
            para lograr la dosis terapéutica más baja que produzca el efecto clínico deseado o el rango deseado de
            concentraciones plasmáticas. En un estudio clínico controlado con placebo de manía aguda o tipo mixto, los
            pacientes recibieron dosis hasta obtener una respuesta clínica con una concentración plasmática mínima entre
            85 y 125 µg/ml. La dosis máxima recomendada es 60 mg/kg/día. No hay pruebas disponibles de estudios
            controlados que guíen al médico en el tratamiento más prolongado de un paciente que mejora durante el
            tratamiento de un episodio maniaco agudo con tabletas de valproato semisódico de liberación prolongada.
            Aunque generalmente se acepta que es deseable un tratamiento farmacológico después de una respuesta aguda en
            la manía, tanto para mantenimiento de la respuesta inicial como para la prevención de nuevos episodios
            maniacos, no hay datos que apoyen los beneficios de las tabletas de valproato semisódico de liberación
            prolongada en tal tratamiento más prolongado (es decir, más de 3 semanas).<br/>
            Epilepsia: El valproato semisódico está indicado como monoterapia y tratamiento complementario en crisis
            convulsivas parciales complejas y crisis convulsivas de ausencia simple y compleja en pacientes adultos y
            pediátricos de 10 años de edad o mayores. Ya que la dosis de valproato semisódico se ajusta ascendentemente,
            las concentraciones de fenobarbital, carbamazepina y/o fenitoína pueden ser afectadas (véase Interacciones
            medicamentosas y de otro género).<br/>
            Crisis parciales complejas (CPC) para adultos y niños de 10 años de edad o mayores: Monoterapia (tratamiento
            inicial): El valproato semisódico no se ha estudiado sistemáticamente como tratamiento inicial. Los
            pacientes deben iniciar el tratamiento con 10 a 15 mg/kg/día. La dosis debe ajustarse en aumentos de 5 a 10
            mg/kg/semana para lograr la respuesta clínica óptima. Normalmente, la respuesta clínica óptima se logra a
            dosis diarias de menos de 60 mg/kg/día. Si no se ha logrado una respuesta clínica satisfactoria, se deben
            determinar los niveles plasmáticos para definir si se encuentran en el rango terapéutico generalmente
            aceptado (50 a 100 µg/ml). No se pueden hacer recomendaciones con respecto a la seguridad de valproato a
            dosis de más de 60 mg/kg/día. La probabilidad de trombocitopenia aumenta significativamente cuando las
            concentraciones valle plasmáticas de valproato están por arriba de 110 µg/ml en mujeres y 135 µg/ml en
            varones. Se debe valorar el beneficio de un mejor control de crisis con dosis más altas contra la
            posibilidad de una mayor incidencia de reacciones adversas.<br/>
            Conversión a monoterapia: Los pacientes deben iniciar el tratamiento con 10 a 15 mg/kg/día. La dosis se debe
            ajustar en aumentos de 5 a 10 mg/kg/semana para lograr la respuesta clínica óptima. Normalmente, la
            respuesta clínica óptima se logra a dosis diarias de menos de 60 mg/kg/día. Si no se ha logrado una
            respuesta clínica satisfactoria, se deben determinar los niveles plasmáticos para definir si se encuentran
            en el rango terapéutico generalmente aceptado (50 a 100 /ml). No se pueden hacer recomendaciones con
            respecto a la seguridad del valproato a dosis de más de 60 mg/kg/día. La dosis de un fármaco antiepiléptico
            (FAE) concomitante normalmente puede reducirse aproximadamente 25% cada dos semanas. Esta reducción puede
            iniciarse al comenzar el tratamiento con valproato semisódico, o retrasarse una a dos semanas si hay una
            preocupación de que se presenten crisis con una reducción. La velocidad y duración del retiro del FAE
            concomitante puede ser muy variable y se debe hacer un monitoreo estrecho de los pacientes durante este
            periodo para detectar aumentos en la frecuencia de las crisis.<br/>
            Tratamiento complementario: Valproato semisódico se puede agregar al esquema del paciente a una dosis de 10
            a 15 mg/kg/día. La dosis debe aumentarse de 5 a 10 mg/kg/semana para lograr la respuesta clínica óptima.
            Normalmente, la respuesta clínica óptima se logra a dosis diarias de menos de 60 mg/kg/día. Si no se ha
            logrado una respuesta clínica satisfactoria, se deben determinar los niveles plasmáticos para definir si se
            encuentran en el rango terapéutico generalmente aceptado (50 a 100 µg/ml). No se pueden hacer
            recomendaciones con respecto a la seguridad de valproato a dosis de más de 60 mg/kg/día. En un estudio de
            tratamiento complementario para crisis parciales complejas en el que los pacientes estaban recibiendo
            carbamazepina o fenitoína además de valproato semisódico, no fue necesario ajustar la dosis de carbamazepina
            o fenitoína. Sin embargo, ya que valproato puede interactuar con éstos u otros FAE administrados
            concomitantemente, así como con otros fármacos (véase Interacciones medicamentosas y de otro género), se
            recomienda determinar periódicamente las concentraciones plasmáticas de los FAE concomitantes durante el
            inicio del tratamiento (véase Interacciones medicamentosas y de otro género).<br/>
            Crisis de ausencia simple y compleja para adultos y niños de 10 años de edad y mayores: La dosis inicial
            recomendada es 15 mg/kg/día, ajustándola en intervalos de una semana en aumentos de 5 a 10 mg/kg/día hasta
            que las crisis se controlen o los efectos colaterales impidan más aumentos. La dosis máxima recomendada es
            60 mg/kg/día. No se ha establecido una buena correlación entre la dosis diaria, concentraciones séricas y
            efecto terapéutico. Sin embargo, se considera que la concentración sérica terapéutica del valproato para la
            mayoría de los pacientes con crisis de ausencia varía de 50 a 100 µg/ml. Algunos pacientes pueden ser
            controlados con concentraciones séricas más bajas o más altas. Ya que la dosis de valproato semisódico se
            ajusta ascendentemente, las concentraciones sanguíneas de fenobarbital y/o fenitoína pueden ser afectadas
            (véase Precauciones generales). Los fármacos antiepilépticos no deben suspenderse abruptamente en pacientes
            a quienes se administra el fármaco para prevenir crisis convulsivas mayores debido a la fuerte posibilidad
            de precipitar estado epiléptico con hipoxia asociada y amenaza para la vida.<br/>
            Profilaxis de la migraña: La dosis inicial recomendada es 500 mg una vez al día durante una semana, después
            se aumenta a 1,000 mg una vez al día. Aunque las dosis distintas a 1,000 mg una vez al día de valproato
            semisódico de liberación prolongada no se han evaluado en pacientes con migraña, el rango de dosis eficaz de
            las tabletas de valproato semisódico en estos pacientes es 500 a 1,000 mg/día. Como con otros productos con
            valproato, las dosis de valproato semisódico de liberación prolongada se deben personalizar. Las tabletas de
            valproato semisódico de liberación prolongada no son bioequivalentes a las tabletas de valproato semisódico
            con capa entérica (véase Farmacocinética y farmacodinamia en la información para Prescribir versión Amplia).
            Si un paciente requiere ajustes a la dosis más pequeños que los disponibles con valproato semisódico de
            liberación prolongada, en su lugar se deben usar comprimidos de valproato semisódico.<br/>
            Conversión a valproato semisódico de liberación prolongada: En pacientes adultos y pacientes pediátricos de
            10 años de edad o mayores con epilepsia y que previamente hayan recibido valproato semisódico, el valproato
            semisódico de liberación prolongada se debe administrar una vez al día usando 8 a 20% más que la dosis total
            de valproato semisódico (véase tabla 5). Para los pacientes cuya dosis total diaria de valproato semisódico
            no puede convertirse directamente a valproato semisódico ER, se debe evaluar a criterio del médico, aumentar
            la dosis total diaria de valproato semisódico a la siguiente dosis más alta antes de convertir a la dosis
            diaria total adecuada de valproato semisódico ER. No hay datos suficientes que permitan recomendar un factor
            de conversión para pacientes tratados con dosis de valproato semisódico por arriba de 3,125 mg/día. Aunque
            generalmente se acepta que es deseable un tratamiento farmacológico después de una respuesta aguda en la
            manía, tanto para mantenimiento de la respuesta inicial como para la prevención de nuevos episodios
            maniacos, no hay datos que apoyen los beneficios de las tabletas de valproato semisódico de liberación
            prolongada en tal tratamiento más prolongado (es decir, más de 3 semanas).

          </p>
          <img src={"./imgs/mirj-5.png"} className=" md:px-[15rem]"/>
          <p>* Estas dosis totales diarias de valproato semisódico no pueden convertirse directamente a una dosis total
            diaria 8 a 20% más alta de valproato semisódico ER porque no hay presentaciones de valproato semisódico ER
            en las concentraciones de dosis necesarias. El médico debe valorar aumentar la dosis diaria de valproato del
            paciente a la siguiente dosis más alta antes de cambiar a la dosis diaria total adecuada de valproato
            semisódico ER.<br/>
            Las concentraciones Cmín. plasmáticas de valproato semisódico ER son equivalentes a las de valproato
            semisódico, pero pueden variar entre los pacientes después de la conversión. Si no se ha logrado una
            respuesta clínica satisfactoria, se deben determinar los niveles plasmáticos para definir si se encuentran
            en el rango terapéutico generalmente aceptado (50 a 100 µg/ml).
            Recomendaciones generales de dosificación:<br/>
            Administración en pacientes de edad avanzada: Debido a una disminución en la depuración de valproato no
            unido y posiblemente a una mayor sensibilidad a la somnolencia en los ancianos, la dosis inicial debe
            reducirse en estos pacientes. En los ancianos las dosis iniciales de menos de 250 mg sólo pueden lograrse
            mediante el uso de formulaciones de valproato semisódico. La dosis debe aumentarse más lentamente y con un
            monitoreo periódico de ingesta de líquidos y alimentos, deshidratación, somnolencia y otros eventos
            adversos. En pacientes con –¬menor ingesta de alimentos o líquidos y en pacientes con somnolencia excesiva
            debe considerarse reducir o suspender la dosis de valproato. La dosis terapéutica máxima debe lograrse con
            base en la tolerabilidad y respuesta clínica (véase Precauciones generales).<br/>
            Eventos adversos relacionados con la dosis: La frecuencia de efectos adversos (en particular elevación de
            enzimas hepáticas y trombocitopenia) puede estar relacionada con la dosis. La probabilidad de
            trombocitopenia al parecer aumenta significativamente a concentraciones totales de valproato de ³ 110 µg/ml
            (mujeres) o ³ 135 µg/ml (varones) (véase Precauciones generales). Se debe valorar el beneficio de un mejor
            efecto terapéutico con dosis más altas contra la posibilidad de una mayor evidencia de reacciones adversas.
            Irritación gastrointestinal: Los pacientes que experimentan irritación G.I. pueden beneficiarse de la
            administración del fármaco con alimentos o iniciando el tratamiento con una dosis más baja de tabletas de
            valproato semisódico.<br/>
            Apego al tratamiento: Se debe informar a los pacientes que deben tomar su medicamento de valproato
            semisódico de liberación prolongada todos los días como se les prescribió. Si se omite una dosis, ésta debe
            tomarse lo antes posible, a menos que sea casi la hora de la siguiente dosis. Si se salta una dosis, el
            paciente no debe aumentar al doble la siguiente dosis.
            Neuropatía y dolor neurítico: Iniciar con dosis de 500 mg EPIVAL ER por la noche, e incrementar dosis
            semanal hasta obtener respuesta. La respuesta se obtiene, generalmente a dosis de 1 a 1.5 g por día. Valorar
            a dosis mayores de 1.5 g, la realización de niveles séricos semestrales o más frecuentes, principalmente en
            la población en edad senil, con politerapia o con riesgo de alteraciones en el metabolismo hepático.
          </p>

        </Collapse.Content>
      </Collapse>
      <Collapse
        icon="arrow"
        className="border-b border-gray-500"

      >
        <Collapse.Title className="text-xl font-medium text-orange-500">
          MANIFESTACIONES Y MANEJO DE LA SOBREDOSIFICACIÓN O INGESTA ACCIDENTAL
        </Collapse.Title>
        <Collapse.Content className="flex flex-col gap-4">
          <p>La sobredosis con ácido valpróico puede producir somnolencia, bloqueo cardiaco y coma profundo.Se han
            informado casos fatales, sin embargo se han recuperado pacientescon niveles de valproato tan elevados como
            2,120 µg/ml. En situaciones de sobredosis, la fracción del fármaco no unido a proteínas es alta y la
            hemodiálisis o hemodiálisis en tándem más hemoperfusión puede producir eliminación significativa del
            fármaco. El beneficio del lavado gástrico o emesis variará con el tiempo a partir de la ingestión. Deben
            aplicarse medidas complementarias con atención especial al mantenimiento de una diuresis adecuada. Se ha
            reportado que naloxona revierte los efectos depresores del sistema nervioso de la sobredosis de valproato.
            La naloxona debe usarse con precaución en pacientes con epilepsia, ya que en teoría tiene la capacidad de
            revertir los efectos antiepilépticos de valproato.</p>
        </Collapse.Content>
      </Collapse>
    </div>
  );
}