import SessionProvider from "./SessionProvider/Session.context";
import {tProvidersProps} from "./Providers.types";
import {Navbar} from "../components/Navbar/Navbar.component";

export default function Providers(props: tProvidersProps) {
    const {children} = props;
    return (
        <SessionProvider>
            <Navbar>
                {children}
            </Navbar>
        </SessionProvider>
    );
}