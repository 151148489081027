import {MedicinesEnum} from "../../../Medicines.enum";
import {FaChevronLeft} from "react-icons/fa";
import {Collapse} from "react-daisyui";

type DosisProps = {
    setSelectedTab: (tab: MedicinesEnum) => void;
}

export default function Dosis({setSelectedTab}: DosisProps) {
    return (
        <div className="flex flex-col my-8">
            <div className="grid grid-cols-12  px-4">
                <div className="col-span-3">
                    <button
                        onClick={() => setSelectedTab(MedicinesEnum.MENU)}
                        className="flex items-center justify-center gap-2">
                        <FaChevronLeft className="w-8 h-8"/>
                        <p>Regresar</p>
                    </button>
                </div>
                <div className="col-span-9 ml-14 mt-6">
                    <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
                        <img src={"./imgs/icons/icon-4.png"} alt="icon.1" className=""/>
                    </div>
                </div>
            </div>
            <h2 className="font-bold text-xl text-center mt-2">DOSIFICACIÓN, SOBREDOSIS Y CONSIDERACIONES</h2>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    DOSIS Y VIA DE ADMINISTRACION
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <p>LEVIPIL® tabletas se administra por vía oral, con una cantidad suficiente de líquido y pueden
                        administrarse con o sin alimentos. La posología diaria se divide en dosis iguales repartidas en
                        dos tomas por día.
                    </p>
                    <h2 className="text-orange-300">Monoterapia</h2>
                    <p>En pacientes con diagnóstico reciente la dosis de inicio recomendada es de 250 mg dos veces al
                        día y se incrementa diariamente con dos tomas de 500 mg después de dos semanas de iniciado el
                        tratamiento. La dosis puede aumentarse posteriormente con incrementos de 250 mg, dos veces al
                        día cada dos semanas, dependiendo de la respuesta clínica observada. La dosis máxima recomendada
                        es de 1500 mg dos veces por día.</p>
                    <h2 className="text-orange-300">Terapia de Adultos (de 18 años y mayores) y adolescentes (de 12 a 17
                        años) con 50 kg de peso o más</h2>
                    <p>La dosis terapéutica inicial es de 500 mg dos veces al día. Esta dosis puede ser iniciada el
                        primer día del tratamiento.
                        Dependiendo de la respuesta clínica y de la tolerancia, la dosis diaria se puede incrementar
                        hasta 1500 mg dos veces al día. La modificación de la dosis se puede realizar en incrementos o
                        decrementos de 500 mg dos veces al día cada dos o cuatro semanas.
                    </p>
                    <h2 className="text-orange-300">Ancianos (desde 65 años)</h2>
                    <p>Se recomienda el ajuste de dosis en pacientes ancianos con función renal comprometida (ver
                        Pacientes con insuficiencia renal).</p>
                    <h2 className="text-orange-300">Niños de 4 a 11 años de edad y adolescentes (de 12 a 17 años) con
                        peso menor a 50 kg</h2>
                    <p>La dosis terapéutica inicial es de 10 mg/kg dos veces al día.
                        Dependiendo de la respuesta clínica y tolerancia, la dosis puede incrementar hasta 30 mg/kg dos
                        veces por día. Los cambios de dosis no deben exceder los incrementos o decrementos de 10 mg/kg
                        dos veces al día cada dos semanas. Debe utilizarse la menor dosis efectiva. La dosis para los
                        niños que pesen 50 kg o más es la misma que para los adultos. El medico debe de prescribir la
                        concentración apropiada de acuerdo al peso y la dosis.
                    </p>
                    <h2 className="text-orange-300">Niños menores de 4 años</h2>
                    <p>No hay datos suficientes para recomendar el uso de levetiracetam en niños menores de 4 años.</p>
                    <h2 className="text-orange-300">Pacientes con insuficiencia renal</h2>
                    <p>La dosis diaria de LEVIPIL® debe individualizarse de acuerdo con la función renal. Refiérase a la
                        siguiente tabla y ajuste la dosis como se indica. Para usar esta tabla, es necesaria una
                        estimación de la depuración de creatinina del paciente (CLcr) en ml/min.
                        La CLcr en ml/min puede ser estimada a partir de la determinación de creatinina sérica (mg/ml)
                        utilizando la siguiente ecuación:
                        <br/><br/>
                        CLcr= [140 – edad (años)] x peso (kg) 72 * creatinina sérica (mg/dl)
                        Si el paciente es mujer, el resultado debe multiplicarse por 0.85 Ajuste de dosis para pacientes
                        con insuficiencia renal:</p>
                    <p>1 Se recomienda una dosis de carga de 750 mg en el primer día de tratamiento con LEVIPIL®
                        2 Después de la diálisis se recomienda una dosis suplementaria de 250 a 500 mg.
                        <br/><br/>
                        Para niños con disfunción renal, la dosis de levetiracetam debe ajustarse de acuerdo con la
                        función renal, ya que ésta se relaciona con la depuración del levetiracetam. Esta recomendación
                        se basa en un estudio realizado en pacientes adultos con disfunción renal.
                        Pacientes con insuficiencia hepática: En pacientes con insuficiencia hepática leve a moderada no
                        es necesario ajustar la dosis. En insuficiencia hepática grave la depuración de creatinina puede
                        subestimar el grado de insuficiencia renal, se recomienda una reducción de 50% de la dosis de
                        mantenimiento diario cuando la depuración de creatinina sea menor de 70 ml/min.
                    </p>

                </Collapse.Content>
            </Collapse>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    MANIFESTACIONES Y MANEJO DE LA SOBREDOSIFICACIÓN O INGESTA ACCIDENTAL
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <p>Síntomas: Se han observado casos de somnolencia, agitación, agresión, depresión del nivel de
                        conciencia, depresión respiratoria y coma por la sobredosis de levetiracetam.
                        Tratamiento de la sobredosificación: En la sobredosis aguda puede vaciarse el contenido del
                        estómago por lavado gástrico o por inducción de la emesis. No hay antídoto específico para el
                        levetiracetam. El tratamiento será sintomático y puede incluir hemodiálisis. La eficacia de la
                        eliminación por diálisis es de 60% para levetiracetam y de 74% para el metabolito primario.</p>
                </Collapse.Content>
            </Collapse>
        </div>
    );
}