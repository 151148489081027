
export function WelcomePage() {
    return (

        <div className="flex flex-col md:grid grid-cols-2 md:gap-4 md:h-full md:my-8">
            <img src={"./imgs/backgrounds/index-banner.png"} alt="hero" className="w-full h-96 md:h-full   "/>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 px-2 md:pt-12">
                <a href="/miraji">
                    <img src="./imgs/icons/Miraji.png" alt="Miraji" className="w-full h-full object-cover"/>
                </a>
                <a href="/sunam">
                    <img src="./imgs/icons/Sunam.png" alt="Sunam.png" className="w-full h-full object-cover"/>
                </a>
                <a href="/zunun">
                    <img src="./imgs/icons/Zunun.png" alt="Zunun.png" className="w-full h-full object-cover"/>
                </a>
                <a href="/oxetol">
                    <img src="./imgs/icons/Oxetol.png" alt="Oxetol.png" className="w-full h-full object-cover"/>
                </a>
                <a href="/langinyl">
                    <img src="./imgs/icons/Langinyl.png" alt="Langinyl.png" className="w-full h-full object-cover"/>
                </a>
                <a href="/levipil">
                    <img src="./imgs/icons/Levipil.png" alt="Levipil.png" className="w-full h-full object-cover"/>
                </a>
                <a href="/tejaswi">
                    <img src="./imgs/icons/Tejaswi.png" alt="Tejaswi.png" className="w-full h-full object-cover"/>
                </a>
                <a href="/sundonnez">
                    <img src="./imgs/icons/Sudonnez.png" alt="Sudonnez.png" className="w-full h-full object-cover"/>
                </a>

            </div>

        </div>
    );
}