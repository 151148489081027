import {useState} from "react";
import {MedicinesEnum} from "../Medicines.enum";
import OxetolMenu from "./components/OxetolMenu/OxetolMenu.component";
import OxetolDescription from "./components/OxetolDescription/OxetolDescription.component";
import OxetolPrecautions from "./components/OxetolPrecautions/OxetolPrecaitions.component";
import OxetolEffects from "./components/OxetolEffects/OxetolEffects.component";
import OxetolDosis from "./components/OxetolDosis/OxetolDosis.component";
import OxetolPresentations from "./components/OxetolPresentations/OxetolPresentations.component";

export default function Oxetol(){
  const [selectedTab, setSelectedTab] = useState<MedicinesEnum>(MedicinesEnum.MENU);

  const RenderContent = () => {
    switch (selectedTab) {
      case MedicinesEnum.DESCRIPTION:
        return <OxetolDescription setSelectedTab={setSelectedTab}/>;
      case MedicinesEnum.PRECAUTIONS:
        return <OxetolPrecautions setSelectedTab={setSelectedTab}/>;
      case MedicinesEnum.EFFECTS:
        return <OxetolEffects setSelectedTab={setSelectedTab}/>;
      case MedicinesEnum.DOSIS:
        return <OxetolDosis setSelectedTab={setSelectedTab}/>;
      case MedicinesEnum.MENU:
        return <OxetolMenu selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
      case MedicinesEnum.PRESENTATION:
        return <OxetolPresentations setSelectedTab={setSelectedTab}/>
      default:
        return <div>DEFAULT</div>;
    }
  }

  return(
    <div className="flex flex-col ">
      <div className="flex flex-row">
        <img src={"./imgs/backgrounds/oxetol-banner.png"} alt="Oxetol" className="w-full md:px-[30rem]"/>
      </div>
      <RenderContent/>
    </div>
  );
}