export default function CollapseOne() {
    return (
        <div className="flex flex-col my-2 gap-4">
            <div>
                <strong>GALANTAMINA</strong><br/><br/>

                <strong>FORMA FARMACEUTICA</strong><br/>
                <strong>Cápsulas de liberación prolongada</strong><br/><br/>

                <strong>FORMULA</strong><br/>
                Bromhidrato de Galantamina equivalente a . . . . 8 mg	16 mg	24 mg De Galantamina
                Excipiente cbp	1 Cápsula.

            </div>
        </div>
    )
        ;
}