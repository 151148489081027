import { createContext, useContext } from 'react';
import useSession from './Session.hook';
import { tSessionContext, tSessionProviderProps } from './Session.types';

const initialState: tSessionContext = {
    user: null,
    setUser: () => null,
};

const SessionContext = createContext<tSessionContext>(initialState);

export default function SessionProvider(props: tSessionProviderProps) {
    const { children } = props;
    const session = useSession();

    return (
        <SessionContext.Provider value={session}>
            {children}
        </SessionContext.Provider>
    );
}

export const useSessionContext = (): tSessionContext =>
    useContext(SessionContext);