import {tNavbar} from "./Navbar.types";
import {CiMenuFries} from "react-icons/ci";
import {useLocation} from "react-router-dom";
import {FaChevronLeft, FaChevronRight} from "react-icons/fa";
import {useState} from "react";

export function Navbar(props: tNavbar) {

    const [isOpen, setIsOpen] = useState(false);

    const {pathname} = useLocation();
    const id = pathname.substring(6);

    const handleSmoothScroll = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, targetId: string) => {
        event.preventDefault();
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({behavior: 'smooth'});
        }
    };

    return (
        <nav className="flex flex-col">
            <a
                href="/"
                className="flex m-8 justify-between md:hidden">
                    <img src="./imgs/logos/logo.png" alt="logo" className="w-12"/>
                <CiMenuFries className="w-8 h-8 "/>
            </a>
            {props.children}

            <div className="md:text-xs bottom-0 bg-gray-900 py-2 text-white text-sm px-2  md:w-full">
              <div className="flex flex-col text-center">
                <strong>HECHO EN MÉXICO POR:</strong>
                <p>SERRAL, S.A. DE C.V.</p>
                <p>CAMINO REAL A COCOTITLAN S/N ESQ. CON CONSTITUCION 5 DE FEBRERO ZONA INDUSTRIAL CHALCO. CHALCO, MEXICO 56600</p>
                <strong className="py-2">PARA:</strong>
                <p>SUN PHARMA DE MÉXICO S.A. DE C.V.</p>
                <p> AV. RÍO CHURUBUSCO No.658, COL. SIFÓN, C.P. 09400, DELEG. IZTAPALAPA, D.F., MÉXICO.</p>

                <strong className="py-2">DISTRIBUIDO POR:</strong>
                <p>SUN PHARMA DE MÉXICO, S.A. DE C.V.</p>
                <p>Rancho 4 Milpas Km 1, Mod. 10 Carret. Tepotzotlan La Aurora MDC Fase II, Secc. L. Ex Hacienda San Miguel, C.P. 54716, Cuautitlan Izcalli, México </p>
                <p>Reg. No: 336M2003 SSA IV</p>


              </div>

            </div>


        </nav>
    );
}