import {MedicinesEnum} from "../../../Medicines.enum";
import {FaChevronLeft} from "react-icons/fa";
import {Collapse} from "react-daisyui";

type EffectsProps = {
    setSelectedTab: (tab: MedicinesEnum) => void;
}
export default function Effects({setSelectedTab}: EffectsProps) {
    return (
        <div className="flex flex-col my-8">
            <div className="grid grid-cols-12  px-4">
                <div className="col-span-3">
                    <button
                        onClick={() => setSelectedTab(MedicinesEnum.MENU)}
                        className="flex items-center justify-center gap-2">
                        <FaChevronLeft className="w-8 h-8"/>
                        <p>Regresar</p>
                    </button>
                </div>
                <div className="col-span-9 ml-14 mt-6">
                    <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
                        <img src={"./imgs/icons/icon-3.png"} alt="icon.1" className=""/>
                    </div>
                </div>
            </div>
            <h2 className="font-bold text-xl text-center mt-2">EFECTOS SECUNDARIOS</h2>

            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    REACCIONES SECUNDARIAS Y ADVERSAS
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <p>Levetiracetam ha sido administrado a más de 3,000 sujetos y pacientes. En estudios clínicos
                        controlados han participado 1,023 pacientes con epilepsia. Los datos de seguridad reunidos de
                        estos estudios conducidos en pacientes adultos demostraron que 46.2% y 42.2% de los pacientes
                        experimentaron efectos indeseables en los grupos con levetiracetam y placebo, respectivamente.
                        Además, 2.4% y 2% de los pacientes sufrieron efectos adversos serios en los grupos con
                        levetiracetam y placebo, respectivamente.</p>
                    <h2 className="text-orange-300">Eventos adversos comunes</h2>
                    <p>Los eventos adversos reportados más comunes fueron somnolencia, astenia y mareos. En la
                        recopilación del análisis de seguridad, no hubo evidencia de relación dosis-respuesta, aunque la
                        incidencia y severidad de eventos adversos relacionados con el sistema nervioso central
                        disminuyeron con el tiempo.</p>
                    <h2 className="text-orange-300">Monoterapia</h2>
                    <p>En monoterapia, 49.8% de los sujetos experimentaron cuando menos un evento adverso relacionado
                        con el medicamento. Los eventos adversos más frecuentemente reportados fueron fatiga y
                        somnolencia.</p>

                    <h2 className="text-orange-300">Estudio en población pediátrica</h2>
                    <p>Un estudio conducido con pacientes pediátricos (de 4 a 16 años de edad) mostró que 55.4% de los
                        pacientes en el grupo tratado con levetiracetam y 40.2% de los pacientes tratados con placebo
                        experimentaron efectos indeseables. Los eventos adversos serios fueron experimentados por 0.0%
                        de los pacientes en el grupo tratado por levetiracetam y 1.0% de los pacientes tratados con
                        placebo.<br/>
                        Los efectos indeseables más comúnmente reportados en la población pediátrica fueron somnolencia,
                        hostilidad, nerviosismo, inestabilidad emocional, agitación, anorexia, astenia y cefalea. Los
                        resultados de seguridad en los pacientes pediátricos fueron consistentes con el perfil de
                        seguridad de levetiracetam en los adultos, con excepción de los eventos adversos conductuales y
                        psiquiátricos, que fueron más comunes en los niños que en adultos (38.5% contra 18.6%). Sin
                        embargo, el riesgo relativo fue similar en los niños al compararse con los adultos.</p>
                    <h2 className="text-orange-300">Estudio en adolescentes y adultos</h2>
                    <p>Un estudio realizado en adolescentes y adultos (12 a 65 años) con convulsiones mioclónicas mostró
                        que 33.3% de los pacientes en el grupo de levetiracetam y 30.0% de los pacientes en el grupo
                        placebo experimentaron efectos indeseables que se consideraron relacionados con el tratamiento.
                        Los efectos adversos más comúnmente reportados fueron cefalea y somnolencia. La incidencia de
                        efectos indeseables en pacientes con convulsiones mioclónicas fue menor que en los pacientes
                        adultos con epilepsia parcial (30.0% contra 46.4%).</p>
                    <h2 className="text-orange-300">Estudio en epilepsia generalizada</h2>
                    <p>En un estudio realizado en niños y adultos (4 a 65 años) con epilepsia generalizada idiopática
                        con convulsiones tónico-clónicas generalizadas primarias, se encontró que 39.2% de los pacientes
                        en el grupo levetiracetam y 29.8% de los pacientes en el grupo placebo presentaron efectos
                        indeseables que se relacionaron con el tratamiento. El efecto adverso más comúnmente reportado
                        fue fatiga. Los eventos adversos indeseables reportados comúnmente con levetiracetam concentrado
                        fueron dolor de cabeza y vértigo.</p>
                    <h2 className="text-orange-300">Incidencia de efectos indeseables</h2>
                    <p>La incidencia de efectos indeseables (eventos adversos considerados posiblemente a levetiracetam
                        por el investigador) de estudios clínicos controlados fueron:
                        Efectos indeseables muy comunes (mayor 10%):
                        <br/> - Trastornos generales y condiciones en el sitio de administración: astenia
                        <br/>- Trastornos del sistema nervioso: somnolencia, fatiga
                        Efectos indeseables comunes (mayor 1%, menor a 10%):
                        <br/> - Trastornos de la sangre: trombocitopenia
                        <br/>- Trastornos gastrointestinales: dolor abdominal, diarrea, dispepsia, náusea, vómito
                        <br/>- Trastornos del sistema nervioso: amnesia, ataxia, convulsión, vértigos, dolor de cabeza,
                        <br/>- hipercinesia, temblor, trastornos del equilibrio, problemas de atención, pérdida de
                        memoria.
                        <br/> - Padecimientos psiquiátricos: agresión, agitación, depresión, debilidad emocional/de
                        cambios de
                        humor, hostilidad, insomnio, irritabilidad, nerviosismo, trastornos de personalidad, pensamiento
                        anormal.
                        <br/> - Metabolismo y trastornos alimenticios: anorexia, aumento de peso. El riesgo de anorexia
                        es
                        mayor
                        cuando se administra topiramato con levetiracetam.
                        <br/>- Padecimientos del oído y del laberinto: vértigo.
                        <br/> - Trastornos de la visión: diplopía y visión borrosa.
                        <br/>- Trastornos del sistema musculoesquelético y del tejido conjuntivo: mialgia.
                        <br/>- Heridas, intoxicaciones y complicaciones del procedimiento: lesión accidental.
                        <br/>- Infecciones e infestaciones: infección, nasofaringitis.
                        <br/>- Padecimientos respiratorios, torácicos y mediastinales: la tos se incrementa.
                        <br/>- Padecimientos de la piel y tejido subcutáneo: eccema, prurito, erupción.
                    </p>
                    <h2 className="text-orange-300">Experiencia poscomercialización</h2>
                    <p>Las alteraciones psiquiátricas o del sistema nervioso son los datos más frecuentemente
                        reportados. Además de los eventos adversos reportados durante el desarrollo de estudios
                        clínicos, los siguientes eventos adversos han sido reportados en la experiencia
                        poscomercialización. Los datos son insuficientes para soportar un estimado de su incidencia en
                        la población a ser tratada.
                        <br/>-Trastornos de la sangre y del sistema linfático: leucopenia, neutropenia, pancitopenia
                        (con la
                        supresión de médula identificada en algunos de los casos).
                        Padecimientos psiquiátricos: comportamiento anormal, cólera, ansiedad, confusión, alucinación,
                        trastornos psicóticos, suicidios, intentos de suicidio o ideas suicidas.
                        <br/>- Trastornos del sistema nervioso: parestesia.
                        Padecimientos de la piel y tejido subcutáneo: alopecia; en varios casos, la recuperación fue
                        observada cuando el levetiracetam fue discontinuado.
                        <br/>-Trastornos gastrointestinales: pancreatitis.
                        <br/>-Trastornos hepatobiliares: hepatitis, trastornos del hígado. Investigaciones: prueba de
                        función
                        hepática anormal, pérdida de peso.
                        <br/>-Experiencia poscomercial: alopecia (en muchos casos se observa recuperación al
                        discontinuar el
                        tratamiento con levetiracetam).</p>


                </Collapse.Content>
            </Collapse>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    INTERACCIONES MEDICAMENTOSAS Y DE OTRO GÉNERO
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <p>Levetiracetam no influye en las concentraciones séricas de los medicamentos antiepilépticos
                        conocidos (fenitoína, carbamazepina, ácido valpróico, fenobarbital, lamotrigina, gabapentina y
                        primidona), y estos medicamentos no influyen en la farmacocinética de levetiracetam.
                        La depuración de levetiracetam fue 22% mayor en los niños tratados con fármacos antiepilépticos
                        inductores de enzimas que en los niños que no fueron tratados con estos medicamentos, por lo que
                        no se recomienda un ajuste de dosis.
                        El levetiracetam no tiene efectos sobre las concentraciones plasmáticas de carbamazepina,
                        valproato, topiramato o lamotrigina.
                        Se ha comprobado que probenecid (500 mg cuatro veces al día), bloqueador de la secreción tubular
                        renal, inhibe la depuración renal del metabolito primario pero no del levetiracetam. Sin
                        embargo, los niveles del metabolito se mantienen bajos. Es de esperar que otros fármacos que se
                        excretan por secreción tubular activa puedan reducir también la depuración renal del metabolito.
                        No se ha estudiado el efecto del levetiracetam sobre el probenecid y no se conoce el efecto de
                        levetiracetam sobre otros fármacos secretados activamente, por ejemplo, AINEs, sulfonamidas y
                        metotrexato.
                        Una dosis diaria de 1,000 mg de levetiracetam no influye en la farmacocinética de los
                        anticonceptivos orales (etinilestradiol y levonorgestrel), y no se modifican los parámetros
                        endocrinos (hormona luteinizante y progesterona).
                        Una dosis diaria de 2,000 mg de levetiracetam no influye en la farmacocinética de la digoxina y
                        de la warfarina, y no se modificó el tiempo de protrombina. La coadministración con digoxina,
                        anticonceptivos orales y warfarina no tuvo influencia sobre la farmacocinética del
                        levetiracetam.
                        No hay información disponible de la influencia de los antiácidos sobre la adsorción del
                        levetiracetam. El grado de absorción del levetiracetam no se ve alterado por los alimentos,
                        aunque la velocidad de absorción se redujo ligeramente. De la misma manera, la adsorción de
                        levetiracetam no se ve alterada por los alimentos; sin embargo, la tasa de absorción se
                        encuentra sustancialmente disminuida.
                        No se dispone de datos sobre la interacción del levetiracetam con alcohol.
                    </p>
                </Collapse.Content>
            </Collapse>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    PRECAUCIONES Y RELACION CON EFECTOS DE CARCINOGENESIS, MUTAGENESIS, TERATOGENESIS Y SOBRE LA
                    FERTILIDAD
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <p>Basándose en los estudios convencionales de seguridad, farmacología, genotoxicidad y
                        carcinogenicidad, los resultados preclínicos no muestran riesgo especial en los humanos.
                        No se observaron eventos adversos en los estudios clínicos, pero si se observaron en ratas y en
                        menor medida en ratones, a niveles similares a los niveles de exposición del humano y con
                        posible relevancia clínica cambios hepáticos que indicaron una respuesta adaptativa, como
                        incremento de peso e hipertrofia centrilobular, infiltración grasa e incremento de las enzimas
                        hepáticas en plasma.
                        Carcinogénesis: datos preclínicos de seguridad. Los datos preclínicos no revelan ningún peligro
                        especial para los humanos basados en estudios convencionales de seguridad, farmacología,
                        genotoxicidad y carcinogenicidad.
                        Los efectos adversos no observados en estudios clínicos, pero vistos en rata y en menor grado en
                        ratón, para exposiciones similares a los niveles de exposición en humanos y con posible
                        relevancia para uso clínico fueron cambios en hígado que indicaban una respuesta adaptada así
                        como incrementada e hipertrofia centrilobular, infiltración de grasa e incremento de las enzimas
                        del hígado en plasma.
                        Se realizaron estudios en ratas con dosis de levetiracetam en la dieta durante 104 semanas a
                        dosis de 50, 300y 1800 mg/Kg/día. La dosis más alta corresponde a 6 veces la máxima dosis
                        recomendada para humanos (MRHD) 3000 mg con base a dosis de mg/m2, la que también presenta una
                        exposición sistémica (AUC) aproximadamente 6 veces más alta que la alcanzada por humanos que
                        recibían MRHD, los resultados obtenidos no demuestran evidencia de carcinogenicidad. No hay
                        evidencia de carcinogenicidad.
                        Se han realizado dos estudios en ratones. En el primero, los ratones recibieron levetiracetam en
                        su dieta durante 80 semanas a dosis de 60, 240 y 960 mg/kg/día (la dosis más alta es la
                        equivalente a dos veces MRHD en mg/m2 o base de exposición). En el segundo estudio los ratones
                        recibieron levetiracetam por vía oral durante dos años a dosis de 1000, 2000 y 4000 mg/kg/día.
                        Debido a la pobre supervivencia a la dosis más alta, 4000 mg/kg/día en este estudio, la dosis
                        más alta fue reducida a 3000 mg/kg/día (equivalente a 12 veces el MRHD) Sin embargo ningún
                        estudio demostró evidencia de carcinogenicidad.
                        En los estudios de toxicidad reproductiva en la rata, el levetiracetam indujo toxicidad durante
                        el desarrollo (incremento en las variaciones o anomalías menores del esqueleto, crecimiento
                        retardado, aumento en la mortalidad fetal) a niveles de exposición similar a o mayores a los de
                        exposición humana.
                        En el conejo, los efectos fetales (muerte embrionaria, incremento de anormalidades esqueléticas
                        y de malformaciones) se observaron en los casos de toxicidad materna.
                        La exposición sistémica a nivel de no efecto observado en el conejo fue de aproximadamente 4 a 5
                        veces el nivel de exposición en el humano. Los estudios en animales neonatos y jóvenes (ratas y
                        perros) demostraron que no se observaron efectos adversos en ninguno de los puntos clave de
                        desarrollo o madurez a dosis mayores a los 1800 mg/kg/día correspondiente a 30 veces el máximo
                        de la dosis humana recomendada.
                    </p>
                </Collapse.Content>
            </Collapse>
        </div>
    );
}