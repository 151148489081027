export default function CollapseTwo() {
    return (
        <div className="flex flex-col my-2 gap-4">
            <p>LEVIPIL® está indicado como monoterapia en el tratamiento de las crisis parciales con o sin
                generalización secundaria en pacientes mayores a 16 años con diagnóstico reciente de epilepsia.
                LEVIPIL® está indicado como monoterapia concomitante:
                <br />- En el tratamiento de la crisis parciales con o sin generalización secundaria en niños mayores de 8
                años
                y adultos con epilepsia
                <br />- En el tratamiento de las convulsiones mioclónicas en adultos y adolescentes mayores de 12 años con
                epilepsia mioclónica juvenil.
                <br />- En el tratamiento de las crisis tónico-clónicas generalizadas primarias en adultos y niños mayores a 6
                años con epilepsia idiomática generalizada.</p>
        </div>
    );
}