import {MedicinesEnum} from "../../../Medicines.enum";
import {FaChevronLeft} from "react-icons/fa";
import {Collapse} from "react-daisyui";
import CollapseOne from "./components/CollapseOne/CollapseOne.component";


type PrecautionsProps = {
    setSelectedTab: (tab: MedicinesEnum) => void;
}
export default function Precautions({setSelectedTab}: PrecautionsProps) {
    return (
        <div className="flex flex-col my-8">
            <div className="grid grid-cols-12  px-4">
                <div className="col-span-3">
                    <button
                        onClick={() => setSelectedTab(MedicinesEnum.MENU)}
                        className="flex items-center justify-center gap-2">
                        <FaChevronLeft className="w-8 h-8"/>
                        <p>Regresar</p>
                    </button>
                </div>
                <div className="col-span-9 ml-14 mt-6">
                    <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
                        <img src={"./imgs/icons/icon-2.png"} alt="icon.1" className=""/>
                    </div>
                </div>
            </div>
            <h2 className="font-bold text-xl text-center mt-2">PRECAUCIONES ESPECIALES</h2>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    CONTRAINDICACIONES
                </Collapse.Title>
                <Collapse.Content>
                    <CollapseOne/>
                </Collapse.Content>
            </Collapse>

            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    PRECAUCIONES GENERALES
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <p>Cuando se prescriba Pramipexol a sujetos con insuficiencia renal, es recomendable realizar una
                        reducción en su dosificación.</p>
                    <div className="grid grid-cols-12 gap-4">
                        <p className="col-span-7">Las alucinaciones y las confusiones se reconocen como efectos
                            secundarios del tratamiento de la enfermedad de Parkinson con agonistas de la dopamina y
                            levodopa. Las alucinaciones fueron más frecuentes cuando se administró Pramipexol en
                            combinación con levodopa en pacientes con enfermedad avanzada, que en la monoterapia en
                            pacientes con enfermedad temprana.
                            <br></br><br></br>
                            En el desarrollo clínico del programa de registro del síndrome de piernas inquietas, se
                            reportó un caso de alucinaciones. Los pacientes deben ser advertidos acerca del hecho de que
                            algunas alucinaciones (generalmente visuales) pueden ocurrir y que podrían afectar la
                            habilidad para manejar.</p>
                        <div className="flex items-center col-span-5">
                            <img src={"./imgs/sunam-conf.png"} alt="sunam-conf.png"
                                 className="w-full col-span-5 items-center flex"/>
                        </div>

                    </div>
                    <p>Los pacientes y sus cuidadores deben estar pendientes de que pueden ocurrir cambios en el
                        comportamiento, como tendencia patológica al juego, aumento de la libido, hipersexualidad y
                        comer compulsivamente; en estos casos, debe valorarse una reducción o suspensión del
                        tratamiento.
                        <br></br><br></br>
                        Cambios patológicos, como degeneración y pérdida de células fotorreceptoras, fueron observados
                        en la retina de ratas albinas en un estudio de carcinogenicidad de 2 años. No se revelaron
                        cambios similares en la evaluación en la retina de los ratones albinos, ratas pigmentadas,
                        changos y puercos. No se ha establecido la relación de este efecto en humanos, pero no se puede
                        descartar porque puede estar involucrada la interrupción de un mecanismo que está presente
                        universalmente en los vertebrados.</p>
                    <div className="grid grid-cols-12">
                        <div className="flex items-center col-span-5">
                            <img src={"./imgs/sunam-heart.png"} alt="sunam-conf.png"
                                 className="w-full  items-center flex"/>
                        </div>

                        <p className="col-span-7">En sujetos con cardiopatías severas deberán tomarse precauciones
                            especiales. Se recomienda el
                            monitoreo de la presión arterial, especialmente al principio del tratamiento, debido al
                            riesgo de sufrir hipotensión postural asociada a un tratamiento dopaminérgico.</p>
                    </div>
                    <p>Los pacientes deben ser alertados sobre los efectos sedantes potenciales de Pramipexol,
                        incluyendo la somnolencia y la posibilidad de quedarse dormido durante las actividades propias
                        de la vida diaria. Debido a que la somnolencia es un efecto adverso frecuente con consecuencias
                        potencialmente serias, los pacientes no deben manejar u operar maquinaria compleja hasta que
                        hayan ganado suficiente experiencia con Pramipexol, para medir si afecta adversamente o no su
                        desarrollo mental y motor.
                        <br></br><br></br>
                        Los pacientes deben ser advertidos que, si durante el tratamiento los episodios de somnolencia
                        se incrementan o se quedan dormidos durante el desarrollo de las actividades de la vida diaria,
                        deben evitar manejar y participar en actividades potencialmente peligrosas, y comentarlo
                        inmediatamente con su médico.
                        <br></br><br></br>
                        Estudios epidemiológicos han demostrado que los pacientes con enfermedad de Parkinson tienen un
                        riesgo elevado (de 2 a aproximadamente 6 veces más alto) de desarrollar melanoma que la
                        población general. Aún permanece confuso si el riesgo creciente observado es debido a la
                        enfermedad de Parkinson.
                        <br></br><br></br>
                        Por las razones anteriormente mencionadas, los pacientes y sus responsables deben ser
                        aconsejados acerca de monitorear la presencia de melanoma cuando están utilizando Pramipexol u
                        otras drogas dopaminérgicas.
                        <br></br><br></br>
                        En la enfermedad de Parkinson, síntomas sugestivos de síndrome neuroléptico maligno han sido
                        reportados con el retiro abrupto de la terapia dopaminérgica.</p>
                </Collapse.Content>
            </Collapse>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    Síndrome de las piernas inquietas
                </Collapse.Title>
                <Collapse.Content>
                    <div style={{background: 'url("./imgs/sunam-bg-prec.png")'}}
                         className="flex justify-end text-white ">
                        <div className="grid grid-cols-12">
                            <div className="col-span-4"></div>
                            <p className="col-span-8 py-4"> Existen reportes en la literatura que indican que el
                                tratamiento de síndrome de piernas inquietas con medicamentos dopaminérgicos, pueden
                                resultar en un aumento de la aparición temprana de síntomas por la mañana o incluso por
                                la tarde, además de extenderse a otras extremidades. No se ha establecido la frecuencia
                                de este evento tras tratamientos prolongados con Pramipexol ni se ha evaluado en
                                estudios clínicos controlados el manejo adecuado de estos eventos.
                            </p>
                        </div>


                    </div>
                </Collapse.Content>
            </Collapse>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    RESTRICCIONES DE USO DURANTE EL EMBARAZO Y LA LACTANCIA
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <div>
                        <p>No se ha estudiado los efectos sobre el embarazo y la fertilidad en humanos. Debido a que el
                            medicamento inhibe la secreción de la prolactina, no es recomendable su uso durante la
                            lactancia. No se administra durante el embarazo y la lactancia. </p>
                    </div>

                </Collapse.Content>
            </Collapse>


        </div>
    );
}