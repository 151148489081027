import {MedicinesEnum} from "../../../Medicines.enum";
import {FaChevronLeft} from "react-icons/fa";
import {Collapse} from "react-daisyui";

type EffectsProps = {
    setSelectedTab: (tab: MedicinesEnum) => void;
}
export default function Effects({setSelectedTab}: EffectsProps) {
    return (
        <div className="flex flex-col my-8">
            <div className="grid grid-cols-12  px-4">
                <div className="col-span-3">
                    <button
                        onClick={() => setSelectedTab(MedicinesEnum.MENU)}
                        className="flex items-center justify-center gap-2">
                        <FaChevronLeft className="w-8 h-8"/>
                        <p>Regresar</p>
                    </button>
                </div>
                <div className="col-span-9 ml-14 mt-6">
                    <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
                        <img src={"./imgs/icons/icon-3.png"} alt="icon.1" className=""/>
                    </div>
                </div>
            </div>
            <h2 className="font-bold text-xl text-center mt-2">EFECTOS SECUNDARIOS</h2>

            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    REACCIONES SECUNDARIAS Y ADVERSAS
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <div className="grid grid-cols-12">
                        <p className="col-span-8">Los efectos adversos que han sido reportados durante el uso de
                            Pramipexol: sueños anormales,
                            confusión, constipación, paranoia, vértigo, disquinesia, fatiga, alucinaciones, cefalea,
                            hiperquinesia, hipotensión, aumento de apetito (comer compulsivamente, hiperfatiga),
                            insomnio, alteraciones de la libido, edema periférico, paranoia, tendencia patológica al
                            juego, hipersexualidad y comportamiento anormal, somnolencia, aumento de peso y somnolencia
                            repentina, prurito, rash y otras hipersensibilidades.</p>
                        <div className="flex items-center col-span-4">
                            <img src={"./imgs/sunam-effect-1.png"} alt="sunam-conf.png"
                                 className="w-full col-span-4 items-center flex"/>
                        </div>
                    </div>
                    <p>La incidencia de hipotensión bajo tratamiento con Pramipexol, comparada con el tratamiento con
                        placebo, no se vio incrementada. Sin embargo, en algunos pacientes, la hipotensión puede suceder
                        al inicio del tratamiento, especialmente si la dosis de Pramipexol es incrementada muy
                        rápidamente.
                        <br></br><br></br>
                        Pramipexol puede estar asociado con alteraciones de la libido (aumento o disminución). Pacientes
                        en el tratamiento con Pramipexol han reportado quedarse dormidos durante las actividades de la
                        vida diaria incluyendo la operación de vehículos motorizados lo cual en algunas ocasiones
                        resulta en accidentes.
                        <br></br><br></br>
                        Algunos de ellos no reportaron algún signo de alerta como la somnolencia, la cual es común que
                        suceda en pacientes a los cuales se les administró Pramipexol en tabletas o en dosis cercanas a
                        1.5 mg al día y que de acuerdo con la fisiología del sueño siempre precede éste.
                        <br></br><br></br>
                        No existe una relación clara en cuanto a la duración del tratamiento. Algunos pacientes se
                        encontraban ingiriendo otra medicación con propiedades sedativas potenciales. En muchos casos
                        donde existe información disponible, no existieron más episodios tras la reducción de la dosis o
                        con la terminación de la terapia.
                        <br></br><br></br>
                        Como se describe en la literatura relacionada con los agonistas de la dopamina utilizados para
                        el tratamiento de la enfermedad de Parkinson, los pacientes tratados con Pramipexol en especial
                        a dosis altas, han mostrado una tendencia patológica al juego, incremento de la libido e
                        hipersexualidad, generalmente reversible después de la descontinuación del tratamiento.</p>
                </Collapse.Content>
            </Collapse>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    INTERACCIONES MEDICAMENTOSAS Y DE OTRO GÉNERO
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <p>Los medicamentos que inhiben la excreción tubular renal de fármacos básicos (catiónicos), como la
                        cimetidina, o que son eliminados por secreción tubular, pueden interactuar con el Pramipexol,
                        ocasionando una disminución de la eliminación que cualquiera de éstos o ambos.
                        <br></br><br></br>
                        En caso de administrarse concomitantemente este tipo de medicamentos (inclusive amantadina) con
                        Pramipexol, debe ponerse atención a la aparición de signos de sobreestimulación dopaminérgica
                        como discinesias, agitación o alucinaciones. En este caso será necesario disminuir la dosis de
                        Pramipexol.
                    </p>
                    <div className="grid grid-cols-2 gap-4">
                        <p>La selegilina y la levodopa no modifican las características farmacocinéticas de Pramipexol,
                            en tanto que éste no altera la absorción y la eliminación de levodopa. </p>
                        <img src={"./imgs/sunam-pills.png"} alt="sunam-conf.png"
                             className="w-full  items-center flex"/>
                    </div>
                    <p>La distribución y eliminación de levodopa no son modificadas por el Pramipexol. No se ha
                        estudiado la interacción con anticolinérgicos ni con amantadina, aunque es posible que ocurra
                        una interacción con ésta debido a que dichos fármacos se eliminan vía renal.
                        <br></br><br></br>
                        Como los anticolinérgicos son eliminados principalmente por el metabolismo hepático, las
                        interacciones farmacocinéticas medicamentosas con Pramipexol son improbables.
                        <br></br><br></br>
                        Cuando se administra conjuntamente con levodopa, se recomienda disminuir la dosis de ésta al
                        incrementar la de Pramipexol y debe permanecer constante la dosis de otros medicamentos
                        antiparkinsonianos.
                        <br></br><br></br>
                        Deben tomarse ciertas precauciones en pacientes que se encuentran ingiriendo medicación sedante
                        o alcohol en combinación con Pramipexol por los efectos adicionales, así como cuando se agreguen
                        medicamentos que incrementan los niveles plasmáticos de Pramipexol (por ejemplo,
                        cimetidina).</p>
                </Collapse.Content>
            </Collapse>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    PRECAUCIONES Y RELACION CON EFECTOS DE CARCINOGENESIS, MUTAGENESIS, TERATOGENESIS Y SOBRE LA
                    FERTILIDAD
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">

                    <p>Los estudios de toxicidad a dosis repetidas mostraron que Pramipexol ejercía efectos funcionales,
                        relacionados principalmente con el sistema nervioso central y, en ratas, con el sistema
                        reproductor femenino. Probablemente, estos efectos son el resultado de un efecto farmacodinámico
                        exagerado de Pramipexol.
                        <br></br><br></br>
                        Se observó una disminución de la presión diastólica y sistólica, así como en el índice cardiaco
                        del lechón, y también se observó una tendencia hacia un efecto hipotensivo en el mono.
                        <br></br><br></br>
                        Los efectos potenciales de Pramipexol sobre la función reproductiva han sido investigados en
                        ratas y conejos. Pramipexol no fue teratogénico en ratas y conejos, pero fue embriotóxico en
                        ratas a dosis tóxicas durante el periodo de gestación.
                        <br></br><br></br>
                        Debido al efecto de hipoprolactinemia del compuesto y al rol especial de la prolactina en la
                        función reproductiva en las ratas hembra, los efectos de Pramipexol en el embarazo y la
                        fertilidad femenina no han sido totalmente dilucidados.
                        <br></br><br></br>
                        Pramipexol no fue genotóxico. En un estudio de carcinogenicidad, las ratas machos desarrollaron
                        hiperplasia en las células de Leydig y adenomas; esto es explicado por el efecto inhibitorio de
                        Pramipexol sobre la prolactina. Este hallazgo no es clínicamente relevante para el humano.
                        <br></br><br></br>
                        El mismo estudio mostró que a dosis de 2 mg/kg o mayores, Pramipexol fue asociado con
                        degeneración de la retina en ratas albinas. Estos últimos hallazgos no fueron observados en
                        ratas pigmentadas ni en un estudio de carcinogenicidad de dos años en ratones albinos, ni en
                        algunas otras especies investigadas.
                    </p>
                </Collapse.Content>
            </Collapse>
        </div>
    );
}