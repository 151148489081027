import {MedicinesEnum} from "../../../Medicines.enum";
import {FaChevronLeft} from "react-icons/fa";
import {Collapse} from "react-daisyui";

type DosisProps = {
    setSelectedTab: (tab: MedicinesEnum) => void;
}

export default function Dosis({setSelectedTab}: DosisProps) {
    return (
        <div className="flex flex-col my-8">
            <div className="grid grid-cols-12  px-4">
                <div className="col-span-3">
                    <button
                        onClick={() => setSelectedTab(MedicinesEnum.MENU)}
                        className="flex items-center justify-center gap-2">
                        <FaChevronLeft className="w-8 h-8"/>
                        <p>Regresar</p>
                    </button>
                </div>
                <div className="col-span-9 ml-14 mt-6">
                    <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
                        <img src={"./imgs/icons/icon-4.png"} alt="icon.1" className=""/>
                    </div>
                </div>
            </div>
            <h2 className="font-bold text-xl text-center mt-2">DOSIFICACIÓN, SOBREDOSIS Y CONSIDERACIONES</h2>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    DOSIS Y VIA DE ADMINISTRACION
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <p>Oral
                        Galantamina Cápsulas de liberación prolongada deben ser administradas una vez al día por la
                        mañana, de preferencia con los alimentos. La dosis recomendada de inicio es de 8 mg/día.
                        Debe asegurarse siempre una adecuada ingesta de líquidos durante el tratamiento.</p>
                    <h2 className="text-orange-300">Dosis de mantenimiento</h2>
                    <p>La dosis de mantenimiento es de 16 mg/día con cápsulas de liberación prolongada, los pacientes
                        deben ser mantenidos en 16 mg/día al menos durante 4 semanas.
                        Un incremento a la dosis máxima de mantenimiento recomendada, de 24 mg/día (24 mg una vez al día
                        con cápsulas) deben ser consideradas después de una apropiada evaluación incluyendo una
                        evaluación del beneficio y tolerabilidad clínica.
                        No existe efecto de rebote después de la suspensión brusca del tratamiento (por ejemplo: en
                        preparación para una cirugía).</p>
                    <h2 className="text-orange-300">Insuficiencia hepática o renal</h2>
                    <p>En pacientes con moderado deterioro de la función hepática, basados en un modelo farmacocinético,
                        deben comenzar con una cápsula de liberación prolongada de 8 mg una vez al día por lo menos una
                        semana, de preferencia tomarla por la mañana. Después de eso, los pacientes deben continuar con
                        8 mg, una vez al día para las cápsulas de liberación prolongada por lo menos cuatro semanas. En
                        estos pacientes, las dosis diaria total no debe exceder de 16 mg.
                        En pacientes con severo deterioro hepático, el uso de Galantamina no está recomendado.
                        En pacientes con depuración de creatinina mayor de 9 ml/min, no se requiere ajuste de dosis. En
                        pacientes con severo deterioro renal (DRCE menor de 9 ml/min), el uso de Galantamina no es
                        recomendado dado que no existe información al respecto.</p>
                    <h2 className="text-orange-300">Tratamiento concomitante</h2>
                    <p>En pacientes tratados con inhibidores potentes de CYP2D6 ó CYP3A4, una reducción de dosis puede
                        ser considerada.</p>

                </Collapse.Content>
            </Collapse>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    MANIFESTACIONES Y MANEJO DE LA SOBREDOSIFICACIÓN O INGESTA ACCIDENTAL
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <p>Los signos y síntomas de una sobredosis de Galantamina son predeciblemente similares a los vistos
                        en una sobredosis de otros colinomiméticos. Estos efectos generalmente involucran el sistema
                        nervioso central, el sistema nervioso parasimpático y la placa neuromuscular. Además de
                        debilidad muscular o fasciculaciones, pueden desarrollarse alguno o todos los signos de crisis
                        colinérgica: náuseas severas, vómito, calambres gastrointestinales, sialorrea, epífora, pérdida
                        del control del esfínter vesical y/o anal, diaforesis, bradicardia, hipotensión, estado de
                        choque y crisis convulsivas. El incremento en la debilidad muscular junto con hipersecreción
                        traqueal y broncoespasmo quizá lleve a un compromiso vital de la vía aérea.
                        Hay reportes post-comercialización de Torsade de pointes, prolongación del intervalo QT,
                        bradicardia, taquicardia ventricular y pérdida del estado de alerta, asociado con la ingestión
                        inadvertida de sobredosificación de Galantamina. En un caso donde la dosis se conoció, 8
                        tabletas de 4 mg (32 mg total) fueron ingeridas en un solo día" Dos casos adicionales de
                        ingestión accidental; de 32 mg (náuseas, vómito y sequedad de boca; otro con náuseas, vómito y
                        dolor torácico) y un caso más que ingirió 40 mg (vómito), terminó en una breve hospitalización
                        para observación, con una total recuperación. Un paciente a quien le fue prescrita una dosis de
                        24 mg/día y que tenía una historia de alucinaciones en los dos años previos, erróneamente
                        recibió 24 mg dos veces al día por 34 días y desarrolló alucinaciones requiriendo
                        hospitalización. Sus síntomas se resolvieron en 24 horas.
                    </p>
                    <h2 className="text-orange-300">Tratamiento</h2>
                    <p>Como en cualquier caso de sobredosis, deben utilizarse las medidas generales de soporte. En casos
                        severos, anticolinérgicos como la atropina, pueden utilizarse como un antídoto general para
                        colinomiméticos. Una dosis inicial de atropína 0.5 a 1.0 mg vía intravenosa es recomendada, con
                        dosis subsecuentes, basados en la respuesta clínica.
                        Debido a que las estrategias para el manejo de sobredosis se encuentran en evolución constante,
                        es recomendable contactar al centro de control de intoxicaciones para revisar las últimas
                        recomendaciones para el manejo de la sobredosis.</p>

                </Collapse.Content>
            </Collapse>
        </div>
    );
}