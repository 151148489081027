export default function CollapseOne() {
    return (
        <div className="flex flex-col my-2 gap-4">
            <div>
                <strong>DONEPECILO</strong><br/><br/>

                <strong>FORMA FARMACEUTICA</strong><br/>
                <strong>Tabletas</strong><br/><br/>

                <strong>FORMULA</strong><br/>
                Cada tableta contiene:<br/>
                Clorhidrato de Donepecilo 5 mg ó 10mg<br/>
                Excipientes cbp… 1 tableta<br/>
            </div>
            <p>El Clorhidrato de Donepecilo es un inhibidor reversible de la enzima acetilcolinesterasa, conocido
                químicamente como clorhidrato de (&plusmn;)-2,3
                dihidro-5,6-dimetoxi-2- &#123;[1 - (fenilmetil) - 4 - piperidinil]metil&#125;-1H-inden-1-ona. El
                clorhidrato de
                Donepecilo se conoce comúnmente en la literatura farmacológica como E2020. El compuesto tiene la
                siguiente fórmula empírica: C<sub>24</sub>H<sub>29</sub>NO<sub>3</sub>HCl y un peso molecular de 415.96.
            </p>


        </div>
    )
        ;
}