import {MedicinesEnum} from "../../../Medicines.enum";
import {FaChevronLeft} from "react-icons/fa";
import {Collapse} from "react-daisyui";

type EffectsProps = {
    setSelectedTab: (tab: MedicinesEnum) => void;
}
export default function Effects({setSelectedTab}: EffectsProps) {
    return (
        <div className="flex flex-col my-8">
            <div className="grid grid-cols-12  px-4">
                <div className="col-span-3">
                    <button
                        onClick={() => setSelectedTab(MedicinesEnum.MENU)}
                        className="flex items-center justify-center gap-2">
                        <FaChevronLeft className="w-8 h-8"/>
                        <p>Regresar</p>
                    </button>
                </div>
                <div className="col-span-9 ml-14 mt-6">
                    <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
                        <img src={"./imgs/icons/icon-3.png"} alt="icon.1" className=""/>
                    </div>
                </div>
            </div>
            <h2 className="font-bold text-xl text-center mt-2">EFECTOS SECUNDARIOS</h2>

            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    REACCIONES SECUNDARIAS Y ADVERSAS
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">

                    <h2 className="text-orange-300">Eventos adversos que requirieron la descontinuación del
                        tratamiento</h2>
                    <p>Las tasas de discontinuación del tratamiento en los grupos con Donepecilo 5 mg/día, en estudios
                        clínicos controlados fueron comparables a las de los grupos placebo, de aproximadamente 5%. La
                        tasa de discontinuación en pacientes que recibieron escalaciones de 7 días de 5 mg/día a los 7
                        días fue mayor, de 13%.<br/>
                        En el cuadro 1 se muestran los eventos adversos más frecuentes, que llevaron a la
                        descontinuación del medicamento, definidos como aquellos que
                        ocurrieron en por lo menos 2% de los pacientes y el doble de la incidencia vista en los
                        pacientes que recibieron placebo.</p>
                    <p>Cuadro 1. Efectos adversos más frecuentes, que llevaron a la descontinuación del tratamiento en
                        estudios clínicos con controles por grupo de dosis.</p>
                    <img src={"./imgs/Sudonnez-data.png"} alt="icon.1" className=""/>


                    <h2 className="text-orange-300">Eventos adversos clínicos más frecuentes ocurridos en relación con
                        el uso de Donepecilo</h2>
                    <p>Los eventos adversos más frecuentes, definidos como los que ocurrieron por lo menos a una
                        frecuencia de 5% de pacientes que recibieron 10 mg/día y cuya tasa fue el doble que la del
                        placebo, son predecibles en gran parte por las acciones colinomiméticas de Donepecilo. Entre
                        ellos se incluyen náuseas, diarrea, insomnio, vómito, calambres musculares, fatiga y anorexia.
                        <br/><br/>
                        Estos eventos adversos con frecuencia fueron leves y transitorios y se resolvieron durante la
                        continuación del tratamiento con Donepecilo sin necesidad de modificar la dosis. Existen datos
                        indicativos de que la frecuencia de estos eventos adversos comunes podría verse afectada por la
                        tasa de titulación.
                        <br/><br/>
                        Se realizó un estudio abierto con 269 pacientes que recibieron placebo en estudios a 15 y 30
                        semanas. Estos pacientes fueron titulados a la dosis de 10 mg/día durante un periodo de 6
                        semanas. Las tasas de efectos adversos comunes fueron más bajas que las observadas en pacientes
                        titulados con 10 mg/día durante 1 semana en los estudios clínicos controlados y fueron
                        comparables con las de sujetos que recibieron 5 mg/día.
                        <br/><br/>
                        Véase en el cuadro 2 una comparación de los eventos adversos más frecuentes, luego de los
                        regímenes de titulación de dosis en 1 y 6 semanas.</p>
                    <p>Cuadro 2. Comparación de las tasas de efectos adversos en pacientes fueron titulados a la dosis
                        de 10 mg/día sobre 1 y 6 semanas.</p>
                    <img src={"./imgs/Sudonnez-data-2.png"} alt="icon.1" className=""/>

                    <h2 className="text-orange-300">Eventos adversos señalados en estudios clínicos controlados</h2>
                    <p>Los efectos citados reflejan la experiencia adquirida en las condiciones de monitoreo estrecho de
                        estudios clínicos en una población de pacientes muy selecta. Estos cálculos de frecuencia tal
                        vez no serían aplicables en la práctica clínica real o en otros estudios clínicos, dado que
                        pueden diferir las condiciones de uso, conducta de notificación y tipos de pacientes tratados.
                        <br/><br/>
                        En el cuadro 3 se enumeran los signos y síntomas surgidos durante el tratamiento que se
                        notificaron al menos en 2% de los pacientes de estudios clínicos placebo-controlados que
                        recibieron Donepecilo, y en los que la tasa de incidencia fue mayor que en los sujetos asignados
                        al placebo. En general, los eventos adversos fueron más frecuentes en mujeres de edad
                        avanzada.</p>
                    <p>Cuadro 3. Eventos adversos comunicados en estudios clínicos controlados en al menos 2% de los
                        pacientes que recibieron Donepecilo con una mayor frecuencia que los presentados en pacientes
                        tratados con placebo.</p>
                    <img src={"./imgs/Sudonnez-data-3.png"} alt="icon.1" className=""/>


                    <h2 className="text-orange-300">Otros eventos adversos observados durante los estudios clínicos</h2>
                    <p>Donepecilo se ha administrado a más de 1,700 individuos en estudios clínicos realizados en todo
                        el mundo. De ellos, casi 1,200 han sido tratados por lo menos durante 3 meses, y más de 1,000, a
                        lo largo de 6 meses o más. Los estudios clínicos controlados y no controlados efectuados en los
                        Estados Unidos de Norteamérica incluyeron aproximadamente 900 pacientes. En cuanto a la dosis
                        más alta, de 10 mg/día, esta población incluye 650 individuos tratados por espacio de 3 meses,
                        475 que la recibieron por 6 meses y 116 a los que les fue administrada a lo largo de 1 año. El
                        intervalo de exposición de pacientes va de 1 a 1,214 días. Los investigadores clínicos
                        registraron como efectos adversos, con terminología de su propia elección, los signos y síntomas
                        surgidos durante el tratamiento que ocurrieron durante los 3 estudios clínicos controlados y los
                        dos estudios abiertos en EUA. A fin de tener un cálculo global de la proporción de individuos
                        con eventos de tipo similar, estos se agruparon en un número menor de categorías estandarizadas
                        usando un diccionario COSTART modificado y se calcularon las frecuencias de efectos en todos los
                        estudios. Las categorías utilizadas son las que se enumeran en párrafos siguientes. Las
                        frecuencias reflejan la proporción de 900 pacientes de estos estudios que experimentaron el
                        evento adverso mientras recibían Donepecilo. Se incluyeron todos los eventos adversos que
                        ocurrieron con frecuencia de al menos el doble, excepto los ya mencionados en los cuadros 2 ó 3,
                        los que correspondieron a términos COSTART demasiado generales para resultar informativos, o los
                        que tuvieron menos probabilidades de ser fármaco inducidos. Los eventos se clasifican por
                        sistema o aparato corporal con base en las definiciones siguientes: eventos adversos frecuentes,
                        los que ocurrieron al menos en 1/100 pacientes, y eventos adversos infrecuentes, los que
                        tuvieron lugar en 1/100 a 1/1,000 pacientes. Estos eventos adversos no se relacionan
                        necesariamente con la administración de Donepecilo y en la mayor parte de los casos se
                        observaron con frecuencia similar en sujetos que recibieron placebo en los estudios clínicos
                        controlados. No se observaron otros eventos adversos importantes en estudios realizados fuera de
                        Estados Unidos.</p>
                    <h2 className="text-orange-300">Cuerpo Completo</h2>
                    <p>Frecuente: gripe, dolor de pecho, dolor de dientes, influenza; Infrecuente: fiebre, edema facial,
                        edema periorbital, hernia hiatal, absceso, celulitis, escalofríos, frío generalizado, cabeza
                        pesada, apatía.</p>
                    <h2 className="text-orange-300">Osteomuscular</h2>
                    <h2 className="text-orange-300">Sistema Cardiovascular</h2>
                    <p>Frecuente: hipertensión, vasodilatación, fibrilación atrial (y/o auricular), bochornos,
                        hipotensión; Infrecuente: angina de pecho, hipotensión postural, infarto al miocardio, bloqueo
                        AV (primer grado), insuficiencia cardiaca congestiva, arteritis, bradicardia, enfermedad
                        vascular periférica, taquicardia supraventricular, trombosis venosa profunda.
                    </p>
                    <h2 className="text-orange-300">Sistema Digestivo</h2>
                    <p>Frecuente: incontinencia fecal, hemorragia gastrointestinal, distensión abdominal, timpanismo,
                        dolor epigástrico; Infrecuente: eructos, gingivitis, aumento del apetito, náusea, anorexia,
                        flatulencia, absceso periodontal, colelitiasis, diverticulitis, baboseo, boca seca, llagas de
                        fiebre, gastritis, colon irritable, edema de la lengua, trastorno epigástrico, gastroenteritis,
                        aumento de las transaminasas, hemorroides, íleo, aumento de la sed, ictericia, melena,
                        polidipsia, úlcera duodenal, úlcera estomacal.</p>
                    <h2 className="text-orange-300">Sistema Endocrino</h2>
                    <p>Infrecuente: diabetes mellitus, bocio.</p>
                    <h2 className="text-orange-300">Sistema hemático y linfático</h2>
                    <p>Infrecuente: anemia, trombocitopenia, eosinofilia, eritrocitopenia, trombocitenia.</p>
                    <h2 className="text-orange-300">Trastornos metabólicos y nutricionales</h2>
                    <p>Frecuente: deshidratación; infrecuente: gota, hipocaliemia, aumento de la creatina cinasa,
                        hiperglicemia, aumento de peso, aumento de lactato deshidrogenasa.</p>
                    <p>Frecuente: fractura ósea; Infrecuente: debilidad muscular, fasciculación muscular.</p>
                    <h2 className="text-orange-300">Sistema Nervioso</h2>
                    <p>Frecuente: delusiones, temblores, irritabilidad, parestesias, agresión, vértigo, ataxia, aumento
                        de la libido, inquietud, llanto anormal, nerviosismo, insomnio, afasia; infrecuente: accidente
                        cerebrovascular, hemorragia intracraneal, ataque isquémico transitorio, inestabilidad emocional,
                        neuralgia, frío (localizado), espasmo muscular, miosis, disforia, anormalidad de la marcha,
                        hipertonía, hipocinesia, neurodermatitis, entumecimiento (localizado), paranoia, disartria,
                        disfasia, hostilidad, reducción de la libido, melancolía, retiro emocional, nistagmo, caminar de
                        un lado a otro, neuralgia, disfagia. </p>
                    <h2 className="text-orange-300">Sistema respiratorio</h2>
                    <p>Frecuente: disnea, dolor de garganta, bronquitis; Infrecuente: epistaxis, goteo post-nasal,
                        neumonía, hiperventilación, congestión pulmonar, resuellos, hipoxia, insuficiencia respiratoria,
                        faringitis, pleuresía, colapso pulmonar, apnea durante el sueño, ronquidos.
                    </p>
                    <h2 className="text-orange-300">Piel y apéndices cutáneos</h2>
                    <p>Frecuente: prurito, diaforesis, urticaria: infrecuente: dermatitis, eritema, decoloración de la
                        piel, hiperqueratosis, alopecia, dermatitis micótica, herpes zoster, hirsutismo, estrías,
                        sudores nocturnos, úlcera de la piel, equimosis.</p>
                    <h2 className="text-orange-300">Órganos de los sentidos</h2>
                    <p>Frecuente: catarata, irritación ocular, visión borrosa; Infrecuente: ojos secos, glaucoma,
                        otalgia, tinnitus, blefaritis, reducción de la audición, hemorragia retinal, otitis externa,
                        otitis media, dolor de oídos, mal sabor en la boca, hemorragia conjuntival, zumbido de oídos,
                        enfermedad del movimiento, manchas frente a los ojos.</p>
                    <h2 className="text-orange-300">Sistema urogenital</h2>
                    <p>Frecuente: incontinencia urinaria, nocturia; Infrecuente: disuria, hematuria, urgencia,
                        metrorragia, cistitis, enuresis, poliuria, hipertrofia prostática, pielonefritis, incapacidad de
                        vaciar la vejiga, fibroadenosis de mamas, mamas fibrocísticas, mastitis, piuria, insuficiencia
                        renal, vaginitis. </p>
                    <h2 className="text-orange-300">Reportes post introducción</h2>
                    <p>Se han recibido algunos reportes voluntarios de eventos adversos temporalmente asociados con
                        Donepecilo, desde su introducción en el mercado, que no se enlistaron anteriormente y que pueden
                        tener no relación causal con el medicamento, éstas son: dolor abdominal, agitación,
                        colecistitis, confusión, convulsiones, alucinaciones, pancreatitis, erupción, bloqueo cardiaco
                        (todos los tipos), anemia hemolítica, hepatitis, hiponatremia, síndrome neuroléptico maligno. En
                        general, los eventos adversos ocurren con mayor frecuencia en pacientes femeninos de edad
                        avanzada. </p>
                </Collapse.Content>
            </Collapse>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    INTERACCIONES MEDICAMENTOSAS Y DE OTRO GÉNERO
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <h2 className="text-orange-300">Fármacos altamente unidos con proteínas plasmáticas</h2>
                    <p>Se han realizado estudios de desplazamiento de fármacos in vitro, entre este medicamento
                        altamente unido (96%) y otros fármacos, como la furosemida, digoxina y warfarina. En
                        concentraciones de 0.3-10 µg/ml, Donepecilo no afectó la unión de la furosemida (5 µg/ml),
                        digoxina (2 ng/ml) o warfarina (3 µg/ml) con la albúmina humana. De manera similar, la unión de
                        Donepecilo con la albúmina humana no fue afectada por la furosemida, digoxina o warfarina.
                    </p>

                    <h2 className="text-orange-300">Efecto de Donepecilo en el metabolismo de otros medicamentos</h2>
                    <p>No se han realizado estudios clínicos in vivo para evaluar el efecto de Donepecilo en la
                        depuración de fármacos metabolizados por CYP3A4 (por ejemplo, cisaprida, terfenadina) o CYP2D6
                        (por ejemplo, imipramina). Sin embargo, los estudios in vitro muestran una baja tasa de unión
                        con estas enzimas (media de Ki de 50-130 µM) que, dadas las concentraciones plasmáticas
                        terapéuticas de Donepecilo (164 nM), indican escasas probabilidades de interferencia. No se sabe
                        si Donepecilo tiene potencial para la inducción de enzimas hepáticas. En estudios
                        farmacocinéticos formales se evaluó el potencial de Donepecilo para la interacción de teofilina,
                        cimetidina, warfarina y digoxina. No se observaron efectos significativos en la farmacocinética
                        de estos fármacos. Efectos de otros medicamentos en el metabolismo de Donepecilo: El ketoconazol
                        y quinidina, que inhiben CYP450, 3A4 y 2D6, respectivamente, inhiben el metabolismo de
                        Donepecilo in vitro. No se sabe si estos inhibidores producen un efecto clínico. Los inductores
                        de CYP2D6 y CYP3A4 (por ejemplo, fenitoína, carbamazepina, dexametasona, rifampicina y
                        fenobarbital) podrían aumentar la tasa de eliminación de Donepecilo. Los estudios
                        farmacocinéticos formales demostraron que el metabolismo de Donepecilo no se ve afectado
                        significativamente por la administración concurrente de digoxina o cimetidina</p>
                </Collapse.Content>
            </Collapse>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    PRECAUCIONES Y RELACION CON EFECTOS DE CARCINOGENESIS, MUTAGENESIS, TERATOGENESIS Y SOBRE LA
                    FERTILIDAD
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <p>El Donepecilo no fue mutagénico en el ensayo de mutación inversa de Ames en bacterias. En las
                        pruebas de aberraciones cromosómicas
                        en cultivos de pulmón de hámster chino (PHC), se observaron algunos efectos clastogénicos. El
                        Donepecilo no fue clastogénico en la prueba de micronúcleos en ratones in vivo. El Donepecilo no
                        afectó la fertilidad de ratas en dosis hasta de 10 mg/kg/día (aproximadamente el óctuplo de la
                        dosis máxima recomendada en humanos, sobre la base de mg/m2).
                    </p>
                </Collapse.Content>
            </Collapse>
        </div>
    );
}