import { signOut } from "firebase/auth";
import {useCallback, useEffect} from "react";
import {auth} from "../../lib/firebase";
import {useNavigate} from "react-router-dom";
import {useSessionContext} from "../../Providers/SessionProvider/Session.context";

export default function Dashboard() {
    const navigate = useNavigate();

    const {user} = useSessionContext();

    useEffect(()=>{
        if(!user?.email){
            navigate('/login');
        }
    },[user])

    const logOut = useCallback(() => {
        signOut(auth)
        navigate('/login');
    }, [auth])


    return (
        <div>
            <h1>Bienvenido {user?.email}</h1>
            <h1>Dashboard</h1>
            <p>This is a private route</p>
            <button
                onClick={logOut}
                className="bg-red-500 text-white px-3 py-2 rounded"
            >Cerrar sesion</button>
        </div>
    );
}