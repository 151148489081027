export default function CollapseOne() {
    return (
        <div className="flex flex-col my-2 gap-4">
            <div>
                <strong>ZUNUN</strong><br/><br/>

                <strong>FORMA FARMACEUTICA</strong><br/>
                <strong>Cápsulas de liberación prolongada</strong><br/><br/>

                <strong>FORMULA</strong><br/>
                Tableta
                Cada tableta contiene:
                Citicolina sódica equivalente a .......................................... 500 mg
                de Citicolina
                Excipiente cbp ..................................................................... 1
                tableta
            </div>
        </div>
    )
        ;
}