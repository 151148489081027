import {MedicinesEnum} from "../../../Medicines.enum";
import {FaChevronLeft} from "react-icons/fa";
import {Collapse} from "react-daisyui";
import CollapseOne from "./components/CollapseOne/CollapseOne.component";


type PrecautionsProps = {
    setSelectedTab: (tab: MedicinesEnum) => void;
}
export default function Precautions({setSelectedTab}: PrecautionsProps) {
    return (
        <div className="flex flex-col my-8">
            <div className="grid grid-cols-12  px-4">
                <div className="col-span-3">
                    <button
                        onClick={() => setSelectedTab(MedicinesEnum.MENU)}
                        className="flex items-center justify-center gap-2">
                        <FaChevronLeft className="w-8 h-8"/>
                        <p>Regresar</p>
                    </button>
                </div>
                <div className="col-span-9 ml-14 mt-6">
                    <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
                        <img src={"./imgs/icons/icon-2.png"} alt="icon.1" className=""/>
                    </div>
                </div>
            </div>
            <h2 className="font-bold text-xl text-center mt-2">PRECAUCIONES ESPECIALES</h2>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    CONTRAINDICACIONES
                </Collapse.Title>
                <Collapse.Content>
                    <CollapseOne/>
                </Collapse.Content>
            </Collapse>

            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    PRECAUCIONES GENERALES
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <p>(Véase Interacciones medicamentosas y de otro género).
                        <br/><br/>
                        El efecto de Donepecilo en el metabolismo de otros medicamentos: No se han realizado estudios
                        clínicos in vivo para evaluar el efecto del Donepecilo en la depuración de fármacos
                        metabolizados por CYP3A4 (por ejemplo, cisaprida, terfenadina) o CYP2D6 (por ejemplo,
                        imipramina).
                        <br/><br/>
                        Sin embargo, los estudios in vitro muestran baja tasa de unión con estas enzimas (media de K1 de
                        50-130 µM) que, dadas las concentraciones plasmáticas terapéuticas de Donepecilo (164 nM),
                        indican escasas probabilidades de interferencia.
                        <br/><br/>
                        No se sabe si Donepecilo tiene potencial para la inducción de enzimas hepáticas.
                        <br/><br/>
                        El efecto de otros medicamentos en el metabolismo de Donepecilo: El ketoconazol y quinidina,
                        inhibidores CYP450 3A4 y 2D6, respectivamente, inhiben el metabolismo del Donepecilo in vitro.
                        No se sabe si estos inhibidores producen un efecto clínico.
                        <br/><br/>
                        Los inductores de CYP2D6 y CYP3A4 (por ejemplo, fenitoína, carbamazepina, dexametasona,
                        rifampicina y fenobarbital) podrían aumentar la tasa de eliminación de Donepecilo.
                        <br/><br/>
                        Uso con anticolinérgicos: En virtud de su mecanismo de acción, los inhibidores de la
                        colinesterasa tienen potencial para interferir en la actividad de los medicamentos
                        anticolinérgicos.
                        <br/><br/>
                        Uso con colinomiméticos y otros inhibidores de la colinesterasa: Cabría esperar un efecto
                        sinergista cuando se administran simultáneamente inhibidores de la colinesterasa y la
                        succinilcolina, otros agentes bloqueadores neuromusculares semejantes o agonistas colinérgicos,
                        como el betanecol.</p>
                    <h2 className="text-orange-300">Genitourinarios</h2>
                    <p>Donepecilo, como inhibidor de la colinesterasa, probablemente incremente la relajación muscular
                        de tipo succinilcolina durante la anestesia.
                    </p>
                    <h2 className="text-orange-300">Convulsiones</h2>
                    <h2 className="text-orange-300">Afecciones cardiovasculares</h2>
                    <p>Debido a su acción farmacológica, los inhibidores de la colinesterasa pueden ejercer efectos
                        vagotónicos en los nodos sinoatrial y atrioventricular sobre la frecuencia cardiaca (por
                        ejemplo, bradicardia o bloqueo cardiaco en pacientes con o sin anomalías de conducción cardiaca
                        subyacente conocida). El potencial para esta actividad puede ser particularmente importante en
                        pacientes con «síndrome de seno enfermo» u otras afecciones de la conducción cardiaca
                        supraventricular. Han sido reportados episodios de síncope en asociación con el uso de
                        Donepecilo.
                    </p>
                    <img src={"./imgs/Sudonnez-heart.png"} alt="icon.1" className="w-full"/>

                    <h2 className="text-orange-300">Afecciones gastrointestinales</h2>
                    <p>A través de su acción primaria, puede esperarse que los inhibidores de la colinesterasa aumenten
                        la secreción de ácido gástrico debido a un aumento de la actividad colinérgica. Por lo tanto,
                        los pacientes deberán ser monitoreados de cerca en busca de síntomas de sangrado
                        gastrointestinal activo u oculto, especialmente aquellos con riesgo elevado de desarrollar
                        úlceras, por ejemplo, aquellos con una historia de enfermedad ulcerosa o aquellos que reciben
                        concomitantemente medicamentos antiinflamatorios no esteroideos.
                        <br/><br/>
                        Los estudios clínicos realizados con Donepecilo no han demostrado aumentos, en comparación con
                        placebo, en la incidencia de enfermedad de úlceras pépticas o hemorragias gastrointestinales.
                        <br/><br/>
                        Donepecilo, como una consecuencia previsible de sus propiedades farmacológicas, ha demostrado
                        causar diarrea, náuseas y vómitos. Estos efectos, cuando ocurren, aparecen más frecuentemente
                        con la dosis de 10 mg/día que con la de 5 mg/día. En la mayoría de los casos, estos efectos han
                        sido leves y pasajeros, presentándose durante las primeras 3 semanas y resolviéndose durante el
                        uso continuo de Donepecilo.
                    </p>
                    <h2 className="text-orange-300">Afecciones Pulmonares</h2>
                    <p>Debido a sus acciones colinomiméticas, los inhibidores de la colinesterasa deben ser prescritos
                        con cautela a pacientes con una historia familiar de asma o de enfermedad pulmonar obstructiva.
                    </p>
                </Collapse.Content>
            </Collapse>
            <Collapse
                icon="arrow"
                className="border-b border-gray-500"

            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    RESTRICCIONES DE USO DURANTE EL EMBARAZO Y LA LACTANCIA:
                </Collapse.Title>
                <Collapse.Content className="flex flex-col gap-4">
                    <h2 className="text-orange-300">Embarazo</h2>
                    <div className="grid grid-cols-12">
                        <div className="flex items-center col-span-4">
                            <img src={"./imgs/Sudonnez-pregnant.png"} alt="icon.1" className="w-full"/>
                        </div>
                        <p className="col-span-7">Los estudios teratológicos llevados a cabo en ratas preñadas, a dosis
                            de hasta 16 mg/kg/día
                            (aproximadamente 13 veces la dosis máxima recomendada para humanos en una base de mg/m²), y
                            en conejas preñadas a dosis de hasta 10 mg/kg/día (aproximadamente 16 veces la dosis máxima
                            humana recomendada en base a mg/m²), no revelaron evidencia alguna de un potencial
                            teratógeno de Donepecilo.
                        </p>
                    </div>
                    <p>Sin embargo, en un estudio en el cual se les administró a ratas preñadas 10 mg/kg/día
                        (aproximadamente 8 veces la dosis máxima recomendada en humanos en base a mg/m²) desde el día 17
                        de la gestación hasta el día 20 del posparto, hubo un ligero aumento en óbitos y una ligera
                        disminución en la sobrevida de crías hasta el día 4 del posparto a esta dosis; la siguiente
                        dosis baja probada fue de 3 mg/kg/día.
                        <br/><br/>
                        No se cuenta con estudios adecuados o bien controlados en mujeres embarazadas. Donepecilo debe
                        usarse durante el embarazo solamente si el beneficio potencial justifica el riesgo potencial
                        para el feto.</p>
                    <h2 className="text-orange-300">Lactancia</h2>
                    <p>No se sabe si Donepecilo es excretado en la leche humana, por lo que no está indicado para el uso
                        en mujeres en etapa de lactancia. Pediatría: No se han hecho estudios adecuados y bien
                        controlados para documentar la seguridad y eficacia de Donepecilo en esta población.</p>
                    <img src={"./imgs/Sudonnez-bg-preg.png"} alt="icon.1" className="w-full"/>

                </Collapse.Content>
            </Collapse>

            <Collapse
                icon="arrow"
                className="border-b border-gray-500"
            >
                <Collapse.Title className="text-xl font-medium text-orange-500">
                    LACTANCIA
                </Collapse.Title>
                <Collapse.Content>
                    <p>El valproato se excreta en la leche materna. Se ha reportado que las concentraciones en la leche
                        materna son del 1 al 10% de las concentraciones séricas. Se desconoce el efecto que esto tendría
                        en un lactante alimentado con leche materna. Se debe considerar suspender la lactancia materna
                        cuando se administra valproato semisódico a mujeres en etapa de lactancia.</p>
                </Collapse.Content>
            </Collapse>


        </div>
    )
        ;
}